import { Reducer } from "react";
import { AppAction } from "../definitions/Action";
import { Building } from "../definitions/model/Building";
import { UserConstants, BuildingActionTypes, FamilyConstatns } from "../constants";
import { GroupMembersDto } from "../definitions/model/Group";

export interface BuildingStoreState {
  loading: boolean;
  building: Building | null;
  groupMembers: GroupMembersDto | null;
  error: string | null;
}

export const initialState: BuildingStoreState = {
  building: null,
  groupMembers: null,
  loading: false,
  error: null,
};

const buildingReducer: Reducer<BuildingStoreState | undefined, AppAction> = (
  state: BuildingStoreState = initialState,
  action: AppAction
): BuildingStoreState | undefined => {
  switch (action.type) {
    case BuildingActionTypes.GET_BUILDING:
    case BuildingActionTypes.GET_BUILDING_MEMBERS:
    case BuildingActionTypes.INVITE_MEMBER:
    case BuildingActionTypes.REMOVE_MEMBER:
      return { ...state, loading: true };
    case BuildingActionTypes.GET_MOVED_IN_BUILDING_SUCCEEDED:
    case UserConstants.CHANGE_USER_CURRENT_BUILDING_SUCCEEDED:
      return {
        ...state,
        loading: false,
        building: action.payload,
      };
    case BuildingActionTypes.MOVE_IN_BUILDING_SUCCESS:
      return { ...state, loading: false, building: action.payload.Building };
    case BuildingActionTypes.GET_BUILDING_SUCCEEDED:
      return {
        ...state,
        loading: false,
        building: action.payload,
      };
    case BuildingActionTypes.GET_BUILDING_MEMBERS_SUCCEEDED:
    case BuildingActionTypes.CHANGE_MEMBER_ROLE_SUCCEEDED:
    case BuildingActionTypes.INVITE_MEMBER_SUCCEEDED:
      return {
        ...state,
        loading: false,
        groupMembers: action.payload,
      };
    case BuildingActionTypes.REMOVE_MEMBER_SUCCEEDED:
      if (!state.groupMembers)
        return {
          ...state,
          loading: false,
        };

      return {
        ...state,
        loading: false,
        groupMembers: {
          ...state.groupMembers,
          Members: state.groupMembers.Members.filter((x) => x.UserId !== action.payload),
          PendingMembers: state.groupMembers.PendingMembers.filter((x) => x.UserId !== action.payload),
        },
      };
    case FamilyConstatns.REMOVE_FAMILY_MEMBER_SUCCEEDED:
      if (!state.building)
        return {
          ...state,
          loading: false,
        };

      return {
        ...state,
        loading: false,
        building: {
          ...state.building,
          Units: state.building.Units.map((x) =>
            x.Id === action.payload.UnitId ? { ...x, Members: action.payload.Members } : x
          ),
        },
      };
    case BuildingActionTypes.REMOVE_PENDING_TENANT_SUCCEEDED:
      if (!state.building)
        return {
          ...state,
          loading: false,
        };

      return {
        ...state,
        loading: false,
        building: {
          ...state.building,
          Units: state.building.Units.map((x) =>
            x.Id === action.payload.Id ? { ...x, PendingMembers: action.payload.PendingMembers } : x
          ),
        },
      };
    case BuildingActionTypes.INVITE_TENANT_SUCCEEDED:
      if (!state.building) return state;
      return {
        ...state,
        building: {
          ...state.building,
          Units: state.building?.Units.map((x) => (x.Id === action.payload.Id ? action.payload : x)),
        },
      };
    case BuildingActionTypes.BUILDING_OPERATION_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default buildingReducer;
