import Unit, { UnitNeighbourHouse } from "../definitions/model/unit/Unit";
import { AutocompleteItem } from "../definitions/AutocompleteItem";
import { Building, BuildingUnitDto, OwnedBuildingDto } from "../definitions/model/Building";
import Address from "../definitions/model/unit/Address";

const AddressHelper = {
  GetUnitAddressUrl: (unit: Unit | BuildingUnitDto | null): string => {
    return unit
      ? `/${unit.Address.City}_${unit.Address.HouseNo}_${unit.Address.Floor}_${unit.Address.DoorNumber}_${unit.Address.Street}_${unit.Id}`
      : "";
  },
  GetBuildingAddressUrl: (building: Building | OwnedBuildingDto | null): string => {
    return building
      ? `/${building.Address.City}_${building.Address.HouseNo}_${building.Address.Street}_${building.Id}`
      : "";
  },
  GetUnitNeighbourAddressUrl: (unit: UnitNeighbourHouse): string => {
    return `/${unit.Address.City}_${unit.Address.HouseNo}_${unit.Address.Floor}_${unit.Address.DoorNumber}_${unit.Address.Street}_${unit.Id}`;
  },
  GetAutocompleteAddressUrl: (item: AutocompleteItem): string => {
    return `/${item.Address.City}_${item.Address.HouseNo}_${item.Address.Floor ?? ""}_${
      item.Address.DoorNumber ?? ""
    }_${item.Address.Street}_${item.UnitId}`;
  },
  GetAutocompleteInput: (address: Address): string => {
    let autocompleteValue = `${address.Street} ${address.HouseNo}`;
    if (address.Floor) autocompleteValue += `, ${address.Floor}`;
    if (address.DoorNumber) autocompleteValue += `. ${address.DoorNumber}`;

    autocompleteValue += `, ${address.PostalCode}`;
    if (address.City) {
      autocompleteValue += ` ${address.City}`;
    }
    return autocompleteValue;
  },
};

export default AddressHelper;
