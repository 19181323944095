import { Reducer } from "redux";
import { AppAction } from "../../definitions/Action";
import { NavigationActionTypes } from "./navigation.actiontypes";
import { NavigationItem, PartnersItem } from "../../definitions/Menu";

export interface NavigationStoreState {
  navigationItems: NavigationItem[] | null;
  partnersItems: PartnersItem[] | null;
  loading: boolean;
  error: string | null;
}

export const initialState: NavigationStoreState = {
  navigationItems: null,
  partnersItems: null,
  loading: false,
  error: null,
};

const navigationReducer: Reducer<NavigationStoreState, AppAction> = (
  state: NavigationStoreState = initialState,
  action: AppAction
): NavigationStoreState => {
  switch (action.type) {
    case NavigationActionTypes.NAVIGATION_OPERATION_FAILED:
    case NavigationActionTypes.PARTNERS_OPERATION_FAILED:
      return { ...state, loading: false, error: action.payload };
    case NavigationActionTypes.GET_PARTNERS_ITEMS:
    case NavigationActionTypes.GET_NAVIGATION_ITEMS:
      return { ...state, loading: true };
    case NavigationActionTypes.GET_NAVIGATION_ITEMS_SUCCEEDED:
      return {
        ...state,
        navigationItems: action.payload.items
      };
    case NavigationActionTypes.GET_PARTNERS_ITEMS_SUCCEEDED:
      return {
        ...state,
        partnersItems: action.payload,
      };
    default:
      return state;
  }
};

export default navigationReducer;
