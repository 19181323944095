import React, { ReactElement } from "react";
import { Container, Grid, Typography } from "@mui/material";
import TopBar from "./TopBar";
import MainMenu from "./MainMenu";
import Footer from "./Footer";
import { useMenuItems } from "../../helpers/hooks";
import { ErrorBoundary } from "../ErrorBoundary";
import {styled} from "@mui/material/styles";

const MenuPlaceholder = styled(Grid)(({theme})=>({
  height: 48,
  width: "100%",
  backgroundColor: theme.palette.background.grayPaper,
  margin: "20px 0",
  [theme.breakpoints.down("sm")]: {
    height: 30,
  },
}))

type MainContainerProps = {
  children: React.ReactNode;
  title?: string | ReactElement;
  subTitle?: string | ReactElement;
  menuColor?: string;
  hideMenu?: boolean;
  hideElements?: boolean;
  className?: string;
  hideFooterLinks?: boolean;
};

const MainContainer = (props: MainContainerProps): JSX.Element => {
  const { children, title, subTitle, menuColor, hideMenu, hideElements } =
    props;
  const menuItems = useMenuItems();

  return (
    <Container maxWidth={false} disableGutters>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ minHeight: "100vh" }}
      >
        <TopBar hideAuth hideButtons={hideElements} />
        <Grid container direction="column" alignContent="flex-start" sx={{ flex: "1"}}>
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              {title &&
                !hideElements &&
                (React.isValidElement(title) ? (
                  React.cloneElement(title, {
                   sx: { flex: "1 0 auto"},
                  } as any)
                ) : (
                  <Typography variant="h1" sx={{ 
                      letterSpacing: 5,
                      fontWeight: 200,
                      textAlign: "center",
                      maxWidth: 500}}>
                    {title}
                  </Typography>
                ))}
              {subTitle &&
                (React.isValidElement(subTitle) ? (
                  React.cloneElement(subTitle, {
                    sx: { 
                        fontWeight: 500,
                        fontSize: 17,
                        lineHeight: 2.47,
                        letterSpacing: 2.66,
                        padding: 0},
                  } as any)
                ) : (
                  <Typography variant="h3" sx={{
                      fontWeight: 500,
                      fontSize: 17,
                      lineHeight: 2.47,
                      letterSpacing: 2.66,
                      padding: 0
                  }}>
                    {subTitle}
                  </Typography>
                ))}
            </Grid>
          {!hideMenu ? (
            <MainMenu menuColor={menuColor} menuItems={menuItems} />
          ) : (
            !hideElements && <MenuPlaceholder/>
          )}
          <ErrorBoundary>
            {children}
          </ErrorBoundary>
        </Grid>
        <Footer hideFooterLinks = {props.hideFooterLinks}/>
      </Grid>
    </Container>
  );
};

export default MainContainer;
