import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Link, useMediaQuery, Typography, GridProps, LinkProps, useTheme } from "@mui/material";
import { menuClick } from "../../pages/Overview/Components/MenuBlockList";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { useUser } from "../../actions/user.actions";
import { GetTranslatedText } from "../../helpers/translation-helper";
import { NavigationItem } from "../../definitions/Menu";
import { useUnit } from "../../actions/unit.actions";
import { useBuilding } from "../../actions/building.actions";

interface MenuWrapperProps extends GridProps {
  menuColor?: string;
}

interface MenuLinkProps extends LinkProps {
  isSafari: boolean;
  menuColor?: string;
}

const MenuWrapper = styled(Grid, {
  shouldForwardProp: (propName) => propName !== "menuColor",
})<MenuWrapperProps>(({ theme, menuColor }) => ({
  backgroundColor: menuColor ? menuColor : theme.palette.background.grayPaper,
  margin: "20px 0",
}));

const MenuLink = styled(Link, {
  shouldForwardProp: (propName) => propName !== "isSafari" && propName !== "menuColor",
})<MenuLinkProps>(({ theme, isSafari, menuColor }) => ({
  color: menuColor ? theme.palette.getContrastText(menuColor) : "#000",
  minHeight: 48,
  padding: 10,
  boxSizing: "border-box",
  display: "inline-flex",
  alignItems: "center",
  "&:hover": {
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: 600,
    letterSpacing: isSafari ? -0.8 : 0,
  },
}));

type MenuType = {
  menuColor?: string;
  preview?: boolean;
  menuItems: NavigationItem[] | null;
};

const MainMenu = (props: MenuType): JSX.Element => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [{ unit }] = useUnit();
  const [{ user }] = useUser();
  const [{ building }] = useBuilding();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return !isXs ? (
    <MenuWrapper menuColor={props.menuColor} container>
      <Grid container alignItems="flex-start" justifyContent="center">
        {user && user.Rating > 0 && (
          <MenuLink
            isSafari={isSafari}
            menuColor={props.menuColor}
            onClick={() => {
              if (props.preview) return;
              history.push("/overview");
            }}
          >
            <Typography variant="body1">{t("Menu.MyOverview")}</Typography>
          </MenuLink>
        )}
        {props.menuItems &&
          props.menuItems.map((item: NavigationItem) =>
            item.ShowInTopBar &&
            item.IsPublished &&
            (!item.UserSensitive || (item.UserSensitive && user && item.Rating <= user.Rating)) ? (
              <MenuLink
                key={item.TargetUrl}
                isSafari={isSafari}
                menuColor={props.menuColor}
                onClick={() => {
                  if (props.preview) return;
                  menuClick(item.TargetUrl, item.UnitSensitive, unit, building, history);
                }}
              >
                <Typography variant="body1">{GetTranslatedText(item.Titles)}</Typography>
              </MenuLink>
            ) : null
          )}
      </Grid>
    </MenuWrapper>
  ) : (
    <></>
  );
};

export default MainMenu;
