import { Reducer } from "react";

import { AppAction } from "../../../definitions/Action";
import { CO2Plan, partsToShow } from "./CO2Plan";
import { CO2PlanActionTypes } from "./co2plan.actiontypes";
import { UnitConstants, UserConstants } from "../../../constants";
import i18n from "i18next";

export interface CO2StoreState {
  loading: boolean;
  error: string | null;
  co2Plan: CO2Plan | null;
  lastStep: partsToShow | null;
  tempCO2PlanId: string | null;
  totalCO2Plans: number;
  hasCO2Plan: boolean | null;
  multiSelectMode?: boolean;
}

export const CO2InitialState: CO2StoreState = {
  loading: false,
  error: null,
  co2Plan: null,
  lastStep: null,
  tempCO2PlanId: null,
  totalCO2Plans: 0,
  hasCO2Plan: null,
  multiSelectMode: false,
};

const co2Reducer: Reducer<CO2StoreState | undefined, AppAction> = (
  state: CO2StoreState = CO2InitialState,
  action: AppAction
): CO2StoreState | undefined => {
  switch (action.type) {
    case UnitConstants.GET_MOVED_IN_PROPERTY_SUCCEEDED:
    case UnitConstants.GET_PENDING_PROPERTY_SUCCEEDED:
    case UnitConstants.GET_UNIT_SUCCEEDED:
      return {
        ...state,
        hasCO2Plan: action.payload?.HasCO2Plan,
        lastStep: null,
      };
    case UserConstants.CHANGE_USER_CURRENT_HOUSE_SUCCEEDED:
      return {
        ...state,
        hasCO2Plan: action.payload?.Unit.HasCO2Plan,
        lastStep: null,
      };
    // case UserConstants.UPDATE_USER_PROFILE_SUCCEEDED:
    case UserConstants.MOVE_IN_UNIT_SUCCESS:
    case UserConstants.MOVE_OUT_UNIT_SUCCESS:
      return {
        ...state,
        co2Plan: null,
        lastStep: null,
        tempCO2PlanId: null,
        hasCO2Plan: null,
      };
    case CO2PlanActionTypes.SET_TEMP_CO2_PLANID:
      return {
        ...state,
        tempCO2PlanId: action.payload,
        hasCO2Plan: !!action.payload,
        lastStep: null,
      };

    case CO2PlanActionTypes.GET_CO2_PLAN:
      return { ...state, loading: true, lastStep: null };
    case CO2PlanActionTypes.SET_CO2_PLAN:
    case CO2PlanActionTypes.REGISTER_CO2_PLAN:
      return {
        ...state,
        co2Plan: !action.payload
          ? null
          : {
              ...action.payload,
              InitialSurvey: {
                ...action.payload.InitialSurvey,
                ActiveGroupIndex: state.co2Plan?.InitialSurvey.ActiveGroupIndex,
              },
            },
        loading: false,
        hasCO2Plan: !!action.payload,
        lastStep: state.lastStep ?? partsToShow.InitialSurvey,
      };
    case CO2PlanActionTypes.CO2_PLAN_OPERATION_FAILED:
      return {
        ...state,
        error: action.payload ?? i18n.t("ClimatePlan.Errors.ErrorOccurred"),
        loading: false,
      };
    case CO2PlanActionTypes.SET_CO2_SURVEY: {
      if (state.co2Plan) {
        return {
          ...state,
          co2Plan: {
            ...state.co2Plan,
            InitialSurvey: action.payload.IsTargetSurvey ? state.co2Plan.InitialSurvey : action.payload.Survey,
            TargetSurvey: action.payload.IsTargetSurvey ? action.payload.Survey : state.co2Plan.TargetSurvey,
          },
        };
      }
      break;
    }
    case CO2PlanActionTypes.MARK_CO2_TIMELINE_FULFILLED: {
      return {
        ...state,
        co2Plan: {
          ...state.co2Plan!,
          ActionsTimeLine: state.co2Plan!.ActionsTimeLine.map((item) =>
            item.EmissionSourceGroupId === action.payload.EmissionSourceGroupId &&
            item.EmissionSourceId === action.payload.EmissionSourceId &&
            item.PersonId === action.payload.PersonId
              ? {
                  ...item,
                  IsFulfilled: action.payload.IsFulfilled as boolean,
                }
              : item
          ),
        },
      };
    }
    case CO2PlanActionTypes.SET_CO2_ARTICLES: {
      return {
        ...state,
        co2Plan: {
          ...state.co2Plan!,
          TargetSurvey: {
            ...state.co2Plan!.TargetSurvey,
            Categories: state.co2Plan!.TargetSurvey.Categories.map((category) => ({
              ...category,
              EmissionSourceGroups: category.EmissionSourceGroups.map((esg) => ({
                ...esg,
                ArticleLink: action.payload.find((record: any) => record.emissionSourceGroupId === esg.Id)?.article
                  .NavigateTo,
              })),
            })),
          },
        },
      };
    }
    case CO2PlanActionTypes.SAVE_CO2_SURVEY_ERROR: {
      if (state.co2Plan) {
        if (!action.payload.IsTargetSurvey) {
          return {
            ...state,
            co2Plan: {
              ...state.co2Plan,
              InitialSurvey: {
                ...state.co2Plan.InitialSurvey,
                Error: action.payload.Message,
                MissingSourceGroups: action.payload.MissingGroups,
              },
            },
          };
        }

        return {
          ...state,
          co2Plan: {
            ...state.co2Plan,
            TargetSurvey: {
              ...state.co2Plan.TargetSurvey,
              Error: action.payload.Message,
              MissingSourceGroups: action.payload.MissingGroups,
            },
          },
        };
      }
      break;
    }
    case CO2PlanActionTypes.GET_TOTAL_CO2_PLANS_COUNT_SUCCEEDED:
      return {
        ...state,
        totalCO2Plans: action.payload,
      };
    case CO2PlanActionTypes.MAP_TEMP_CO2_PLAN_TO_UNIT_SUCCEEDED:
      return {
        ...state,
        hasCO2Plan: true,
        tempCO2PlanId: null,
      };
    case CO2PlanActionTypes.SET_CO2_PLAN_LAST_STEP:
      return {
        ...state,
        lastStep: action.payload,
      };
    case CO2PlanActionTypes.SET_MULTISELECT:
      return {
        ...state,
        multiSelectMode: action.payload,
      };
    case CO2PlanActionTypes.SET_UPDATED_QUESTIONS: {
      return {
        ...state,
        co2Plan: {
          ...state.co2Plan!,
          CustomQuestions: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default co2Reducer;
