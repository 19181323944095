import React, { ReactElement, ReactNode, useState } from "react";
import {
  Slide,
  Box,
  Dialog,
  DialogContent,
  Button,
  DialogContentText,
  DialogActions,
  SlideProps
} from "@mui/material";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
  props: SlideProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type ConfrimDialogProps = {
  text: string;
  open?: boolean;
  OkClickHandler: () => void;
  onClose?: () => void;
  children?: ReactNode;
};

const ConfirmDialog = ({
  text,
  open,
  OkClickHandler,
  onClose,
  children,
}: ConfrimDialogProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const [innerOpen, setOpen] = useState(false);

  const isOpened = children ? innerOpen : open ?? false;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (children) {
      setOpen(false);
    } else {
      if (onClose) {
        onClose();
      } else {
        throw new Error("[Confirm Dialog]: 'onClose' is undefined.");
      }
    }
  };

  const handleOkClick = () => {
    OkClickHandler();
    handleClose();
  };

  return (
    <Box display="inline-block">
      {children &&
        React.cloneElement(children as React.ReactElement, {
          onClick: handleClickOpen,
        })}
      <Dialog
        open={isOpened}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOkClick} color="secondary">
            {t("General.Buttons.Ok")}
          </Button>
          <Button onClick={handleClose} color="secondary">
            {t("General.Buttons.Undo")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ConfirmDialog;
