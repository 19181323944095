import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider, StyledEngineProvider, Typography } from "@mui/material";
import store from "../../../../reducers/store";
import { getOptionalTheme } from "../../../../constants/theme";
import { isReactComponent } from ".";
import {
  GrapesEditor,
  GrapesTypes,
  LoadComponentOptions,
} from "../../defenitions";
import { useUnit } from "../../../../actions/unit.actions";
import { useCO2 } from "../../../../pages/ClimatePlan/shared/co2plan.actions";
import { CO2EmissionSourceGroup } from "../../../../pages/ClimatePlan/shared/CO2Plan";
import { GetEconomyData } from "../../../../pages/ClimatePlan/shared/co2-helpers";
import { convertToCurency } from "../../../../services/converter.service";
import { useUser } from "../../../../actions/user.actions";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

type EntityFieldProps = {
  entity: "Unit" | "User" | "CO2";
  field: string;
  survey?: "Initial" | "Target" | "Diff";
  measure?: "Economy" | "Emission";
};

const UserFields = ["Name", "Email", "Address", "Phone"];
const UnitFields = [
  "PropertyType",
  "AddressString",
  "Address.City",
  "Address.DoorNumber",
  "Address.Floor",
  "Address.HouseNo",
  "Address.PostalCode",
  "Address.Street",
];

const ServeyFields = ["Initial", "Target", "Diff"];

const CO2Fields = [
  "All",
  "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Transport",
  "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Holiday",
  "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Clothes",
  "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Food",
  "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Phone",
  "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Electronics",
  "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Residential",
];

type CO2DataMap = {
  [key: string]: CO2DataObj;
};

type CO2DataObj = {
  name: string;
  Initial: {
    Economy: string;
    Emission: string;
    Name: string;
    TargetName: string;
  };
  Target: {
    Economy: string;
    Emission: string;
    Name: string;
    TargetName: string;
  };
  Diff: {
    Economy: string;
    Emission: string;
    Name: string;
    TargetName: string;
  };
  savedEmission: number;
  initialCost: number;
  targetCost: number;
  initialEmission: number;
  targetEmission: number;
  savedCost: number;
};

export const EntityField = (props: EntityFieldProps): JSX.Element => {
  const [{ unit }] = useUnit();
  const [{ user }] = useUser();
  const [{ co2Plan, loading: planLoading }, { loadPlan }] = useCO2();
  const [text, setText] = useState("");
  const { t } = useTranslation();
  const field = t(props.field);

  useEffect(() => {
    if (!co2Plan && !planLoading && unit) loadPlan(unit.Id);
  }, [unit, co2Plan]);

  const GetCost = (
    activeGroup: CO2EmissionSourceGroup,
    initialGroup: CO2EmissionSourceGroup
  ): CO2DataObj => {
    const params = new URLSearchParams(window.location.search);
    const {
      personsActiveSourceCost,
      personsInitialSourceCost,
      initialEmission,
      activeEmission,
      activeSourceTargetName,
      initialSourceTargetName,
      activeSourseName,
      initialSourseName,
    } = GetEconomyData(
      activeGroup,
      initialGroup,
      (params.get("co2plan_personid") as string) || user?.Id
    );

    const savedCost = personsInitialSourceCost - personsActiveSourceCost;

    return {
      name: t(activeGroup.ShortName),
      Initial: {
        Economy: convertToCurency(personsInitialSourceCost, "."),
        Emission: convertToCurency(initialEmission, "."),
        Name: t(initialSourseName),
        TargetName: t(initialSourceTargetName),
      },
      Target: {
        Economy: convertToCurency(personsActiveSourceCost, "."),
        Emission: convertToCurency(activeEmission, "."),
        Name: t(activeSourseName),
        TargetName: t(activeSourceTargetName),
      },
      Diff: {
        Economy: convertToCurency(savedCost, "."),
        Emission: convertToCurency(initialEmission - activeEmission, "."),
        Name: t(activeSourseName),
        TargetName: t(activeSourceTargetName),
      },
      initialCost: personsInitialSourceCost,
      targetCost: personsActiveSourceCost,
      initialEmission: initialEmission,
      targetEmission: activeEmission,
      savedEmission: initialEmission - activeEmission,
      savedCost: savedCost,
    };
  };

  const initialGroups = useMemo(
    () =>
      co2Plan?.InitialSurvey.Categories.flatMap(
        (cat) => cat.EmissionSourceGroups
      ),
    [co2Plan]
  );

  const targetGroups = useMemo(
    () =>
      co2Plan?.TargetSurvey.Categories.flatMap(
        (cat) => cat.EmissionSourceGroups
      ),
    [co2Plan]
  );

  const emissionGroups = useMemo(() => {
    if (props.entity === "CO2" && targetGroups && initialGroups) {
      const emissionGroups: CO2DataMap = {};
      let selectedTargetGroups: CO2EmissionSourceGroup[] = [];
      let selectedInitialGroups: CO2EmissionSourceGroup[] = [];
      if (field === "All") {
        selectedInitialGroups = initialGroups;
        selectedTargetGroups = targetGroups;
      } else {
        selectedInitialGroups = initialGroups.filter(
          (group) => t(group.ShortName) === field
        );
        selectedTargetGroups = targetGroups.filter(
          (group) => t(group.ShortName) === field
        );
      }
      let index = 0;
      for (const targetGroup of selectedTargetGroups) {
        const obj = GetCost(targetGroup, selectedInitialGroups[index++]);

        emissionGroups[obj.name] = obj;
      }

      const summaryObj = {
        name: "All",
        Initial: {
          Economy: "",
          Emission: "",
          Name: "",
          TargetName: "",
        },
        Target: {
          Economy: "",
          Emission: "",
          Name: "",
          TargetName: "",
        },
        Diff: {
          Economy: "",
          Emission: "",
          Name: "",
          TargetName: "",
        },
        initialCost: 0,
        targetCost: 0,
        initialEmission: 0,
        targetEmission: 0,
        savedEmission: 0,
        savedCost: 0,
      };
      for (const key in emissionGroups) {
        summaryObj.initialCost += emissionGroups[key].initialCost;
        summaryObj.targetCost += emissionGroups[key].targetCost;
        summaryObj.initialEmission += emissionGroups[key].initialEmission;
        summaryObj.targetEmission += emissionGroups[key].targetEmission;
        summaryObj.savedEmission += emissionGroups[key].savedEmission;
        summaryObj.savedCost += emissionGroups[key].savedCost;
      }
      summaryObj.Initial.Economy = convertToCurency(
        summaryObj.initialCost,
        "."
      );
      summaryObj.Target.Economy = convertToCurency(summaryObj.targetCost, ".");
      summaryObj.Diff.Economy = convertToCurency(summaryObj.savedCost, ".");
      summaryObj.Initial.Emission = convertToCurency(
        summaryObj.initialEmission,
        "."
      );
      summaryObj.Target.Emission = convertToCurency(
        summaryObj.targetEmission,
        "."
      );
      summaryObj.Diff.Emission = convertToCurency(
        summaryObj.savedEmission,
        "."
      );

      emissionGroups.All = summaryObj;

      return emissionGroups;
    }
  }, [targetGroups, initialGroups, props.entity, field]);

  useEffect(() => {
    if (props.entity !== "CO2") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let data: any = user;
      if (props.entity === "Unit" && unit) data = unit;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const getText = (entity: any, field: string): string => {
        if (!entity || !field) return "";

        const childs = field.split(".");
        const fieldValue = childs[0];
        if (childs.length == 1) return entity[fieldValue];

        const pathLeft = field.substr(fieldValue.length + 1);
        return getText(entity[fieldValue], pathLeft);
      };

      const text = getText(data, field);
      setText(text);
    } else if (emissionGroups) {
      if (props.survey && props.measure && emissionGroups[field]) {
        setText(emissionGroups[field][props.survey][props.measure]);
      }
    }
  }, [unit, user, co2Plan]);

  return <span style={{ minHeight: 10, minWidth: 20 }}>{text}</span>;
};

export default function (
  editor: GrapesEditor,
  options: LoadComponentOptions,
  landingTheme?: string
): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "EntityField";

  blockManager.add(GrapesTypes.EntityFieldComponent, {
    label: "Entity field",
    category: options.categoryLabel,
    attributes: { class: "fa fa-gears" },
    content: `<span data-gjs-type="${GrapesTypes.EntityFieldComponent}"></span>`,
  });
  const traits = [
    {
      type: "select",
      label: "Etity",
      changeProp: 1,
      name: "entity",
      typeid: 0,
      options: [
        { id: "User", name: "User" },
        { id: "Unit", name: "Unit" },
        { id: "CO2", name: "CO2" },
      ],
    },
    {
      type: "select",
      label: "Field",
      changeProp: 1,
      name: "field",
      typeid: 0,
      options: UserFields.map((f) => ({ id: f, name: f })),
    },
    {
      name: "survey",
      type: "select",
      label: "Survey",
      changeProp: 1,
      typeid: 0,
      options: ServeyFields,
    },
    {
      name: "measure",
      type: "select",
      label: "Measure",
      changeProp: 1,
      typeid: 0,
      options: ["Economy", "Emission", "Name", "TargetName"],
    },
  ];
  const subscriptions = traits.map((trait) => trait.name);

  const ReinitFieldTraits = (entity: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const component = editor.getSelected() as any;
    let options = UserFields.map((f) => ({ id: f, name: f }));

    if (entity === "Unit" || entity === "User") {
      component.removeTrait("measure");
      component.removeTrait("survey");
    }

    if (entity === "Unit")
      options = UnitFields.map((f) => ({ id: f, name: f }));

    if (entity === "CO2") {
      options = CO2Fields.map((f) => ({ id: f, name: i18next.t(f) }));
      if (!component.getTrait("survey")) {
        component.addTrait({
          name: "survey",
          type: "select",
          label: "Survey",
          changeProp: 1,
          typeid: 0,
          options: ServeyFields,
        });
      }
      if (!component.getTrait("measure")) {
        component.addTrait({
          name: "measure",
          type: "select",
          label: "Measure",
          changeProp: 1,
          typeid: 0,
          options: ["Economy", "Emission", "Name", "TargetName"],
        });
      }
    }

    component.getTrait("field").set("options", options);
  };

  domComponents.addType(GrapesTypes.EntityFieldComponent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute &&
          el.getAttribute("data-gjs-type") ===
            GrapesTypes.EntityFieldComponent) ||
        (el.attributes &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (el.attributes as any)["data-gjs-type"] ===
            GrapesTypes.EntityFieldComponent) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.EntityFieldComponent,
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        droppable: false,
        traits: [...model.prototype.defaults.traits, ...traits],
      },
    },
    view: view.extend({
      init() {
        subscriptions.forEach((subscription) => {
          this.listenTo(
            this.model,
            `change:${subscription}`,
            this.handleChanges
          );
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handleChanges(e: any, value: string) {
        const root = ReactDOM.createRoot(this.el);
        root.unmount();
        if (e.changed.entity) ReinitFieldTraits(value);
        this.render();
      },

      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        const { entity, field, measure, survey } = this.model.attributes;
        comps.reset();
        const compString = `<${compName} entity=${entity} field=${field} measure=${measure} survey=${survey}></${compName}>`;
        comps.add(compString);
        const root = ReactDOM.createRoot(el);

        root.render(
          <>
            <StyledEngineProvider>
              <ThemeProvider theme={getOptionalTheme(landingTheme)}>
                <Provider store={store}>
                  {entity ? (
                    <span>{entity}</span>
                  ) : (
                    <Typography color="error">set entity</Typography>
                  )}
                  {field ? (
                    <span>.{i18next.t(field)} </span>
                  ) : (
                    <Typography color="error">set field</Typography>
                  )}
                  <EntityField
                    entity={entity}
                    field={field}
                    measure={measure}
                    survey={survey}
                  />
                </Provider>
              </ThemeProvider>
            </StyledEngineProvider>
          </>
        );
      },
    }),
  });
}
