import React, { useState, SyntheticEvent, useEffect } from "react";
import { Grid, Link, TextField, Button, Typography, FormLabel, LinearProgress, Box, Zoom, styled } from "@mui/material";
import { emailRegexp } from "../../../services/validation.service";
import DataPoliticDialog from "../DataPoliticDialog";
import AuthModel from "../../../definitions/model/Auth";
import { useForm, FormProvider } from "react-hook-form";
import MyhouseCheckbox from "../../Base/MyhouseCheckbox";
import { useHistory } from "react-router-dom";
import FacebookLogin, { ReactFacebookLoginInfo, ReactFacebookFailureResponse } from "react-facebook-login";
import { Trans, useTranslation } from "react-i18next";
import PasswordField from "../../Base/PasswordField";
import User, { UserRegistrationDTO } from "../../../definitions/model/User";
import { isInAppBrowser } from "../../../helpers";
import { Step } from "../AuthDialog";
import { PhoneField } from "../../Base/FormattedFields";
import { useDispatch } from "react-redux";
import { UserConstants } from "../../../constants";
import NavigationHelper from "../../../helpers/navigation-helper";
import { ControllerConnector, TextFieldConnector } from "../../Base/FormConnector";
import { matchIsValidTel } from "mui-tel-input";
import { useUnitActions, useUser } from "../../../actions";
import Unit from "../../../definitions/model/unit/Unit";
import { ApiError } from "../../../services/api.service";

const FacebookLoginWrapper = styled("div")(({ theme }) => ({
  "& .FbButton": {
    borderRadius: theme.shape.borderRadius,
    fontFamily: "inherit",
    lineHeight: "37px",
    backgroundColor: "#1877F2",
    border: "none",
    outline: "none",
    minWidth: 200,
    color: "white",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#0a54b3",
    },
    "& i": {
      marginRight: 7,
    },
  },
}));

const LinearProgressStyled = styled(LinearProgress)(() => ({
  "&.MuiLinearProgress-colorSecondary": {
    background: "#66000090",
  },
  "&.MuiLinearProgress-barColorSecondary": {
    background: "#660000",
  },
}));

type PasswordSafetyProgressProps = {
  password: string | undefined;
};

const PasswordSefetyProgress = ({ password }: PasswordSafetyProgressProps) => {
  const { t } = useTranslation("translation");
  let progress = 0;
  if (password) {
    if (password.length >= 5) progress += 31;
    if (password.match(/[A-Z!@#$%^&*]/)) progress += 19;
    if (password.match(/[a-z]/)) progress += 19;
    if (password.match(/\d/)) progress += 20;
    if (password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/)) progress += 20;
  }
  return (
    <Box width="100%" pb={1}>
      <LinearProgressStyled
        variant="determinate"
        color={progress < 50 ? "secondary" : "primary"}
        value={progress > 100 ? 100 : progress}
      />
      <Typography variant="body2">{t("Authorization.Errors.NecessaryQuantityOfCharacters")}</Typography>
    </Box>
  );
};

type RegisterFormData = {
  Email?: string;
  Name?: string;
  Password?: string;
  TermsOfUse: boolean;
  Notifications: boolean;
  PhoneNotifications?: boolean;
  FKey?: string;
  Phone?: string;
};

type RegisterFormProps = {
  state: AuthModel;
  showPhoneField?: boolean;
  showNameField?: boolean;
  showCO2MailField?: boolean;
  ignoreNotifications: boolean;
  setStep: (step: Step, state?: AuthModel) => void;
  onRegistrationComplete?: (unitId: string) => Promise<void>;
  closeDialog: () => void;
  employerId?: string;
  communeNumber?: number;
};

const RegisterForm = (props: RegisterFormProps): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation("translation");
  const [openPolitic, setOpenPolitic] = useState(false);
  const [phoneNotificationsIntermediate, setPhoneNotificationsIntermediate] = useState(true);

  const { state } = props;
  const [
    { error, authDestination, authRegisterDestination, authUserRating },
    { registerUser, completeRegistration, getLanguage, checkIsFacebookUserExist },
  ] = useUser();
  const [anError, setAnError] = useState(error);
  useEffect(() => {
    if (anError !== error) setAnError(error);
  }, [error]);
  const [processing, setProcessing] = useState(false);
  const [unit, setUnit] = useState<Unit | null>(null);
  const dispatch = useDispatch();
  const { getUnit } = useUnitActions();

  const methods = useForm<RegisterFormData>({
    defaultValues: {
      Email: state.Email,
      Password: state.Password,
      Notifications: state.Notifications,
      TermsOfUse: state.TermsOfUse,
      // undefined should be treated as false to make checkbox working
      PhoneNotifications: !!state.PhoneNotifications,
      Phone: state.Phone,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    getValues,
    setError,
    clearErrors,
    watch,
  } = methods;

  const getRedirectUri = (termsOfUse: boolean, notifications: boolean) => {
    let returnUri = `terms=${termsOfUse}&notifications=${notifications}`;
    if (!state.SimpleRegistration) returnUri += "&freg=true";
    if (state.PropertyId) returnUri += `&property=${state.PropertyId}`;

    return `${window.location.origin}${window.location.pathname}?${returnUri}`;
  };

  const [redirectUri, setRedirectUri] = useState(window.location.href);

  useEffect(() => {
    if (getValues) {
      const uri = getRedirectUri(getValues().TermsOfUse, getValues().Notifications);
      if (redirectUri != uri) setRedirectUri(uri);
    }
  }, [watch().TermsOfUse, watch().Notifications]);

  useEffect(() => {
    setPhoneNotificationsIntermediate(state.PhoneNotifications === undefined);
  }, [state]);

  const onPoliticClick = (e: SyntheticEvent) => {
    e.preventDefault();
    setOpenPolitic(true);
  };

  const submit = async (data: RegisterFormData) => {
    if (props.showPhoneField && phoneNotificationsIntermediate) {
      setError("PhoneNotifications", {});
      return;
    }

    try {
      await handler(data);
    } catch (error) {
      setAnError((error as Error).message);
    }
  };

  const handler = async (data: RegisterFormData) => {
    setProcessing(true);

    let sendNotifications = data.Notifications || data.PhoneNotifications || false;
    if (sendNotifications && sendNotifications.toString() == "on") sendNotifications = true;
    if (sendNotifications && sendNotifications.toString() == "off") sendNotifications = false;

    try {
      const user = await registerUser({
        Name: data.Name || "",
        Email: data.Email || "",
        FKey: "",
        AvatarUrl: "",
        Phone: data.Phone || "",
        SendNotifications: sendNotifications,
        ReceiveCalls: data.PhoneNotifications || false,
        SendFacebookNotifications: data.PhoneNotifications || false,
        Rating: authUserRating,
        IgnoreNotifications: props.ignoreNotifications,
        EmployerId: props.employerId,
        CommuneNumber: props.communeNumber ?? null,
        Language: getLanguage(),
        Password: data.Password || "",
      });

      await complete(user);
      redirect(user);
    } catch (error) {
      setAnError((error as ApiError).statusText);
      setProcessing(false);
      return;
    }

    setProcessing(false);
    if (props.onRegistrationComplete) {
      dispatch({ type: UserConstants.SHOW_MOVE_IN_DIALOG });
      dispatch({
        type: UserConstants.SET_REGISTRATION_COMPLETE,
        payload: null,
      });
    }
  };

  const complete = async (user?: User) => {
    if (user && user.NewUser) {
      const data = {
        Name: user?.Name || "",
        Email: user?.Email || getValues().Email || "",
        Password: getValues().Password || "",
        Phone: getValues().Phone || "",
        UnitId: state.PropertyId || "",
        Interests: [],
      };
      if (user?.FaceBookId) data.Password = "";

      if (props.onRegistrationComplete && state.PropertyId) {
        await props.onRegistrationComplete(state.PropertyId);
      }

      if (state.SimpleRegistration && unit) {
        await completeRegistration({ ...data, IgnoreNotifications: props.ignoreNotifications });
      }
    }
  };

  const redirect = (user?: User) => {
    const options = {
      ...getValues(),
      PropertyId: state.PropertyId,
    };

    if (authRegisterDestination) {
      NavigationHelper.GoByRoute(history, unit, null, authRegisterDestination, options);
    } else if (authDestination) {
      NavigationHelper.GoByRoute(history, unit, null, authDestination, options);
    } else if (user && user.NewUser) {
      history.push("/completeregistration", options);
    } else {
      history.push("/overview");
    }
  };

  const facebookClick = () => {
    // setRedirectUri(getRedirectUri());
    clearErrors();
    setProcessing(true);
  };

  const responseFacebook = async (response: ReactFacebookLoginInfo | ReactFacebookFailureResponse) => {
    setProcessing(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (response.error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      setAnError(response.error.message);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (response.status === "unknown") {
      setAnError(t("Authorization.Errors.FacebookAuthCommon"));
      return;
    }

    if (!getValues().TermsOfUse) {
      setError("TermsOfUse", {});
      return;
    }

    if (props.showPhoneField && phoneNotificationsIntermediate) {
      setError("PhoneNotifications", {});
      return;
    }

    if (getValues().PhoneNotifications && !getValues().Phone) {
      setError("Phone", {});
      return;
    }

    const facebookUser = response as ReactFacebookLoginInfo;

    let sendNotifications = getValues().Notifications || getValues().PhoneNotifications || false;
    if (sendNotifications && sendNotifications.toString() == "on") sendNotifications = true;
    if (sendNotifications && sendNotifications.toString() == "off") sendNotifications = false;

    const user: UserRegistrationDTO = {
      Name: facebookUser.name || "",
      Email: facebookUser.email || "",
      FKey: facebookUser.id,
      AvatarUrl: facebookUser.picture?.data?.url || "",
      SendNotifications: sendNotifications,
      ReceiveCalls: getValues().PhoneNotifications || false,
      SendFacebookNotifications: getValues().PhoneNotifications || false,
      Phone: getValues().Phone || "",
      Rating: authUserRating,
      IgnoreNotifications: props.ignoreNotifications,
      EmployerId: props.employerId,
      Language: getLanguage(),
      Password: "",
      CommuneNumber: props.communeNumber ?? null,
    };

    const userExist = await checkIsFacebookUserExist(facebookUser.id);
    setProcessing(true);

    const u = await registerUser(user);
    if (!userExist) {
      await complete(u);
    }
    await redirect(u);

    setProcessing(false);
  };

  useEffect(() => {
    if (state.PropertyId) {
      getUnit(state.PropertyId, false)
        .then((u) => setUnit(u || null))
        .catch((e) => {
          console.error(e);
          setUnit(null);
        });
    }
  }, [state.PropertyId]);

  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (!window.FB)
        setAnError(t("Authorization.Errors.FacebookInitializationError", { hostname: window.location.hostname }));
    }, 2000);

    return () => {};
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submit)}>
          <Grid container spacing={2} direction="column">
            {props.showNameField && (
              <Grid item container>
                <TextFieldConnector register={register("Name", { required: false, minLength: 3 })}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    size="small"
                    label={t("Authorization.Forms.Name")}
                    error={!!errors.Name}
                  />
                </TextFieldConnector>
              </Grid>
            )}
            <Grid item container>
              <TextFieldConnector
                register={register("Email", {
                  required: true,
                  pattern: emailRegexp,
                })}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  type="email"
                  size="small"
                  label={t("Authorization.Forms.EnterName")}
                  error={!!errors.Email}
                />
              </TextFieldConnector>
            </Grid>
            {anError && (
              <Zoom in={!!anError}>
                <Grid item container justifyContent="center">
                  <Typography color="error">{anError}</Typography>
                </Grid>
              </Zoom>
            )}
            <Grid item container>
              <TextFieldConnector
                register={register("Password", {
                  required: true,
                  minLength: 5,
                })}
              >
                <PasswordField
                  variant="outlined"
                  fullWidth
                  size="small"
                  label={t("Authorization.Forms.Password")}
                  error={!!errors.Password}
                />
              </TextFieldConnector>
            </Grid>
            <Grid item container>
              <PasswordSefetyProgress password={watch().Password} />
            </Grid>
            <Grid item container>
              <Typography variant="body1">{t("Authorization.Forms.IAgree")}</Typography>
              <StyledFormLabel style={{ display: "inline-flex", alignItems: "flex-start" }}>
                <ControllerConnector name="TermsOfUse" rules={{ required: true }} type="checkbox">
                  <MyhouseCheckbox error={!!errors.TermsOfUse} />
                </ControllerConnector>
                <Grid container>
                  <Typography variant="body1" display="inline">
                    {t("Authorization.Forms.AcceptRegistrationText", { hostname: window.location.hostname })}{" "}
                    <Link color="inherit" underline="always" onClick={onPoliticClick}>
                      {t("Authorization.Forms.Terms")} {t("Authorization.Forms.Or")}{" "}
                      {t("Authorization.Forms.DataPolicy")}.
                    </Link>
                  </Typography>
                </Grid>
              </StyledFormLabel>

              {props.showPhoneField && (
                <StyledFormLabel
                  required={props.showPhoneField}
                  style={{ display: "inline-flex", alignItems: "flex-start" }}
                >
                  <ControllerConnector name="PhoneNotifications" type="checkbox">
                    <MyhouseCheckbox
                      indeterminate={phoneNotificationsIntermediate}
                      error={!!errors.PhoneNotifications}
                      onClick={() => {
                        setPhoneNotificationsIntermediate(false);
                      }}
                    />
                  </ControllerConnector>
                  <Grid container>
                    <Typography variant="body1" display="inline">
                      {t("Authorization.Permissions.MailAndPhone", { hostname: window.location.hostname })}
                    </Typography>
                  </Grid>
                </StyledFormLabel>
              )}
              {!!watch().PhoneNotifications && (
                <Grid item container style={{ marginTop: 5 }}>
                  <ControllerConnector
                    name="Phone"
                    rules={{
                      validate: (e: any) => matchIsValidTel(e),
                    }}
                  >
                    <PhoneField
                      variant="outlined"
                      error={!!errors.Phone}
                      fullWidth
                      type="phone"
                      size="small"
                      label={t("Authorization.Forms.TelNumber")}
                    />
                  </ControllerConnector>
                </Grid>
              )}
              {!props.showPhoneField && (
                <StyledFormLabel>
                  <ControllerConnector name="Notifications" type="checkbox">
                    <MyhouseCheckbox />
                  </ControllerConnector>
                  <Grid container>
                    <Typography variant="body1">
                      <Trans
                        components={{ sub: <sub /> }}
                        values={{ hostname: window.location.hostname }}
                        i18nKey="Authorization.Permissions.Mail"
                      />
                    </Typography>
                  </Grid>
                </StyledFormLabel>
              )}
            </Grid>
            {/*{isHouseTaken && (*/}
            {/*    <Fade in={isHouseTaken}>*/}
            {/*        <Grid item container justifyContent="center">*/}
            {/*            <Alert severity="warning">*/}
            {/*                {t("Authorization.Forms.AlertText1")}*/}
            {/*                <br />*/}
            {/*                {t("Authorization.Forms.AlertText2")}*/}
            {/*            </Alert>*/}
            {/*        </Grid>*/}
            {/*    </Fade>*/}
            {/*)}*/}
            <Grid container justifyContent="center">
              <Button
                sx={{
                  minWidth: "200px",
                  marginTop: "20px",
                  "& span": {
                    justifyContent: "center",
                  },
                }}
                variant="contained"
                color="success"
                disabled={formState.isSubmitting || processing}
                type="submit"
              >
                {props.showCO2MailField && t("General.Buttons.CreateAndNavigateCo2")}
                {!props.showCO2MailField && t("General.Buttons.Create")}
              </Button>
            </Grid>
            <Grid container item justifyContent="center">
              <Typography sx={{ marginBottom: "-8px", marginTop: "-8px" }} variant="body1">
                {t("Base.Dialog.Or")}
              </Typography>
            </Grid>
            <Grid item container justifyContent="center" style={{ paddingLeft: 0 }}>
              {process.env.REACT_APP_FACEBOOK_APP_ID && (
                <FacebookLoginWrapper>
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                    autoLoad={false}
                    isMobile={isInAppBrowser}
                    disableMobileRedirect={false}
                    fields="name,email,picture"
                    scope="public_profile,email"
                    callback={responseFacebook}
                    onClick={facebookClick}
                    icon="fa-facebook"
                    textButton={t("Authorization.Buttons.FacebookRegister") ?? ""}
                    isDisabled={processing}
                    redirectUri={redirectUri}
                    cssClass="FbButton"
                  />
                </FacebookLoginWrapper>
              )}
            </Grid>
            <Grid container item justifyContent="center" alignItems="center">
              <Grid item>
                <Link onClick={() => props.setStep("login", getValues())}>
                  <Typography variant="body2">{t("Authorization.Buttons.HaveAnAccount")}</Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <DataPoliticDialog open={openPolitic} closeDialog={() => setOpenPolitic(false)} />
        </form>
      </FormProvider>
      {processing && <LinearProgress style={{ marginTop: 10 }} />}
    </>
  );
};

export default RegisterForm;

const StyledFormLabel = styled(FormLabel)(() => ({
  "&.MuiFormLabel-root": {
    display: "inline-flex",
    alignItems: "flex-start",
  },
}));
