import React, { Suspense, useEffect, useLayoutEffect, lazy } from "react";
import { Switch, useLocation } from "react-router-dom";
import Route from "./Route";
import MyHouseStartPage from "../pages/MyHouseStartPage/MyHouseStartPage";
import CompleteUserRegistration from "../pages/CompleteRegistration";
import CommercialCompleteUserRegistration from "../pages/CommercialCompleteRegistration";
import FacebookAuthCallback from "../pages/User/FacebookAuthCallback";

import { UserType } from "../constants/enums";
import { connect, ConnectedProps } from "react-redux";
import LoadingOverlay from "../components/LoadingOverlay";
import { setPwaOptions, startSignalR, _getBasicData, useAppDispatch } from "../actions";
import { ApplicationState } from "../reducers/store";
import { AppThunkDispatch } from "../definitions/Action";
import RemoveDocumentsPage from "../pages/House/Documents/RemoveDocumentsPage";
import OfflinePage from "../pages/OfflinePage";
import { Dialog } from "@mui/material";
import ContactPage from "../pages/Contact/ContactPage";
import AnnouncementEditorPage from "../pages/NeighborGroups/AnnouncementEditorPage";
import NavigationHelper from "../helpers/navigation-helper";
import { UserConstants } from "../constants/user.constants";
import CommuneClimatePlan from "../pages/MyClimatePlanPage";
import CommercialClimatePlan from "../pages/MyClimatePlanPage/pages/ClimatePlanCommercialStartPage";
const CustomLandingPageLazy = lazy(() => import("../pages/LandingPage/CustomLandingPage"));
const LandingPageLazy = lazy(() => import("../pages/LandingPage/LandingPage"));
const LandingLoaderLazy = lazy(() => import("../pages/LandingPage/LandingLoader"));
const UnsubscribeLazy = lazy(() => import("../pages/LandingPage/Unsubscribe"));
const AdminPanelLazy = lazy(() => import("../pages/AdminPanel"));
const OverviewPageLazy = lazy(() => import("../pages/Overview/OverviewPage"));
const PartnerLoginLazy = lazy(() => import("../pages/PartnerLogin/PartnerLoginPage"));
const PropertyLayoutLazy = lazy(() => import("../components/Layout/PropertyLayout"));
const SustainabilityCheckPageLazy = lazy(() => import("../pages/SustainabilityCheck/SustainabilityCheckPage"));
const AboutUsPageLazy = lazy(() => import("../pages/AboutUs/AboutMyHousePage"));
const AboutClimatePlanPageLazy = lazy(() => import("../pages/AboutUs/AboutOurClimatePlanPage"));
const ValidationLazy = lazy(() => import("../pages/AboutUs/ValidationPage"));
const FamilyPageLazy = lazy(() => import("../pages/Family/FamilyPage"));
const HousesPageLazy = lazy(() => import("../pages/Family/HousesPage"));
const ContactPageLazy = lazy(() => import("../pages/Contact/ContactPage"));
const DesignPollLazy = lazy(() => import("../pages/DesignPoll/DesignPollPage"));
const NeighborGroupsPageLazy = lazy(() => import("../pages/NeighborGroups/NeighborGroupsPage"));

const Routes = (props: RoutesConnectedProps): JSX.Element => {
  const { user } = props;
  const isAdmin = () => !!user?.Role && user?.Role >= UserType.ExternalManager;
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  useEffect(() => {
    props._getBasicData();

    const beforeInstallPromptHandler = (e: Event) => {
      e.preventDefault();
      props.setPwaOptions(e);
    };

    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);

    return () => {
      window.removeEventListener("beforeinstallprompt", beforeInstallPromptHandler);
    };
  }, []);

  useEffect(() => {
    const sendAnalyticData = (e: BeforeUnloadEvent) => {
      dispatch({
        type: UserConstants.USER_REGISTERED_AND_STOP,
      });
    };
    if (user) {
      const currentDate = new Date();
      const isNewUser = currentDate.getTime() / 3600000 - new Date(user.Created).getTime() / 3600000 <= 24;

      if (isNewUser && pathname === "/overview") {
        window.addEventListener("beforeunload", sendAnalyticData);
      } else {
        window.removeEventListener("beforeunload", sendAnalyticData);
      }
    }
  });

  useLayoutEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      left: 0,
      top: 0,
    });
  }, [pathname]);

  return (
    <Suspense fallback={<LoadingOverlay loading />}>
      <Dialog open={props.isMaintenance || false} fullScreen fullWidth>
        <OfflinePage />
      </Dialog>
      <Dialog
        key={pathname}
        open={(pathname != "/profile" && props.needToAcceptTerms && user !== null) || false}
        fullScreen
        fullWidth
      >
        <ContactPage />
      </Dialog>
      <Switch>
        <Route path="/overview" component={OverviewPageLazy} />
        <Route path="/landing/:name/:languageId" exact component={CustomLandingPageLazy} />
        <Route path="/landing/:name" exact component={CustomLandingPageLazy} />
        <Route path="/landing" component={LandingPageLazy} />
        <Route path="/landing-loader" component={LandingLoaderLazy} />
        <Route path="/unsubscribe/:uniqueName" component={UnsubscribeLazy} />
        <Route
          path="/"
          exact
          component={NavigationHelper.IsClimateSite() ? CommercialClimatePlan : MyHouseStartPage}
        />
        <Route path="/about-us" component={AboutUsPageLazy} />
        <Route path="/about-our-climate" component={AboutClimatePlanPageLazy} />
        <Route path="/validation" component={ValidationLazy} />        
        <Route path="/klimaplan" component={CommuneClimatePlan} />
        <Route path="/sustainabilitycheck" exact component={SustainabilityCheckPageLazy} />
        <Route path="/admin" component={AdminPanelLazy} validate={isAdmin} />
        <Route path="/partner" component={PartnerLoginLazy} />
        <Route path="/facebook-authenticated-callback/:facebookKey" component={FacebookAuthCallback} />
        <Route path="/completeregistration" component={CompleteUserRegistration} />
        <Route path="/commercial-completeregistration" component={CommercialCompleteUserRegistration} />
        <Route path="/contact" component={ContactPageLazy} />
        <Route path="/privacy" component={ContactPageLazy} />
        <Route path="/family/:id" component={FamilyPageLazy} />
        <Route path="/house-managment" component={HousesPageLazy} />
        <Route exact path="/remove-documents/" component={RemoveDocumentsPage} />
        <Route exact path="/remove-documents/:id" component={RemoveDocumentsPage} />
        <Route path="/styles" component={DesignPollLazy} />
        <Route exact path="/neighbor-groups/:groupId/edit/:id" component={AnnouncementEditorPage} />
        <Route exact path="/neighbor-groups/:id" component={NeighborGroupsPageLazy} />
        <Route path="/neighbor-groups" component={NeighborGroupsPageLazy} />
        <Route component={PropertyLayoutLazy} />
      </Switch>
    </Suspense>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.user,
  isMaintenance: state.user.isMaintenance,
  needToAcceptTerms: state.user.needToAcceptTerms,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  startSignalR: dispatch(startSignalR()),
  _getBasicData: () => dispatch(_getBasicData()),
  setPwaOptions: (pwaOptions: any) => dispatch(setPwaOptions(pwaOptions)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type RoutesConnectedProps = ConnectedProps<typeof connector>;

export default connector(Routes);
