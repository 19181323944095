import React from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../../actions";
import MainContainer from "../../components/Layout/MainContainer";
import TermsPage from "../User/TermsPage";

const ContactPage = (): JSX.Element => {
  const [userState] = useUser();

  const { t } = useTranslation("translation");
  return (
    <MainContainer
      title={
        userState.needToAcceptTerms
          ? t("Pages.Contact.TermsUpdated")!
          : t("Pages.Contact.MainContainerTitle")!
      }
      hideFooterLinks={userState.needToAcceptTerms}
      hideMenu
    >
      <TermsPage />
    </MainContainer>
  );
};
export default ContactPage;
