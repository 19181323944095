export const BuildingActionTypes = {
  GET_BUILDING: "GET_BUILDING",
  GET_BUILDING_SUCCEEDED: "GET_BUILDING_SUCCEEDED",
  GET_MOVED_IN_BUILDING: "GET_MOVED_IN_BUILDING",
  GET_MOVED_IN_BUILDING_SUCCEEDED: "GET_MOVED_IN_BUILDING_SUCCEEDED",
  GET_BUILDING_PHOTOS: "GET_BUILDING_PHOTOS",
  GET_BUILDING_PHOTOS_SUCCEEDED: "GET_BUILDING_PHOTOS_SUCCEEDED",
  BUILDING_OPERATION_FAILED: "BUILDING_OPERATION_FAILED",
  MOVE_IN_BUILDING: "MOVE_IN_BUILDING",
  MOVE_IN_BUILDING_SUCCESS: "MOVE_IN_BUILDING_SUCCESS",
  MOVE_OUT_BUILDING: "MOVE_OUT_BUILDING",
  MOVE_OUT_BUILDING_SUCCESS: "MOVE_OUT_BUILDING_SUCCESS",
  GET_OWNED_BUILDINGS: "GET_OWNED_BUILDINGS",
  GET_OWNED_BUILDINGS_SUCCEEDED: "GET_OWNED_BUILDINGS_SUCCEEDED",
  GET_BUILDING_MEMBERS: "GET_BUILDING_MEMBERS",
  GET_BUILDING_MEMBERS_SUCCEEDED: "GET_BUILDING_MEMBERS_SUCCEEDED",
  UPDATE_MANAGER_INFO: "UPDATE_MANAGER_INFO",
  UPDATE_MANAGER_INFO_SUCCEEDED: "UPDATE_MANAGER_INFO_SUCCEEDED",
  SEND_SUGGESTION: "SEND_SUGGESTION",
  SEND_SUGGESTION_SUCCEEDED: "SEND_SUGGESTION_SUCCEEDED",
  SEND_MESSAGE_TO_TENANTS: "SEND_MESSAGE_TO_TENANTS",
  SEND_MESSAGE_TO_TENANTS_SUCCEEDED: "SEND_MESSAGE_TO_TENANTS_SUCCEEDED",
  CHANGE_MEMBER_ROLE: "CHANGE_BUILDING_MEMBER_ROLE",
  CHANGE_MEMBER_ROLE_SUCCEEDED: "CHANGE_BUILDING_MEMBER_ROLE_SUCCEEDED",
  INVITE_MEMBER: "INVITE_BUILDING_MEMBER",
  INVITE_MEMBER_SUCCEEDED: "INVITE_BUILDING_MEMBER_SUCCEEDED",
  ACCEPT_INVITE_MEMBER: "ACCEPT_INVITE_BUILDING_MEMBER",
  ACCEPT_INVITE_MEMBER_SUCCEEDED: "ACCEPT_INVITE_BUILDING_MEMBER_SUCCEEDED",
  REMOVE_MEMBER: "REMOVE_BUILDING_MEMBER",
  REMOVE_MEMBER_SUCCEEDED: "REMOVE_BUILDING_MEMBER_SUCCEEDED",
  INVITE_TENANT: "INVITE_BUILDING_TENANT",
  INVITE_TENANT_SUCCEEDED: "INVITE_BUILDING_TENANT_SUCCEEDED",
  REMOVE_PENDING_TENANT: "REMOVE_PENDING_BUILDING_TENANT",
  REMOVE_PENDING_TENANT_SUCCEEDED: "REMOVE_PENDING_BUILDING_TENANT_SUCCEEDED",
};
