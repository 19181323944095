import React, { useState } from "react";
import { Button, Grid, Link, styled, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

export const Wrapper = styled(Grid)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  background: theme.palette.background.grayPaper,
  padding: "30px 0",
  "& h3": {
    fontSize: 21,
    fontWeight: "bold",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "15px 0",
    "& h3": {
      fontSize: 16,
    },
  },
}));

export const ButtonsWrapper = styled(Grid)(({ theme }) => ({
  marginTop: 15,
  "& button:first-child": {
    marginRight: 15,
  },
}));

const CookiesBlock = (): JSX.Element => {
  const history = useHistory();
  const [visible, setVisible] = useState(true);
  const { t } = useTranslation("translation");

  const acceptCookies = () => {
    document.cookie =
      "isCookieAccepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    setVisible(false);
  };

  const rejectCookies = () => {
    setVisible(false);
  };

  const isHiddenCookie = () => {
    return document.cookie.includes("isCookieAccepted");
  };

  return (
    <>
      {visible && !isHiddenCookie() && (
        <Wrapper container justifyContent="center" zIndex={55}>
          <Grid item xs={11} md={9}>
            <Typography variant="h3">
              {t("Pages.StartPage.WeUseCookies")}
            </Typography>
            <Typography variant="body1">
              <Trans
                components={{ b: <b /> }}
                i18nKey="Pages.StartPage.ClickToAgreeThatWeUseCookies"
              />
              &nbsp;
              <Link onClick={() => history.push("/contact")} underline="always">                
                {t("Pages.Clubs.Here")}
              </Link>
            </Typography>
            <ButtonsWrapper item>
              <Button
                variant="contained"
                color="secondary"
                onClick={acceptCookies}
              >
                {t("General.Buttons.Accept")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={rejectCookies}
              >
                {t("General.Buttons.Reject")}
              </Button>
            </ButtonsWrapper>
          </Grid>
        </Wrapper>
      )}
    </>
  );
};

export default CookiesBlock;
