import React, { lazy } from "react";
import Layout from "./MyClimatePlanPageLayout";
import { Route, Switch, useRouteMatch } from "react-router";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import ClimatePlanArticlePage from "./pages/ClimatePlanArticlePage";
import { ThemeProvider } from "@emotion/react";
import { communeTheme } from "../../constants/theme";
const ClientPlanStartPageLazy = lazy(() => import("./pages/ClientsStartPage"));
const StartSustainabilityLazy = lazy(() => import("./pages/StartSustainabilityPage"));
const ClimatePlanStartPageLazy = lazy(() => import("./pages/ClimatePlanStartPage"));
const AboutStartPageLazy = lazy(() => import("./pages/AboutStartPage"));
const PressStartPageLazy = lazy(() => import("./pages/MediaStartPage"));
const TopMunicipalityPageLazy = lazy(() => import("./pages/TopMunicipalityPage"));
const TeamPageLazy = lazy(() => import("./pages/TeamPage"));

const MyClimatePlan = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <ThemeProvider theme={communeTheme}>
      <Layout>
        <ErrorBoundary>
          <Switch>
            {/* <Route path="/" exact render={() => <ClimatePlanStartPageLazy />} /> */}
            <Route path="/klimaplan" exact render={() => <ClimatePlanStartPageLazy />} />
            <Route path={path + "/sustainability"} component={StartSustainabilityLazy} />
            <Route path={path + "/clients"} component={ClientPlanStartPageLazy} />
            <Route path={path + "/about-start"} component={AboutStartPageLazy} />
            <Route path={path + "/media/:uniqueName"} component={ClimatePlanArticlePage} />
            <Route path={path + "/media"} component={PressStartPageLazy} />
            <Route path={path + "/top-municipality"} component={TopMunicipalityPageLazy} />
            <Route path={path + "/team"} component={TeamPageLazy} />
          </Switch>
        </ErrorBoundary>
      </Layout>
    </ThemeProvider>
  );
};

export default MyClimatePlan;
