import { applyMiddleware, Middleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from ".";
import UserService from "../services/user.service";
import { ClubStoreState } from "./club.reducer";
import {
  signalrConnector,
  actionLogger,
  googleAnalyticsTracker,
  facebookPixelTracker,
  // intercomTracker,
  // co2TempPlanChecker,
} from "./middlewares";
import { UserStoreState } from "./users.reducer";
import { MapStoreState } from "./map.reducer";
import { PostBoxStoreState } from "./postbox.reducer";
import { AdminStoreState } from "./admin/admin.reducer";
import { LandingAdminStoreState } from "./admin/admin-landing.reducer";
import { LandingStoreState } from "./landing.reducer";
import { NewsStreamStoreState } from "../pages/NewsStream/newsstream.reducer";
import { UnitStoreState } from "./unit.reducer";
import { ServiceBookStoreState } from "./servicebook.reducer";
import { WorkersStoreState } from "../pages/Workers/workers.reducer";
import { DocumentStoreState } from "./document.reducer";
import { FamilyStoreState } from "./family.reducer";
import { SubscriptionsStoreState } from "./subscriptions.reducer";
import { CommentInitialState, CommentStoreState } from "./comment.reducer";
import { CO2StoreState, CO2InitialState } from "../pages/ClimatePlan/shared/co2.reducer";
import { BehaviourRulesStoreState } from "./behavior-rules.reducer";
import { NavigationStoreState } from "../pages/Overview/navigation.reducer";
import { AdminNavigationStoreState } from "../pages/AdminPanel/pages/OverviewNavigation/admin.navigation.reducer";
import { PricePredictionsStoreState } from "../pages/AdminPanel/pages/PricePredictions/admin.price-prediction.reducer";
import { CO2PlanAdminStoreState } from "./admin/admin.co2plan.reducer";
import { AdminPartnersStoreState } from "../pages/AdminPanel/pages/OverviewNavigation/admin.partners.reducer";
import { NeighborGroupAdminStoreState } from "./admin/admin.neighborgroup.reducer";
import { NeighborGroupStoreState } from "./neighborgroup.reducer";
import { ChatStoreState } from "./chat.reducer";
import { BuildingStoreState } from "./building.reducer";
import { legacy_createStore } from "@reduxjs/toolkit";

export interface ApplicationState {
  user: UserStoreState;
  club: ClubStoreState;
  unit: UnitStoreState;
  building: BuildingStoreState
  map: MapStoreState;
  postbox: PostBoxStoreState;
  newsstream: NewsStreamStoreState;
  admin: AdminStoreState;
  adminLanding: LandingAdminStoreState;
  adminCo2Plan: CO2PlanAdminStoreState;
  landing: LandingStoreState;
  servicebook: ServiceBookStoreState;
  workers: WorkersStoreState;
  document: DocumentStoreState;
  family: FamilyStoreState;
  subscriptions: SubscriptionsStoreState;
  comment: CommentStoreState;
  co2: CO2StoreState;
  navigation: NavigationStoreState;
  adminPartners: AdminPartnersStoreState;
  adminNavigation: AdminNavigationStoreState;
  behaviourRules: BehaviourRulesStoreState;
  pricePredictions: PricePredictionsStoreState;
  adminNeighborGroup: NeighborGroupAdminStoreState;
  neighborGroup: NeighborGroupStoreState;
  chat: ChatStoreState;
}

const initialState: any = {
  user: {
    user: UserService.getCurrentUser(),
    themeName: UserService.getUserTheme(),
  },
  unit: {
    unit: null,
  },
  // document: {
  //   documentFolders: [],
  // },
  comment: CommentInitialState,
  co2: CO2InitialState,
};

// eslint-disable-next-line @typescript-eslint/ban-types
const middlewares: Middleware<{}, ApplicationState>[] = [
  thunk,
  signalrConnector,
  googleAnalyticsTracker,
  facebookPixelTracker,
  // intercomTracker,
  // co2TempPlanChecker,
];

//adding logger for the dev build
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  middlewares.push(actionLogger);
}

const store = legacy_createStore(
  rootReducer,
  initialState,
  // (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
