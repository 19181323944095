import React from "react";
import { Grid, Typography, Button, useMediaQuery, useTheme, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../actions/user.actions";
import TestimonialsCarousel from "./TestimonialsCarousel";
import MyHouseImage from "../../../assets/img/myHouseImage.jpg";
import CircleLabel from "./CircleLabel";
import Young from "./../../../assets/img/young.jpg";
import Old from "./../../../assets/img/old.jpg";
import WithKids from "./../../../assets/img/withKids.jpg";
import NewsComponent from "./NewsComponent";
import { useNavigation } from "../../Overview/navigation.actions";
import MenuListSlider from "./MenuListSlider";
import ScrollToTopButton from "../../MyClimatePlanPage/pages/Components/ScrollToTopButton";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.background.paper,
}));

const Banner = styled(Grid)(() => ({
  backgroundImage: `url(${MyHouseImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  width: "100%",
  minHeight: "750px",
  flex: 1,
  position: "relative",
}));

const StyledCenterGrid = styled(Grid)(({ theme }) => ({
  border: "1px solid" + " " + theme.palette.background.default + 50,
  borderRadius: 10,
}));

const RoundImage = styled("img")(({ theme }) => ({
  borderRadius: "50%",
  width: 110,
  height: 110,
  margin: 4,
  [theme.breakpoints.up("sm")]: {
    position: "relative",
    width: 150,
    height: 150,
  },
  [theme.breakpoints.up("md")]: {
    position: "relative",
    bottom: "90px",
  },
  [theme.breakpoints.up("lg")]: {
    width: 200,
    height: 200,
    bottom: "120px",
  },
}));

const WelcomePart = (): JSX.Element => {
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const [{ user }, { openLoginDialog, openRegisterDialog, setAuthDestination, logout }] = useUser();
  const [{ navigationItems }] = useNavigation();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    setAuthDestination(false, "/overview", 1, "/completeregistration");
  }, []);

  const loginClick = () => {
    openLoginDialog(false);
  };

  const changeAccount = () => {
    try {
      logout();
      loginClick();
    } catch (error) {
      console.log(error);
    }
  };

  const registerClick = () => {
    openRegisterDialog();
  };
  return (
    <>
      <Banner item container justifyContent={"center"}>
        <Grid item container justifyContent={"center"} alignItems="center" xs={11} sm={12}>
          <Grid
            item
            container
            direction={isSm ? "column-reverse" : "row"}
            xs={12}
            sm={11}
            py={2}
            md={8}
            sx={{ flexWrap: "nowrap" }}
          >
            {!isSm && (
              <Grid
                item
                px={2}
                container
                justifyContent={"center"}
                xs={12}
                md={6}
                sx={{ marginBottom: isSm ? 0 : "50px", flexWrap: "nowrap" }}
              >
                <NewsComponent />
              </Grid>
            )}
            <Grid item px={2} mb={isSm ? 0 : 6} container justifyContent={"center"} xs={12} md={6}>
              <StyledCenterGrid p={2} pt={3} item container direction="column" justifyContent="center">
                <Grid item container justifyContent={"center"}>
                  <Grid item xs={12}>
                    <StyledTypography align="center" variant="h1" pt={0} pb={0} mt={isSm ? "20px" : 0} mb={1}>
                      {t("Pages.StartPage.WelcomeToMyhouse", { hostname: window.location.hostname })}
                    </StyledTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledTypography paragraph align="center" variant="body1" pt={0} mt={isSm ? "20px" : 0} mb={1}>
                      {t("Pages.StartPage.YourDigitalUniverse", { hostname: window.location.hostname })}
                    </StyledTypography>
                  </Grid>
                </Grid>

                <Grid mt={3} container mb={2} alignItems="center" justifyContent="center">
                  {!user ? (
                    <>
                      <Grid item xs={6}>
                        <StyledTypography variant="body1">{t("Authorization.Buttons.AlreadyHave")}</StyledTypography>
                      </Grid>
                      <Grid item>
                        <Button sx={{ minWidth: "95px" }} variant="contained" color="success" onClick={loginClick}>
                          {t("Authorization.Buttons.LogIn")}
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={6}>
                        <StyledTypography variant="body1">{`${t("Authorization.Buttons.AlreadyLogin")} ${
                          user.Name
                        }`}</StyledTypography>
                      </Grid>
                      <Grid item>
                        <Button sx={{ minWidth: "95px" }} variant="contained" color="success" onClick={changeAccount}>
                          {t("Authorization.Buttons.ChangeAccount")}
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
                {!user && (
                  <Grid container alignItems="center" mb={2} justifyContent="center">
                    <Grid item xs={6}>
                      <StyledTypography variant="body1" marginRight={3} color={"#fff"}>
                        {t("Pages.StartPage.NewUser")}
                      </StyledTypography>
                    </Grid>
                    <Grid item>
                      <Button sx={{ minWidth: "95px" }} variant="contained" color="success" onClick={registerClick}>
                        {t("General.Buttons.Create")}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </StyledCenterGrid>
            </Grid>
          </Grid>

          <Grid item p={2} py={0} container justifyContent="center" xs={12} sm={11} md={4}>
            <Grid item sx={{ maxWidth: isSm ? "100%" : "380px", width: "100%" }}>
              {!isSm && <TestimonialsCarousel backgroundColor={theme.palette.grey[900] + 70} />}
              <CircleLabel />
            </Grid>
          </Grid>
        </Grid>
        {isSm && (
          <MenuListSlider
            navigationItems={navigationItems?.filter((item) => item.IsPublished && item.ShowAtStartPage) || []}
          />
        )}
        <Grid
          item
          container
          xs={12}
          md={11}
          pb={isSm ? 2 : 0}
          justifyContent={isSm ? "space-around" : "flex-start"}
          sx={{ height: isSm ? "auto" : 0 }}
        >
          <RoundImage src={Young} alt="" />
          <RoundImage src={WithKids} alt="" />
          <RoundImage src={Old} alt="" />
        </Grid>

        {isSm && (
          <Grid item px={5} container direction="column" justifyContent={"center"} xs={12}>
            <NewsComponent />
            <Grid container pt={2}>
              <TestimonialsCarousel backgroundColor={theme.palette.grey[900] + 70} />
            </Grid>
          </Grid>
        )}
        {isXs && <ScrollToTopButton />}
      </Banner>
      {!isSm && (
        <MenuListSlider
          navigationItems={navigationItems?.filter((item) => item.IsPublished && item.ShowAtStartPage) || []}
        />
      )}
    </>
  );
};

export default WelcomePart;
