import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import { MenuItem, Popper, ClickAwayListener, Paper, MenuList, PaperProps } from "@mui/material";
import { useHistory } from "react-router-dom";
import { UserType } from "../../constants/enums";
import { useUser } from "../../actions";
import { useTranslation } from "react-i18next";
import NavigationHelper from "../../helpers/navigation-helper";

type LinkComponentProps = {
  component?: React.ElementType;
  to?: string;
};

const StyledMenuItem = styled(MenuItem)<LinkComponentProps>({
  padding: "2px 16px",
  minHeight: "auto",
});

type PopperMenuProps = {
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  paperProps?: PaperProps;
};

const PopperMenu = (props: PopperMenuProps) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const [{ user, mainUser }, actions] = useUser();
  const klimaplan = NavigationHelper.IsClimateSite();
  const isAdminUser = !!user?.Role && user?.Role >= UserType.ExternalManager;
  const isPartnerUser = !!user?.Role && user?.MovedInBuildings?.length > 0;

  const goToProfile = () => {
    props.setAnchorEl(null);
    history.push("/profile");
  };

  const goToOverview = () => {
    props.setAnchorEl(null);
    history.push("/overview");
  };

  const logOut = () => {
    props.setAnchorEl(null);
    actions.logout();
    history.push("/");
  };

  const unTryUser = () => {
    props.setAnchorEl(null);
    actions.unTryUser();
  };

  const handleClose = () => {
    props.setAnchorEl(null);
  };

  const menuListItems = useMemo(() => {
    let itemsArray = [
      {
        text: t("Menu.Profil"),
        onClick: goToProfile,
      },
      {
        text: mainUser ? `(${user?.Name}) ${t("Menu.LogOut")}` : t("Menu.LogOut"),
        onClick: mainUser ? unTryUser : logOut,
      },
    ];

    if (user && user.Rating >= 1 && !klimaplan) {
      itemsArray = [
        {
          text: t("Pages.Overview.OverviewTitle"),
          onClick: goToOverview,
        },
        ...itemsArray,
      ];
    }

    if (isAdminUser) {
      itemsArray = [...itemsArray, { text: t("Menu.Admin"), onClick: () => history.push("/admin") }];
    }

    if (isAdminUser || isPartnerUser) {
      itemsArray = [...itemsArray, { text: t("Menu.Partner"), onClick: () => history.push("/partner") }];
    }

    return itemsArray;
  }, [user]);

  return (
    <Popper open={Boolean(props.anchorEl)} anchorEl={props.anchorEl} disablePortal placement="bottom-end">
      <Paper style={{ zIndex: 2 }} {...props.paperProps}>
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList autoFocusItem={Boolean(props.anchorEl)} style={{ padding: 0 }}>
            {menuListItems.map((item) => (
              <StyledMenuItem onClick={item?.onClick} key={item?.text}>
                {item?.text}
              </StyledMenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
};

export default PopperMenu;
