import { useSelector } from "react-redux";
import { operationFailedActionGeneral, useAppDispatch } from ".";
import { BuildingActionTypes } from "../constants/building.actiontypes";
import { AppAction, AppActionThunk, AppThunkDispatch } from "../definitions/Action";
import { ApplicationState } from "../reducers/store";
import BuildingService from "../services/building.service";
import { Building } from "../definitions/model/Building";
import User from "../definitions/model/User";
import { GroupMemberRole } from "../constants/enums";
import { Clear } from "@mui/icons-material";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, BuildingActionTypes.BUILDING_OPERATION_FAILED);
};

const clearLastError = (): AppAction => {
  return { type: BuildingActionTypes.BUILDING_OPERATION_FAILED, payload: null };
};

export const getBuilding =
  (buildingId: string): AppActionThunk<Promise<Building | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: BuildingActionTypes.GET_BUILDING });
      const result = await BuildingService.getBuilding(buildingId);
      dispatch({ type: BuildingActionTypes.GET_BUILDING_SUCCEEDED, payload: result });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getBuildingPhotos =
  (houseNumber: number, street: string, postalCode: number): AppActionThunk<Promise<string[] | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({ type: BuildingActionTypes.GET_BUILDING_PHOTOS });
      const result = await BuildingService.getBuildingPhotos(houseNumber, street, postalCode);
      dispatch({ type: BuildingActionTypes.GET_BUILDING_PHOTOS_SUCCEEDED });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const moveInToBuilding =
  (buildingId: string): AppActionThunk<Promise<{ User: User; Building: Building } | undefined>> =>
  async (dispatch) => {
    try {
      dispatch({
        type: BuildingActionTypes.MOVE_IN_BUILDING,
      });
      const result = await BuildingService.moveInToBuilding(buildingId);
      dispatch({
        type: BuildingActionTypes.MOVE_IN_BUILDING_SUCCESS,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const moveOutBuilding =
  (buildingId: string): AppActionThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch({ type: BuildingActionTypes.MOVE_OUT_BUILDING });
    try {
      const result = await BuildingService.moveOutBuilding(buildingId);
      dispatch({
        type: BuildingActionTypes.MOVE_OUT_BUILDING_SUCCESS,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getOwnedBuildings = (signal?: AbortSignal) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: BuildingActionTypes.GET_OWNED_BUILDINGS });
    const result = await BuildingService.getOwnedBuildings(signal);
    dispatch({
      type: BuildingActionTypes.GET_OWNED_BUILDINGS_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

export const getBuildingMembers = (buildingId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: BuildingActionTypes.GET_BUILDING_MEMBERS });
    const result = await BuildingService.getBuildingMembers(buildingId);
    dispatch({
      type: BuildingActionTypes.GET_BUILDING_MEMBERS_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const updateManagerInfo =
  (buildingId: string, email: string): AppActionThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch({ type: BuildingActionTypes.UPDATE_MANAGER_INFO });
      await BuildingService.updateBuildingManagerInfo(buildingId, email);
      dispatch({ type: BuildingActionTypes.UPDATE_MANAGER_INFO_SUCCEEDED });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const sendSuggestion =
  (unitId: string, suggestion: string): AppActionThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch({ type: BuildingActionTypes.SEND_SUGGESTION });
      await BuildingService.sendSuggestion(unitId, suggestion);
      dispatch({ type: BuildingActionTypes.SEND_SUGGESTION_SUCCEEDED });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const sendMessageToTenants =
  (buildingId: string, recipients: string[], message: string): AppActionThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      dispatch({ type: BuildingActionTypes.SEND_MESSAGE_TO_TENANTS });
      await BuildingService.sendMessageToTenants(buildingId, recipients, message);
      dispatch({ type: BuildingActionTypes.SEND_MESSAGE_TO_TENANTS_SUCCEEDED });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const changeMemberRole =
  (buildingId: string, userId: string, role: GroupMemberRole) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({ type: BuildingActionTypes.CHANGE_MEMBER_ROLE });
      const result = await BuildingService.changeMemberRole(buildingId, userId, role);
      dispatch({
        type: BuildingActionTypes.CHANGE_MEMBER_ROLE_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const inviteMember =
  (buildingId: string, name: string, email: string, role: GroupMemberRole) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({ type: BuildingActionTypes.INVITE_MEMBER });
      const result = await BuildingService.inviteMember(buildingId, name, email, role);
      dispatch({
        type: BuildingActionTypes.INVITE_MEMBER_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const acceptInviteMember = (buildingId: string, token: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch(clearLastError());
    dispatch({ type: BuildingActionTypes.ACCEPT_INVITE_MEMBER });
    const result = await BuildingService.acceptInviteMember(buildingId, token);
    dispatch({
      type: BuildingActionTypes.ACCEPT_INVITE_MEMBER_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const removeMember = (buildingId: string, memberId: string, token: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: BuildingActionTypes.REMOVE_MEMBER });
    const result = await BuildingService.removeMember(buildingId, memberId, token);
    dispatch({
      type: BuildingActionTypes.REMOVE_MEMBER_SUCCEEDED,
      payload: memberId,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const inviteTenant =
  (buildingId: string, unitId: string, name: string, email: string) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({ type: BuildingActionTypes.INVITE_TENANT });
      const result = await BuildingService.inviteTenant(buildingId, unitId, name, email);
      dispatch({
        type: BuildingActionTypes.INVITE_TENANT_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const removePendingTenant =
  (buildingId: string, unitId: string, memberId: string, token: string) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({ type: BuildingActionTypes.REMOVE_PENDING_TENANT });
      const result = await BuildingService.removePendingTenant(buildingId, unitId, memberId, token);
      dispatch({
        type: BuildingActionTypes.REMOVE_PENDING_TENANT_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const useBuildingActions = () => {
  const dispatch = useAppDispatch();

  return {
    getBuilding: (buildingId: string) => dispatch(getBuilding(buildingId)),
    getBuildingPhotos: (houseNumber: number, street: string, postalCode: number) =>
      dispatch(getBuildingPhotos(houseNumber, street, postalCode)),
    moveInToBuilding: (buildingId: string) => dispatch(moveInToBuilding(buildingId)),
    moveOutBuilding: (buildingId: string) => dispatch(moveOutBuilding(buildingId)),
    getOwnedBuildings: (signal?: AbortSignal) => dispatch(getOwnedBuildings(signal)),
    getBuildingMembers: (buildingId: string) => dispatch(getBuildingMembers(buildingId)),
    updateManagerInfo: (buildingId: string, email: string) => dispatch(updateManagerInfo(buildingId, email)),
    sendSuggestion: (unitId: string, suggestion: string) => dispatch(sendSuggestion(unitId, suggestion)),
    sendMessageToTenants: (buildingId: string, recipients: string[], message: string) =>
      dispatch(sendMessageToTenants(buildingId, recipients, message)),
    changeMemberRole: (buildingId: string, userId: string, role: GroupMemberRole) =>
      dispatch(changeMemberRole(buildingId, userId, role)),
    inviteMember: (buildingId: string, name: string, email: string, role: GroupMemberRole) =>
      dispatch(inviteMember(buildingId, name, email, role)),
    acceptInviteMember: (buildingId: string, token: string) => dispatch(acceptInviteMember(buildingId, token)),
    removeMember: (buildingId: string, memberId: string, token: string) =>
      dispatch(removeMember(buildingId, memberId, token)),
    inviteTenant: (buildingId: string, unitId: string, name: string, email: string) =>
      dispatch(inviteTenant(buildingId, unitId, name, email)),
    removePendingTenant: (buildingId: string, unitId: string, memberId: string, token: string) =>
      dispatch(removePendingTenant(buildingId, unitId, memberId, token)),
  };
};

export const useBuildingState = () => useSelector((state: ApplicationState) => state.building);

export const useBuilding = (): [ReturnType<typeof useBuildingState>, ReturnType<typeof useBuildingActions>] => {
  const state = useBuildingState();
  const actions = useBuildingActions();

  return [state, actions];
};
