import { Dispatch } from "redux";
import { AppAction, AppActionThunk } from "../../definitions/Action";
import { AdminActionTypes } from "../../constants/admin/admin.actiontypes";
import MapService from "../../services/map.service";
import AdminService from "../../services/admin/admin.service";
import {
  RegisterLandingContract,
  BenefitClub,
  RegisterBenefitClubContract,
  UpdateBenefitClubContract,
} from "../../definitions/Landing";
import {
  DashboardActionRequest,
  DashboardEntryModel,
  SubscriptionChangeActionPayload,
} from "../../definitions/model/Dashboard";
import UserService from "../../services/user.service";
import { DashboardUpdatingFrequency, UserType } from "../../constants/enums";
import { ClubModel } from "../../definitions/model/Club";
import { SubscriptionModel } from "../../definitions/model/Subscriptions";
import SubscriptionsService from "../../services/subscriptions.service";
import { SearchResult } from "../../definitions/model/SearchResult";
import CommentService from "../../services/comment.service";
import { CO2PlanActionTypes } from "../../pages/ClimatePlan/shared/co2plan.actiontypes";
import User from "../../definitions/model/User";
import { AdminInfoGroup, AdminUserinfo } from "../../pages/AdminPanel/pages/AggregateViewPage/AggregateViewPage";
import { operationFailedActionGeneral, useAppDispatch } from "..";
import { ReplacementModel } from "../../definitions/model/SystemMetaModel";
import { SystemStringModel, SystemStringType } from "../../definitions/model/SystemString";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../reducers/store";

export const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, AdminActionTypes.ADMIN_OPERATION_FAILED);
};

export const getMovedIn = () => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: AdminActionTypes.GET_MOVED_IN,
    });
    const result = await MapService.getMovedIn();
    dispatch({
      type: AdminActionTypes.GET_MOVED_IN_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const addNews = (name: string, templateId: string | null) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: AdminActionTypes.ADD_NEWS,
    });
    const data: RegisterLandingContract = {
      Name: name,
      TemplateId: templateId,
    };

    const result = await AdminService.addNews(data);
    dispatch({
      type: AdminActionTypes.ADD_NEWS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getNews = (filter: string, skip: number) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminActionTypes.GET_NEWS });
    const result = await AdminService.getNews(filter, skip);
    dispatch({
      type: AdminActionTypes.GET_NEWS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getNewsEditor = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminActionTypes.GET_NEWS_EDITOR });
    const result = await AdminService.getNewsEditor(id);
    dispatch({
      type: AdminActionTypes.GET_NEWS_EDITOR_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const deleteNews = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminActionTypes.DELETE_NEWS });
    const result = await AdminService.deleteNews(id);
    dispatch({
      type: AdminActionTypes.DELETE_NEWS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const setNewsPublish = (id: string, publish: boolean) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminActionTypes.SET_NEWS_PUBLISH });
    const result = await AdminService.setNewsPublish(id, publish);
    dispatch({
      type: AdminActionTypes.SET_NEWS_PUBLISH_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getAdminUsers = () => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminActionTypes.GET_ADMIN_USERS });
    const result = await UserService.getAdministrators();
    dispatch({
      type: AdminActionTypes.GET_ADMIN_USERS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const updateUserRole =
  (id: string, role: UserType, communeNumber: number) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: AdminActionTypes.UPDATE_USER_ROLE });
      await UserService.changeUserRole(id, role, communeNumber);
      dispatch({
        type: AdminActionTypes.UPDATE_USER_ROLE_SUCCEEDED,
        payload: { id: id, role: role },
      });
      const updatedUsers = await UserService.getAdministrators();
      dispatch({
        type: AdminActionTypes.GET_ADMIN_USERS_SUCCEEDED,
        payload: updatedUsers,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getUsersByEmailPart = (filter: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminActionTypes.GET_USERS_BY_EMAIL_PART });
    const users = await UserService.getUsersByEmailPart(filter);
    dispatch({
      type: AdminActionTypes.GET_USERS_BY_EMAIL_PART_SUCCEEDED,
      payload: users,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getDashboardEntries = () => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminActionTypes.GET_DASHBOARD_ENTRIES });
    const result = await AdminService.getDashboardEntries();
    dispatch({
      type: AdminActionTypes.GET_DASHBOARD_ENTRIES_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const changeDashboardSubscription =
  (data: DashboardActionRequest): AppActionThunk<Promise<any>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminActionTypes.UPDATE_DASHBOARD_SUBSCRIPTION });
      const result = await AdminService.changeDashboardSubscription(data);
      dispatch({
        type: AdminActionTypes.UPDATE_DASHBOARD_SUBSCRIPTION_SUCCEEDED,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const changeLocalSubscription = (data: SubscriptionChangeActionPayload) => (dispatch: Dispatch<AppAction>) => {
  dispatch({
    type: AdminActionTypes.CHANGE_DASHBOARD_SUBSCRIPTION,
    payload: data,
  });
};

export const updateEntry =
  (id: string, name: string, frequency: DashboardUpdatingFrequency, environment: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<DashboardEntryModel> => {
    try {
      dispatch({
        type: AdminActionTypes.UPDATE_DASHBOARD_ENTRY,
      });
      const result = await AdminService.updateDashboardEntry(id, name, frequency, environment);
      dispatch({
        type: AdminActionTypes.UPDATE_DASHBOARD_ENTRY_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const deleteEntry =
  (id: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<void> => {
    try {
      dispatch({
        type: AdminActionTypes.DELETE_DASHBOARD_ENTRY,
      });
      await AdminService.deleteDashboardEntry(id);
      dispatch({
        type: AdminActionTypes.DELETE_DASHBOARD_ENTRY_SUCCEEDED,
        payload: id,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const createEntry =
  (name: string, frequency: DashboardUpdatingFrequency, environment: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<DashboardEntryModel> => {
    try {
      dispatch({
        type: AdminActionTypes.CREATE_DASHBOARD_ENTRY,
      });
      const result = await AdminService.createDashboardEntry(name, frequency, environment);
      dispatch({
        type: AdminActionTypes.CREATE_DASHBOARD_ENTRY_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const clearError = () => operationFailedAction("");

export const getBenefitClub =
  (id: string): AppActionThunk<Promise<BenefitClub | undefined>> =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: AdminActionTypes.GET_BENEFIT_CLUB });
      const result = await AdminService.getBenefitClub(id);
      dispatch({
        type: AdminActionTypes.GET_BENEFIT_CLUB_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getBenefitClubs =
  (skip: number, take: number): AppActionThunk<Promise<Array<BenefitClub> | undefined>> =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: AdminActionTypes.GET_BENEFIT_CLUBS });
      const result = await AdminService.getBenefitClubs(skip, take);
      dispatch({
        type: AdminActionTypes.GET_BENEFIT_CLUBS_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const addBenefitClub =
  (data: RegisterBenefitClubContract): AppActionThunk<Promise<BenefitClub | undefined>> =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: AdminActionTypes.ADD_BENEFIT_CLUB });
      const result = await AdminService.addBenefitClub(data);
      dispatch({
        type: AdminActionTypes.ADD_BENEFIT_CLUB_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const setBenefitClubPublish =
  (id: string, publish: boolean): AppActionThunk<Promise<BenefitClub | undefined>> =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: AdminActionTypes.SET_BENEFIT_CLUB_PUBLISH });
      const result = await AdminService.setBenefitClubPublish(id, publish);
      dispatch({
        type: AdminActionTypes.SET_BENEFIT_CLUB_PUBLISH_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const updateBenefitClub =
  (data: UpdateBenefitClubContract): AppActionThunk<Promise<BenefitClub | undefined>> =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: AdminActionTypes.UPDATE_BENEFIT_CLUB });
      const result = await AdminService.updateBenefitClub(data);
      dispatch({
        type: AdminActionTypes.UPDATE_BENEFIT_CLUB_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const deleteBenefitClub =
  (id: string): AppActionThunk<Promise<boolean | undefined>> =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: AdminActionTypes.DELETE_BENEFIT_CLUB });
      const result = await AdminService.deleteBenefitClub(id);
      dispatch({
        type: AdminActionTypes.DELETE_BENEFIT_CLUB_SUCCEEDED,
        payload: id,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const reorderBenefitClubs =
  (clubs: BenefitClub[]): AppActionThunk<Promise<BenefitClub[]>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminActionTypes.REORDER_BENEFIT_CLUBS });
      const result = await AdminService.reorederBenefitClubs(clubs);
      dispatch({
        type: AdminActionTypes.REORDER_BENEFIT_CLUBS_SUCCEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      return [];
    }
  };

export const getClubs =
  (skip: number): AppActionThunk<Promise<Array<ClubModel> | undefined>> =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: AdminActionTypes.GET_CLUBS });
      const result = await AdminService.getClubs(skip);
      dispatch({
        type: AdminActionTypes.GET_CLUBS_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getProductSubscriptions =
  (
    productId: string,
    skip: number,
    take: number,
    isDescending: boolean,
    sortingColumn: string,
    searchTerm: string
  ): AppActionThunk<Promise<SearchResult<SubscriptionModel>>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminActionTypes.GET_PRODUCT_SUBSCRIPTIONS });
      const result = await SubscriptionsService.getProductSubscriptions(
        productId,
        skip,
        take,
        isDescending,
        sortingColumn,
        searchTerm
      );
      dispatch({
        type: AdminActionTypes.GET_PRODUCT_SUBSCRIPTIONS_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const cancelSubscription =
  (subscriptionId: string): AppActionThunk<Promise<SubscriptionModel>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminActionTypes.CANCEL_SUBSCRIPTION });
      const result = await SubscriptionsService.cancelUserSubscription(subscriptionId);
      dispatch({
        type: AdminActionTypes.CANCEL_SUBSCRIPTION_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const createTestSubscription =
  (email: string, productId: string, priceId: string): AppActionThunk<Promise<SubscriptionModel>> =>
  async (dispatch) => {
    try {
      dispatch({ type: AdminActionTypes.CREATE_TEST_SUBSCRIPTION });
      const result = await SubscriptionsService.createTestSubscription(email, productId, priceId);
      dispatch({
        type: AdminActionTypes.CREATE_TEST_SUBSCRIPTION_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const getNotApprovedComments = (skip: number, take: number) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminActionTypes.GET_NOT_APPROVED_COMMENTS });
    const result = await CommentService.getNotApprovedComments(skip, take);
    dispatch({
      type: AdminActionTypes.GET_NOT_APPROVED_COMMENTS_SUCCEEDED,
      payload: result,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const approvedComment = (commentId: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    await CommentService.approveComment(commentId);
    dispatch({
      type: AdminActionTypes.COMMENT_APRROVE_SUCCEEDED,
      payload: commentId,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const clearSelectedCO2Plan = () => (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({
      type: CO2PlanActionTypes.SET_CO2_PLAN,
      payload: null,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const clearSubscription = () => ({
  type: AdminActionTypes.CLEAR_PRODUCT_SUBSCRIPTION,
});

export const getSubscribedToLeadAlarm =
  () =>
  async (dispatch: Dispatch<AppAction>): Promise<User[]> => {
    try {
      dispatch({ type: AdminActionTypes.GET_SUBSCRIBED_TO_LEAD_ALARM });
      const result = await UserService.getSubscribedToLeadAlarm();
      dispatch({
        type: AdminActionTypes.GET_SUBSCRIBED_TO_LEAD_ALARM_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const getNotSubscribedToLeadAlarmAdmins =
  (emailPart: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<User[]> => {
    try {
      dispatch({
        type: AdminActionTypes.GET_UNSUBSCRIBED_TO_LEAD_ALARM_ADMINS,
      });
      const result = await UserService.getUnSubscribedToLeadAlarmAdmins(emailPart);
      dispatch({
        type: AdminActionTypes.GET_UNSUBSCRIBED_TO_LEAD_ALARM_ADMINS_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const subscribeToLeadAlarm =
  (userId: string, utmSource: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<User> => {
    try {
      dispatch({ type: AdminActionTypes.SUBSCRIBE_TO_LEAD_ALARM });
      const result = await UserService.subscribeToLeadAlarm(userId, utmSource);
      dispatch({
        type: AdminActionTypes.SUBSCRIBE_TO_LEAD_ALARM_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const unsubscribeFromLeadAlarm =
  (userId: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<User> => {
    try {
      dispatch({ type: AdminActionTypes.UNSUBSCRIBE_FROM_LEAD_ALARM });
      const result = await UserService.unsubscribeFromLeadAlarm(userId);
      dispatch({
        type: AdminActionTypes.UNSUBSCRIBE_FROM_LEAD_ALARM_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const getSystemStrings =
  (type?: SystemStringType) =>
  async (dispatch: Dispatch<AppAction>): Promise<SystemStringModel[]> => {
    try {
      dispatch({ type: AdminActionTypes.GET_UTM_SOURCES });
      const result = await AdminService.getSystemStrings(type);
      dispatch({
        type: AdminActionTypes.GET_UTM_SOURCES_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const addSystemStrings =
  (value: string, type: SystemStringType) =>
  async (dispatch: Dispatch<AppAction>): Promise<SystemStringModel> => {
    try {
      dispatch({ type: AdminActionTypes.ADD_UTM_SOURCES });
      const result = await AdminService.addSystemString(value, type);
      dispatch({
        type: AdminActionTypes.ADD_UTM_SOURCES_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const getAdminUserInfo =
  (searchData: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<(AdminUserinfo | AdminInfoGroup)[]> => {
    try {
      dispatch({ type: AdminActionTypes.GET_ADMIN_USER_INFO });
      const info = await UserService.getAdminUserInfo(searchData);
      if (!info?.Id) {
        dispatch(operationFailedAction("No data for this unit"));
        return [];
      }
      const groups = await UserService.getAdminUserGroups(info?.Id);

      dispatch({
        type: AdminActionTypes.GET_ADMIN_USER_INFO_SUCCEEDED,
        payload: [info, groups],
      });
      return [info, groups];
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const getAdminAggregate = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminActionTypes.GET_ADMIN_USER_GROUPS_AGGREGATE });
    const aggregate = await UserService.getAdminAggregate(id);
    dispatch({
      type: AdminActionTypes.GET_ADMIN_USER_GROUPS_AGGREGATE_SUCCEEDED,
      payload: aggregate,
    });
    return aggregate;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getSystemMetaList = () => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminActionTypes.GET_SYSTEM_META_LIST });
    const result = await AdminService.getSystemMetaList();
    dispatch({
      type: AdminActionTypes.GET_SYSTEM_META_LIST_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getSystemMeta = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminActionTypes.GET_SYSTEM_META });
    const result = await AdminService.getSystemMeta(id);
    dispatch({
      type: AdminActionTypes.GET_SYSTEM_META_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const CreateSystemMeta =
  (hostname: string, path: string, replacements: ReplacementModel) => async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: AdminActionTypes.CREATE_SYSTEM_META });
      const result = await AdminService.createSystemMeta(hostname, path, replacements);
      dispatch({
        type: AdminActionTypes.CREATE_SYSTEM_META_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const UpdateSystemMeta =
  (id: string, hostname: string, path: string, replacements: ReplacementModel) =>
  async (dispatch: Dispatch<AppAction>) => {
    try {
      dispatch({ type: AdminActionTypes.UPDATE_SYSTEM_META });
      const result = await AdminService.updateSystemMeta(id, hostname, path, replacements);
      dispatch({
        type: AdminActionTypes.UPDATE_SYSTEM_META_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const deleteSystemMeta = (id: string) => async (dispatch: Dispatch<AppAction>) => {
  try {
    dispatch({ type: AdminActionTypes.DELETE_SYSTEM_META });
    const result = await AdminService.deleteSystemMeta(id);
    dispatch({
      type: AdminActionTypes.DELETE_SYSTEM_META_SUCCEEDED,
      payload: id,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const getStopWatcherData = () => async (dispatch: Dispatch<AppAction>) => {
  try {
    const result = await AdminService.getStopWatcherData();
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const switchStopWatcher = () => async (dispatch: Dispatch<AppAction>) => {
  try {
    const result = await AdminService.switchStopWatcher();
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const useAdminActions = () => {
  const dispatch = useAppDispatch();

  return {
    getAdminUserInfo: (searchData: string) => dispatch(getAdminUserInfo(searchData)),
    getAdminAggregate: (aggregateId: string) => dispatch(getAdminAggregate(aggregateId)),
    getStopWatcherData: () => dispatch(getStopWatcherData()),
    switchStopWatcher: () => dispatch(switchStopWatcher()),
    getSubscribedToLeadAlarm: () => dispatch(getSubscribedToLeadAlarm()),
    subscribeToLeadAlarm: (userId: string, utmSource: string) => dispatch(subscribeToLeadAlarm(userId, utmSource)),
    unsubscribrFromLeadAlarm: (userId: string) => dispatch(unsubscribeFromLeadAlarm(userId)),
    getNotSubsribedToLeadAlarm: (emailPart: string) => dispatch(getNotSubscribedToLeadAlarmAdmins(emailPart)),
    clearError: () => dispatch(clearError()),
    addSystemStrings: (value: string, type: SystemStringType) => dispatch(addSystemStrings(value, type)),
    getSystemStrings: (type?: SystemStringType) => dispatch(getSystemStrings(type)),
    createSystemMeta: (hostname: string, path: string, replacements: ReplacementModel) =>
      dispatch(CreateSystemMeta(hostname, path, replacements)),
    updateSystemMeta: (id: string, hostname: string, path: string, replacements: ReplacementModel) =>
      dispatch(UpdateSystemMeta(id, hostname, path, replacements)),
    getSystemMetaList: () => dispatch(getSystemMetaList()),
    deleteSystemMeta: (id: string) => dispatch(deleteSystemMeta(id)),
  };
};

const useAdminSate = () => useSelector((state: ApplicationState) => state.admin);

export const useAdmin = (): [ReturnType<typeof useAdminSate>, ReturnType<typeof useAdminActions>] => {
  const state = useAdminSate();
  const actions = useAdminActions();

  return [state, actions];
};
