import { SearchResult } from "../../../definitions/model/SearchResult";
import ApiService from "../../../services/api.service";
import {
  CO2Plan,
  CO2UpdatePlanPersonsRequest,
  CO2UpdateTempPlanPersonsRequest,
  MarkTimelineActionAsFulfilledRequest,
  SaveEmissionSource,
  CO2PlanPerson,
  CO2SponsorSolution,
  CO2PropertyDescription,
  CO2RegisterTempPlanResponse,
} from "./CO2Plan";
import { MarkTempTimelineActionAsFulfilledRequest } from "./CO2Plan";

const CO2PlanService = {
  getPlan: async (planId: string, unitId: string): Promise<CO2Plan> => {
    const url = `/api/CO2Plan/${planId}/?unitId=${unitId}`;
    const result = await ApiService.get<CO2Plan>(url);
    return result;
  },
  registerPlan: async (
    unitId: string,
    familyMembers: CO2PlanPerson[],
    isSinglePersonPlan: boolean,
    IgnoreMembersList: string[],
    isMultiSelect: boolean
  ): Promise<CO2Plan> => {
    const url = `/api/CO2Plan`;
    const data = {
      UnitId: unitId,
      FamilyMembers: familyMembers,
      IsSinglePersonPlan: isSinglePersonPlan,
      IgnoreMembersList,
      IsMultiSelect: isMultiSelect,
    };
    const result = await ApiService.post<CO2Plan>(url, data);
    return result;
  },
  updatePersons: async (request: CO2UpdatePlanPersonsRequest): Promise<CO2Plan> => {
    const url = `/api/CO2Plan`;
    const result = await ApiService.put<CO2Plan>(url, request);
    return result;
  },
  saveEmissionSurvey: async (
    planId: string,
    unitId: string,
    initialEmissionSources: SaveEmissionSource[] | null,
    targetEmissionSources: SaveEmissionSource[] | null,
    customQuestions: SaveEmissionSource[] | null
  ): Promise<CO2Plan> => {
    const url = `/api/CO2Plan/${planId}/saveEmissionSurvey`;
    const data = {
      UnitId: unitId,
      initialEmissionSources,
      targetEmissionSources,
      CustomQuestions: customQuestions,
    };
    const result = await ApiService.post<CO2Plan>(url, data);
    return result;
  },
  markTimelineActionAsFulfilled: async (request: MarkTimelineActionAsFulfilledRequest): Promise<void> => {
    const url = `/api/CO2Plan/markTimelineActionAsFulfilled`;
    await ApiService.post<CO2Plan>(url, request);
  },

  getTempPlan: async (tempPlanId: string): Promise<CO2Plan> => {
    const url = `/api/CO2PlanAnon?tempPlanId=${tempPlanId}`;
    const result = await ApiService.get<CO2Plan>(url);
    return result;
  },
  registerTempPlan: async (
    propertyDescription: CO2PropertyDescription,
    familyMembers: CO2PlanPerson[],
    isSinglePersonPlan: boolean
  ): Promise<CO2RegisterTempPlanResponse> => {
    const url = `/api/CO2PlanAnon`;
    const data = {
      PropertyDescription: propertyDescription,
      FamilyMembers: familyMembers,
      IsSinglePersonPlan: isSinglePersonPlan,
    };
    const result = await ApiService.post<CO2RegisterTempPlanResponse>(url, data);
    return result;
  },
  updateTempPersons: async (request: CO2UpdateTempPlanPersonsRequest): Promise<CO2Plan> => {
    const url = `/api/CO2PlanAnon/updatePersons`;
    const result = await ApiService.post<CO2Plan>(url, request);
    return result;
  },
  saveTempEmissionSurvey: async (
    tempPlanId: string,
    initialEmissionSources: SaveEmissionSource[] | null,
    targetEmissionSources: SaveEmissionSource[] | null
  ): Promise<CO2Plan> => {
    const url = `/api/CO2PlanAnon/saveEmissionSurvey`;
    const data = {
      TempPlanId: tempPlanId,
      initialEmissionSources,
      targetEmissionSources,
    };
    const result = await ApiService.post<CO2Plan>(url, data);
    return result;
  },
  markTempTimelineActionAsFulfilled: async (request: MarkTempTimelineActionAsFulfilledRequest): Promise<void> => {
    const url = `/api/CO2PlanAnon/markTimelineActionAsFulfilled`;
    await ApiService.post<CO2Plan>(url, request);
  },

  getTotalCO2PlansCount: async (): Promise<number> => {
    const url = "/api/CO2Plan/totalPlansCount";
    const result = await ApiService.get<number>(url, {}); // don't send authorize header to make server-side cache working
    return result;
  },
  mapTempPlanToUnit: (tempCO2PlanId: string, unitId: string): Promise<void> => {
    const url = `/api/CO2Plan/mapTempPlanToUnit`;
    const data = {
      tempCO2PlanId,
      unitId,
    };
    return ApiService.post(url, data);
  },
  registerCO2EconomyItemLinkClick: (
    emissionSourceGroupId: string,
    economyItemId: string,
    cO2PlanId: string
  ): Promise<void> => {
    const url = `/api/CO2Plan/economy-item-click`;
    const data = {
      emissionSourceGroupId,
      economyItemId,
      cO2PlanId,
    };
    return ApiService.post(url, data);
  },
  getTempCO2PlanId: (): string | null => {
    return localStorage["tempCO2PlanId"] ?? null;
  },
  setTempCO2PlanId: (tempCO2PlanId: string): void => {
    localStorage["tempCO2PlanId"] = tempCO2PlanId;
  },
  clearTempCO2PlanId: (): void => {
    localStorage.removeItem("tempCO2PlanId");
  },
  getSponsorSolutions: async (
    emissionSourceId: string,
    subEmissionSourceId?: string
  ): Promise<SearchResult<CO2SponsorSolution>> => {
    let url = `/api/CO2Plan/sponsor-solutions?emissionSourceId=${emissionSourceId}`;
    if (subEmissionSourceId) url += `&subEmissionSourceId=${subEmissionSourceId}`;
    const result = await ApiService.get<SearchResult<CO2SponsorSolution>>(url);
    return result;
  },
};

export default CO2PlanService;
