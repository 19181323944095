export const AdminCO2PlanActionTypes = {
  ADMIN_CO2_OPERATION_FAILED: "ADMIN_CO2_OPERATION_FAILED",
  GET_UNITS_WITH_CO2_PLANS: "GET_UNITS_WITH_CO2_PLANS",
  GET_UNITS_WITH_CO2_PLANS_SUCCEEDED: "GET_UNITS_WITH_CO2_PLANS_SUCCEEDED",
  GET_CO2_SPONSOR_SOLUTIONS: "GET_CO2_SPONSOR_SOLUTIONS",
  GET_CO2_SPONSOR_SOLUTIONS_SUCCEEDED: "GET_CO2_SPONSOR_SOLUTIONS_SUCCEEDED",
  ADD_CO2_SPONSOR_SOLUTION: "ADD_CO2_SPONSOR_SOLUTION",
  ADD_CO2_SPONSOR_SOLUTION_SUCCEEDED: "ADD_CO2_SPONSOR_SOLUTION_SUCCEEDED",
  EDIT_CO2_SPONSOR_SOLUTION: "EDIT_CO2_SPONSOR_SOLUTION",
  EDIT_CO2_SPONSOR_SOLUTION_SUCCEEDED: "EDIT_CO2_SPONSOR_SOLUTION_SUCCEEDED",
  DELETE_CO2_SPONSOR_SOLUTION: "DELETE_CO2_SPONSOR_SOLUTION",
  DELETE_CO2_SPONSOR_SOLUTION_SUCCEEDED: "DELETE_CO2_SPONSOR_SOLUTION_SUCCEEDED",
  GET_CO2_ECONOMY_ITEMS: "GET_CO2_ECONOMY_ITEMS",
  GET_CO2_ECONOMY_ITEMS_SUCCEEDED: "GET_CO2_ECONOMY_ITEMS_SUCCEEDED",
  ADD_CO2_ECONOMY_ITEM: "ADD_CO2_ECONOMY_ITEM",
  ADD_CO2_ECONOMY_ITEM_SUCCEEDED: "ADD_CO2_ECONOMY_ITEM_SUCCEEDED",
  EDIT_CO2_ECONOMY_ITEM: "EDIT_CO2_ECONOMY_ITEM",
  EDIT_CO2_ECONOMY_ITEM_SUCCEEDED: "EDIT_CO2_ECONOMY_ITEM_SUCCEEDED",
  DELETE_CO2_ECONOMY_ITEM: "DELETE_CO2_ECONOMY_ITEM",
  DELETE_CO2_ECONOMY_ITEM_SUCCEEDED: "DELETE_CO2_ECONOMY_ITEM_SUCCEEDED",
  GET_AGE_GROUPED_ITEMS: "GET_AGE_GROUPED_ITEMS",
  GET_AGE_GROUPED_ITEMS_SUCCEEDED: "GET_AGE_GROUPED_ITEMS_SUCCEEDED",
  GET_CO2_PLAN_META_ITEMS: "GET_CO2_PLAN_META_ITEMS",
  GET_CO2_PLAN_META_ITEMS_SUCCEEDED: "GET_CO2_PLAN_META_ITEMS_SUCCEEDED",
  ADD_CO2_PLAN_META_ITEM: "ADD_CO2_PLAN_META_ITEM",
  ADD_CO2_PLAN_META_ITEM_SUCCEEDED: "ADD_CO2_PLAN_META_ITEM_SUCCEEDED",
  EDIT_CO2_PLAN_META_ITEM: "EDIT_CO2_PLAN_META_ITEM",
  EDIT_CO2_PLAN_META_ITEM_SUCCEEDED: "EDIT_CO2_PLAN_META_ITEM_SUCCEEDED",
  DELETE_CO2_PLAN_META_ITEM: "DELETE_CO2_PLAN_META_ITEM",
  DELETE_CO2_PLAN_META_ITEM_SUCCEEDED: "DELETE_CO2_PLAN_META_ITEM_SUCCEEDED",
};
