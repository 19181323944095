import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Container, Grid, Button, Typography, Link, FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ApplicationState } from "../../../reducers/store";
import MainContainer from "../../../components/Layout/MainContainer";
import ModelImage from "../../../assets/house/3dmodel.jpg";
import { toggleShareModel } from "../../../actions/unit.actions";

import { AppThunkDispatch } from "../../../definitions/Action";

type ModelContainerProps = {
  modelUrl: string;
};

export const ModelContainer = (props: ModelContainerProps) => {
  const { modelUrl } = props;
  return (
    <Grid
      container
      sx={{
        height: "60vh",
      }}
    >
      <iframe
        title="3D Model"
        style={{ flex: "1 1 auto" }}
        src={modelUrl}
        allowFullScreen
        allow="xr-spatial-tracking; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
      ></iframe>
    </Grid>
  );
};

const EXAMPLE_URL = "https://my.matterport.com/show/?m=9JGU65jCwQC&help=0brand=0&play=1&qs=1dh=0&gt=0&mls=2&mt=0&wh=0";

const HouseModelPage = (props: TasksPageConnectedProps) => {
  const { unit } = props;
  const [share, setShare] = useState(false);
  const { t } = useTranslation("translation");

  const [exampleShown, setExampleShown] = useState(false);

  const toggleShare = () => {
    if (unit) {
      props.toggleShareModel(unit.Id).then(() => setShare(!share));
    }
  };

  const toggleExample = () => {
    setExampleShown(!exampleShown);
  };

  return (
    <MainContainer title={t("Pages.HouseModel.3DMmodelYourHomeTitle") ?? ""}>
      {unit?.ModelUrl ? (
        <>
          <Grid container item justifyContent="flex-end">
            <FormControlLabel
              control={<Switch size="medium" color="primary" checked={share} onChange={toggleShare} />}
              label="Del"
            />
          </Grid>
          <>
            <ModelContainer modelUrl={unit.ModelUrl} />
          </>
        </>
      ) : (
        <>
          {!exampleShown ? (
            <Container maxWidth="md">
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography variant="body1" paragraph>
                    {t("Pages.HouseModel.No3DScanPressToSeeExample")}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {t("Pages.HouseModel.OrderScanWritingEmail")}
                    <Link component="a">ecr@cdm.dk</Link>
                    {t("Pages.HouseModel.WillCoordinateScan")}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {t("Pages.HouseModel.CostAndTimeEstimateOfScan")}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {t("Pages.HouseModel.GiveAccessToYourScan")}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12} spacing={1} container direction="column" alignItems="center">
                  <Grid item>
                    <img src={ModelImage} alt="modelimage" style={{ width: "100%" }} />
                  </Grid>
                  <Grid item>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={toggleExample}
                      sx={{ minWidth: "225px", marginBottom: "16px" }}
                    >
                      {t("Pages.HouseModel.ExampleOfScanning")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          ) : (
            <>
              <Grid container item justifyContent="flex-end">
                <Button variant="contained" color="secondary" onClick={toggleExample}>
                  {t("General.Buttons.Close")}
                </Button>
              </Grid>
              <ModelContainer modelUrl={EXAMPLE_URL} />
            </>
          )}
        </>
      )}
    </MainContainer>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  unit: state.unit.unit,
  user: state.user.user,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  toggleShareModel: (unitId: string) => dispatch(toggleShareModel(unitId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type TasksPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(HouseModelPage);
