import React from "react";
import { CssBaseline } from "@mui/material";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import store from "./reducers/store";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { PreviousRouteProvider } from "./routes/PreviouseRouteContext";
import Routes from "./routes";
import "./App.css";
import { AlertProvider } from "./components/Base/MyhouseAlert";
import { MAlert } from "./components/Base";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LinkedInTag from "react-linkedin-insight";
// import { IntercomProvider } from "react-use-intercom";
import AppThemeProvider from "./AppThemeProvider";
import { ErrorBoundary } from "./components/ErrorBoundary";
import SystemUpdateAlert from "./components/ServiceWorker/SystemUpdateAlert";
import { IBeforeInstallPromptEvent } from "./helpers/useAddToHomescreenPrompt";

declare global {
  interface Window {
    deferredPrompt: IBeforeInstallPromptEvent | undefined;
  }
}

const App = (): JSX.Element => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS as string, {});
  const history = createBrowserHistory();

  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
  });

  const facebookPixelOptions = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL as string, undefined, facebookPixelOptions);
  ReactPixel.pageView();

  if (process.env.REACT_APP_LINKEDIN_TAG) LinkedInTag.init(process.env.REACT_APP_LINKEDIN_TAG);

  // history.listen(() => {
  //   // let intercom know that url has changed
  //   (window as any).Intercom("update", {
  //     last_request_at: new Date().getTime() / 1000,
  //   });
  // });

  return (
    <Provider store={store}>
      {/* <IntercomProvider
        appId={process.env.REACT_APP_INTERCOM_APP_ID || ""}
        autoBoot={true}
      > */}
      <AppThemeProvider>
        <CssBaseline />
        <AlertProvider>
          <ErrorBoundary>
            <Router history={history}>
              <PreviousRouteProvider>
                <Routes />
              </PreviousRouteProvider>
            </Router>
          </ErrorBoundary>
          <MAlert />
        </AlertProvider>
        <SystemUpdateAlert />
      </AppThemeProvider>
      {/* </IntercomProvider> */}
    </Provider>
  );
};

export default App;
