import { TemplateModel, FormBuilderDTO, LandingMetaModel, WidgetMetaModel } from "../../definitions/Landing";
import { TemplateType } from "../../constants/enums";
import { SearchResult } from "../../definitions/model/SearchResult";
import { Dispatch } from "redux";
import { AppAction, AppThunkDispatch } from "../../definitions/Action";
import { AdminLandingActionTypes } from "../../constants";
import AdminLandingService from "../../services/admin/admin.landing.service";
import { getEditor, operationFailedActionGeneral, useAppDispatch } from "..";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../reducers/store";

const useLandingState = () =>
  useSelector((state: ApplicationState) => ({
    landings: state.adminLanding.landings,
    landingsCount: state.adminLanding.landingsCount,
    templates: state.adminLanding.templates,
    templatesCount: state.adminLanding.templatesCount,
    loading: state.adminLanding.loading,
  }));

const useLandingActions = () => {
  const dispatch = useAppDispatch();
  return {
    createLanding: (title: string, uniqueName: string, templateId?: string) =>
      dispatch(createLanding(title, uniqueName, templateId)),
    getLandings: (searchTerm = "", skip?: number, take?: number) => dispatch(getLandings(searchTerm, skip, take)),
    getLanding: (landingId: string) => dispatch(getLanding(landingId)),
    getTemplates: (searchTerm = "", skip: number, take: number, type?: TemplateType) =>
      dispatch(getTemplates(searchTerm, skip, take, type)),
    deleteLanding: (landingId: string) => dispatch(deleteLanding(landingId)),
    clearLandings: () => dispatch(clearLandings()),
    clearTemplates: () => dispatch(clearTemplates()),
    updateLanding: (landing: LandingMetaModel) => dispatch(updateLanding(landing)),
    cloneLanding: (landingId: string) => dispatch(cloneLanding(landingId)),
    createTerms: () => dispatch(createTerms()),
    getTermsList: (skip?: number, take?: number) => dispatch(getTermsList(skip, take)),
    getTerms: (landingId: string) => dispatch(getTerms(landingId)),
    deleteTerms: (landingId: string) => dispatch(deleteTerms(landingId)),
    updateTerms: (landing: LandingMetaModel) => dispatch(updateTerms(landing)),
    cloneTerms: (landingId: string) => dispatch(cloneTerms(landingId)),
    getEditor: (editorId: string) => dispatch(getEditor(editorId)),
    updateFacebookPicture: (landingId: string, picture: File) => dispatch(updateFacebookPicture(landingId, picture)),
    addLocalization: (landingId: string, languageId: string) => dispatch(addLocalization(landingId, languageId)),
    activateTerms: (landing: LandingMetaModel) => dispatch(activateTerms(landing)),
    addWidgetLocalization: (widgetId: string, languageId: string) =>
      dispatch(addWidgetLocalization(widgetId, languageId)),
  };
};

export const useAdminLandings = (): [ReturnType<typeof useLandingState>, ReturnType<typeof useLandingActions>] => {
  const state = useLandingState();
  const actions = useLandingActions();

  return [state, actions];
};

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, AdminLandingActionTypes.LANDING_OPERATION_FAILED);
};

export const addTemplate =
  (name: string, type: TemplateType) =>
  async (dispatch: AppThunkDispatch): Promise<TemplateModel | null> => {
    try {
      dispatch({
        type: AdminLandingActionTypes.ADD_TEMPLATE,
      });
      const result = await AdminLandingService.addTemplate(name, type);
      dispatch({
        type: AdminLandingActionTypes.ADD_TEMPLATE_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      return null;
    }
  };

export const getTemplates =
  (searchTerm: string, skip: number, take: number, type?: TemplateType) =>
  async (dispatch: AppThunkDispatch): Promise<SearchResult<TemplateModel>> => {
    try {
      dispatch({
        type: AdminLandingActionTypes.GET_TEMPLATES,
      });
      const result = await AdminLandingService.getTemplates(searchTerm, skip, take, type);
      dispatch({
        type: AdminLandingActionTypes.GET_TEMPLATES_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      return { Count: 0, Results: [] };
    }
  };

export const updateTemplate =
  (template: TemplateModel) =>
  async (dispatch: AppThunkDispatch): Promise<TemplateModel | null> => {
    try {
      dispatch({
        type: AdminLandingActionTypes.UPDATE_TEMPLATE,
      });
      const result = await AdminLandingService.updateTemplate(template);

      dispatch({
        type: AdminLandingActionTypes.UPDATE_TEMPLATE_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      return null;
    }
  };

export const deleteTemplate =
  (id: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<boolean> => {
    try {
      dispatch({
        type: AdminLandingActionTypes.REMOVE_TEMPLATE,
      });
      const result = await AdminLandingService.deleteTemplate(id);
      if (result) {
        dispatch({
          type: AdminLandingActionTypes.REMOVE_TEMPLATE_SUCCEEDED,
          payload: id,
        });
      } else {
        dispatch(operationFailedAction("Template was not deleted"));
      }
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      return false;
    }
  };

export const getTemplate =
  (id: string) =>
  async (dispatch: AppThunkDispatch): Promise<TemplateModel | null> => {
    try {
      dispatch({ type: AdminLandingActionTypes.GET_TEMPLATE });
      const result = await AdminLandingService.getTempalte(id);
      dispatch({
        type: AdminLandingActionTypes.GET_TEMPLATE_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      return null;
    }
  };

export const getTemplateEditor =
  (id: string) =>
  async (dispatch: AppThunkDispatch): Promise<FormBuilderDTO | null> => {
    try {
      dispatch({ type: AdminLandingActionTypes.GET_EDITOR });
      const result = await AdminLandingService.getTemplateEditor(id);
      dispatch({
        type: AdminLandingActionTypes.GET_EDITOR_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      return null;
    }
  };

export const clearTemplates = (): AppAction => ({
  type: AdminLandingActionTypes.CLEAR_TEMPLATES,
});

export const getLandings =
  (searchTerm = "", skip?: number, take?: number) =>
  async (dispatch: AppThunkDispatch): Promise<SearchResult<LandingMetaModel>> => {
    try {
      dispatch({ type: AdminLandingActionTypes.GET_LANDINGS });
      const result = await AdminLandingService.getLandings(searchTerm, skip, take);
      dispatch({
        type: AdminLandingActionTypes.GET_LANDINGS_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      return { Results: [], Count: 0 };
    }
  };

export const getLanding =
  (landingId: string) =>
  async (dispatch: AppThunkDispatch): Promise<LandingMetaModel | null> => {
    try {
      dispatch({ type: AdminLandingActionTypes.GET_LANDING });
      const result = await AdminLandingService.getLanding(landingId);
      dispatch({
        type: AdminLandingActionTypes.GET_LANDING_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      return null;
    }
  };

export const createLanding =
  (title: string, uniqueName: string, templateId?: string) =>
  async (dispatch: AppThunkDispatch): Promise<LandingMetaModel | null> => {
    try {
      dispatch({ type: AdminLandingActionTypes.ADD_LANDING });
      const result = await AdminLandingService.createLanding(title, uniqueName, templateId);
      dispatch({
        type: AdminLandingActionTypes.ADD_LANDING_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      return null;
    }
  };

export const updateLanding =
  (landing: LandingMetaModel) =>
  async (dispatch: AppThunkDispatch): Promise<LandingMetaModel | null> => {
    try {
      dispatch({ type: AdminLandingActionTypes.UPDATE_LANDING });
      const result = await AdminLandingService.updateLanding(landing);
      dispatch({
        type: AdminLandingActionTypes.UPDATE_LANDING_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const updateFacebookPicture =
  (landingId: string, picture: File) =>
  async (dispatch: AppThunkDispatch): Promise<string | null | undefined> => {
    try {
      dispatch({ type: AdminLandingActionTypes.UPDATE_FACEBOOK_PICTURE });
      const result = await AdminLandingService.updateFacebookPicture(landingId, picture);
      dispatch({
        type: AdminLandingActionTypes.UPDATE_FACEBOOK_PICTURE_SUCCEEDED,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

export const deleteLanding =
  (landingId: string) =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    try {
      dispatch({ type: AdminLandingActionTypes.REMOVE_LANDING });
      if (await AdminLandingService.deleteLanding(landingId)) {
        dispatch({
          type: AdminLandingActionTypes.REMOVE_LANDING_SUCCEEDED,
          payload: landingId,
        });
      } else {
        dispatch(operationFailedAction("landing was not deleted"));
      }
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const cloneLanding =
  (landingId: string) =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    try {
      dispatch({ type: AdminLandingActionTypes.CLONE_LANDING });
      const result = await AdminLandingService.cloneLanding(landingId);
      dispatch({
        type: AdminLandingActionTypes.CLONE_LANDING_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const clearLandings = (): { type: string } => ({
  type: AdminLandingActionTypes.CLEAR_LANDINGS,
});

export const deleteTerms =
  (id: string) =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    try {
      dispatch({ type: AdminLandingActionTypes.REMOVE_LANDING });
      if (await AdminLandingService.deleteTerms(id)) {
        dispatch({
          type: AdminLandingActionTypes.REMOVE_LANDING_SUCCEEDED,
          payload: id,
        });
      } else {
        dispatch(operationFailedAction("landing was not deleted"));
      }
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const cloneTerms =
  (id: string) =>
  async (dispatch: AppThunkDispatch): Promise<void> => {
    try {
      dispatch({ type: AdminLandingActionTypes.CLONE_LANDING });
      const result = await AdminLandingService.cloneTerms(id);
      dispatch({
        type: AdminLandingActionTypes.CLONE_LANDING_SUCCEEDED,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getTermsList =
  (skip?: number, take?: number) =>
  async (dispatch: AppThunkDispatch): Promise<SearchResult<LandingMetaModel>> => {
    try {
      dispatch({ type: AdminLandingActionTypes.GET_LANDINGS });
      const result = await AdminLandingService.getTermsList(skip, take);
      dispatch({
        type: AdminLandingActionTypes.GET_LANDINGS_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      return { Results: [], Count: 0 };
    }
  };

export const getTerms =
  (id: string) =>
  async (dispatch: AppThunkDispatch): Promise<LandingMetaModel | null> => {
    try {
      dispatch({ type: AdminLandingActionTypes.GET_LANDING });
      const result = await AdminLandingService.getTerms(id);
      dispatch({
        type: AdminLandingActionTypes.GET_LANDING_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      return null;
    }
  };

export const createTerms =
  () =>
  async (dispatch: AppThunkDispatch): Promise<LandingMetaModel | null> => {
    try {
      dispatch({ type: AdminLandingActionTypes.ADD_LANDING });
      const result = await AdminLandingService.createTerms();
      dispatch({
        type: AdminLandingActionTypes.ADD_LANDING_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      return null;
    }
  };

export const updateTerms =
  (landing: LandingMetaModel) =>
  async (dispatch: AppThunkDispatch): Promise<LandingMetaModel | null> => {
    try {
      dispatch({ type: AdminLandingActionTypes.UPDATE_LANDING });
      const result = await AdminLandingService.updateTerms(landing);
      dispatch({
        type: AdminLandingActionTypes.UPDATE_LANDING_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const addLocalization =
  (landingId: string, languageId: string) =>
  async (dispatch: AppThunkDispatch): Promise<LandingMetaModel | null> => {
    try {
      dispatch({ type: AdminLandingActionTypes.UPDATE_LANDING });
      const result = await AdminLandingService.addLocalization(landingId, languageId);
      dispatch({
        type: AdminLandingActionTypes.UPDATE_LANDING_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const addWidgetLocalization =
  (widgetId: string, languageId: string) =>
  async (dispatch: AppThunkDispatch): Promise<WidgetMetaModel | null> => {
    try {
      dispatch({ type: AdminLandingActionTypes.UPDATE_WIDGET });
      const result = await AdminLandingService.addWidgetLocalization(widgetId, languageId);
      dispatch({
        type: AdminLandingActionTypes.UPDATE_WIDGET_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const activateTerms =
  (terms: LandingMetaModel) =>
  async (dispatch: AppThunkDispatch): Promise<LandingMetaModel | null> => {
    try {
      dispatch({ type: AdminLandingActionTypes.UPDATE_LANDING });
      const result = await AdminLandingService.activateTerms(terms);
      dispatch({
        type: AdminLandingActionTypes.UPDATE_LANDING_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };
