import ApiService from "./api.service";
import {
  ServiceBookReport,
  ServiceBookOfferRequestContract,
  ServiceBookSearchResult,
  CraftsmanTaskList,
  ServiceBookReportPreview,
  CraftsmanTaskStatus,
  CraftsmanTaskListStatus,
  CustomerReportState,
} from "../definitions/model/ServiceBook";
import { RegisterLeadContract } from "../definitions/LiteCapaign";

export const ServiceBookService = {
  getReports: async (houseId: string) => {
    return ApiService.get<ServiceBookReportPreview[]>(
      "/api/Servicebook/getReports/" + houseId
    );
  },
  getReport: async (reportId: string) => {
    return ApiService.get<ServiceBookReport>(
      "/api/Servicebook/getReport/" + reportId
    );
  },
  sendOfferRequest: async (data: ServiceBookOfferRequestContract) => {
    return ApiService.post("/api/Servicebook/sendOfferRequest", data);
  },

  acceptOffer: async (offerId: string) => {
    return ApiService.post(
      "/api/Servicebook/acceptOffer?offerId=" + offerId,
      {}
    );
  },

  rejectOffer: async (offerId: string, unitId: string) => {
    const url = `$/api/Servicebook/rejectOffer?offerId=${offerId}&unitId=${unitId}`;
    return ApiService.post(url, {});
  },

  getTasks: async (unitId: string, reportId?: string) => {
    let url = "/api/workers/getTasks/" + unitId;
    if (reportId) {
      url += `?reportId=${reportId}`;
    }

    return ApiService.get<ServiceBookSearchResult<CraftsmanTaskList>>(url);
  },

  setTaskStatus: async (
    taskId: string,
    unitId: string,
    status: CraftsmanTaskStatus
  ) => {
    return ApiService.post("/api/workers/setTaskStatus/", {
      taskId,
      status,
      unitId,
    });
  },
  setTaskListStatus: async (
    tasklistId: string,
    status: CraftsmanTaskListStatus
  ) => {
    return ApiService.post("/api/workers/setTaskListStatus", {
      tasklistId,
      status,
    });
  },
  sendReportRequest: async (data: RegisterLeadContract) => {
    return ApiService.post("/api/servicebook/sendReportRequest", data);
  },
  getCustomerReportState: async (reportId: string) => {
    return ApiService.get<CustomerReportState>(
      "/api/servicebook/customerState/" + reportId
    );
  },
  setCustomerReportState: async (state: CustomerReportState) => {
    return ApiService.post("/api/servicebook/customerState/", state);
  },
  getLatestReport: async (unitId: string) => {
    return ApiService.get<{ ReportId: string, Consent: boolean }>("/api/servicebook/latest/" + unitId);
  },
  requestConsent: async (reportId: string) => {
    return ApiService.post("/api/servicebook/requestConsent/" + reportId, null);
  },
  manageConsent: async (reportId: string, managerId: string, given: boolean) => {
    return ApiService.post(`/api/servicebook/manageConsent/${reportId}?managerId=${managerId}&given=${given}`, null);
  },
};
