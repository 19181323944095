import React from "react";
import { useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { UserConstants } from "../constants";
import { useUser } from "../actions";

interface IProps {
  exact?: boolean;
  path?: string;
  component: any;
  validate?: any;
  redirectPath?: string;
}

const RouteWrapper = ({
  component: Component,
  validate,
  redirectPath,
  ...rest
}: IProps) => {
  const dispatch = useDispatch();
  const [state] = useUser();

  if (validate) {
    if (!validate() && state.authDestination !== window.location.pathname) {
      dispatch({
        type: UserConstants.SET_AUTH_DESTINATION,
        payload: window.location.pathname,
      });
    }
  }

  // if (state.authDestination && state.user) {
  //   dispatch({
  //     type: UserConstants.SET_AUTH_DESTINATION,
  //     payload: "",
  //   });
  // }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!validate || (validate && validate()))
          return <Component {...props} />;
        else return <Redirect to={redirectPath ? redirectPath : "/"} />;
      }}
    />
  );
};

export default RouteWrapper;
