import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider, connect, ConnectedProps } from "react-redux";
import { Grid, ThemeProvider, Theme, StyledEngineProvider, Typography, styled } from "@mui/material";

import store, { ApplicationState } from "../../../../reducers/store";
import { getOptionalTheme } from "../../../../constants/theme";
import { isReactComponent } from ".";
import { GrapesEditor, GrapesTypes, LoadComponentOptions } from "../../defenitions";
import { AppThunkDispatch } from "../../../../definitions/Action";
import { convertToCurency } from "../../../../services/converter.service";
import { getUsersCount } from "../../../../actions/landing.actions";
import { useTranslation } from "react-i18next";

const UsersCount = styled("span")(() => ({
  fontWeight: "bold",
  display: "block",
  height: 21,
}));

const mapStateToProps = (state: ApplicationState) => ({
  usersCount: state.landing.usersCount,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getUsersCount: () => dispatch(getUsersCount()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type CircleLabelConnectedProps = ConnectedProps<typeof connector>;

export const UsersCounter = connector(({ usersCount, getUsersCount }: CircleLabelConnectedProps) => {
  const { t } = useTranslation("translation");

  useEffect(() => {
    if (!usersCount) {
      getUsersCount();
    }
  }, []);

  return (
    <Grid
      container
      alignItems="center"
      sx={{
        width: "170px",
        height: "170px",
        textAlign: "center",
        padding: "30px",
        boxSizing: "border-box",
      }}
    >
      <Typography variant="body1" style={{ color: "inherit", lineHeight: "1.5" }}>
        <UsersCount>{usersCount ? convertToCurency(usersCount, ".") : null}</UsersCount>
        {t("Base.Grapes.HomeOwnersUsers", { hostname: window.location.hostname })}
      </Typography>
    </Grid>
  );
});

export default function (editor: GrapesEditor, options: LoadComponentOptions, landingTheme?: string): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const cssComposer = editor.CssComposer;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "UsersCounter";

  blockManager.add(GrapesTypes.UsersCounterComponent, {
    label: "Users counter",
    category: options.categoryLabel,
    attributes: { class: "fa fa-users" },
    content: `<div data-gjs-type="${GrapesTypes.UsersCounterComponent}"></div>`,
  });

  domComponents.addType(GrapesTypes.UsersCounterComponent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute && el.getAttribute("data-gjs-type") === GrapesTypes.UsersCounterComponent) ||
        (el.attributes && (el.attributes as any)["data-gjs-type"] === GrapesTypes.UsersCounterComponent) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.UsersCounterComponent,
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        droppable: false,
      },
    },
    view: view.extend({
      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        comps.reset();
        const compString = `<${compName}></${compName}>`;
        comps.add(compString);

        this.model.addClass(["users-counter", "styled-counter"]);
        cssComposer.setRule(".users-counter", {
          color: "white",
          background: "#3a0d09",
          "border-radius": "50%",
          position: "absolute",
          "line-height": "1.5em",
        });
        const root = ReactDOM.createRoot(el);

        root.render(
          <>
            <StyledEngineProvider>
              <ThemeProvider theme={getOptionalTheme(landingTheme)}>
                <Provider store={store}>
                  <UsersCounter />
                </Provider>
              </ThemeProvider>
            </StyledEngineProvider>
          </>
        );
      },
    }),
  });
}
