import { Dispatch, AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AppAction } from "../../../definitions/Action";
import CO2PlanService from "./co2plan.service";
import { CO2PlanActionTypes } from "./co2plan.actiontypes";
import { ApplicationState } from "../../../reducers/store";
import {
  CO2Plan,
  CO2PlanPerson,
  MergePersons,
  CO2EmissionSurvey,
  CO2EmissionSource,
  CO2EmissionSourceGroup,
  CO2PersonEmissionSourceGroup,
  CO2Category,
  SaveEmissionSource,
  CustomQuestions,
  CO2PlanCustomQuestionsTarget,
  CO2SponsorSolution,
  CO2PropertyDescription,
} from "./CO2Plan";
import { CO2StoreState } from "./co2.reducer";
import { loadRelatedArticles } from "../co2plan.actions.additions";
import { operationFailedActionGeneral } from "../../../actions";
import i18n from "i18next";
import { SearchResult } from "../../../definitions/model/SearchResult";

const operationFailedAction = (payload: unknown): AppAction => {
  return operationFailedActionGeneral(payload, CO2PlanActionTypes.CO2_PLAN_OPERATION_FAILED);
};

const useCO2PlanActions = () => {
  const dispatch = useDispatch<ThunkDispatch<ApplicationState, any, AnyAction>>();
  return {
    loadPlan: (planId: string, unitId?: string, co2?: CO2StoreState) => dispatch(loadPlan(planId, unitId, co2)),
    registerPlan: (
      unitId: string,
      familyMembers: CO2PlanPerson[],
      isSinglePersonPlan: boolean,
      ignoreMembersList: string[],
      isMultiSelect: boolean
    ) => dispatch(registerPlan(unitId, familyMembers, isSinglePersonPlan, ignoreMembersList, isMultiSelect)),
    updatePlanPersons: (
      personNamesToAdd: CO2PlanPerson[],
      personIdsToDelete: string[],
      personsToRename: CO2PlanPerson[],
      isSinglePersonPlan: boolean,
      planPersonsToIgnore: string[],
      personsToMerge: MergePersons[],
      isMultiSelect: boolean,
      unitId?: string
    ) =>
      dispatch(
        updatePlanPersons(
          personNamesToAdd,
          personIdsToDelete,
          personsToRename,
          isSinglePersonPlan,
          planPersonsToIgnore,
          personsToMerge,
          isMultiSelect,
          unitId,
          null
        )
      ),
    saveEmissionSurvey: (
      initialSurvey: CO2EmissionSurvey | null,
      targetSurvey: CO2EmissionSurvey | null,
      customQuestions: CustomQuestions[] | null,
      planId: string,
      unitId?: string,
      isMultiSelect?: boolean
    ) => dispatch(saveEmissionSurvey(initialSurvey, targetSurvey, customQuestions, null, planId, unitId, isMultiSelect)),
    trackSponsorSolutionsOpen: (emissionSourceGroupShortName: string) =>
      dispatch(trackSponsorSolutionsOpen(emissionSourceGroupShortName)),
    selectCustomAnswer: (
      sourceId: string,
      groupId: string,
      questions: CustomQuestions[],
      personId?: string,
      metaValue?: string
    ) => dispatch(selectCustomAnswer(sourceId, groupId, questions, personId, metaValue)),
    getSponsorSolutions: (emissionSourceId: string, subEmissionSourceId?: string) =>
      dispatch(getSponsorSolutions(emissionSourceId, subEmissionSourceId)),
    getTempCO2PlanId: (currentStateValue?: string | null) => dispatch(getTempCO2PlanId(currentStateValue)),
    registerTempPlan: (
      propertyDescription: CO2PropertyDescription,
      familyMembers: CO2PlanPerson[],
      isSinglePersonPlan: boolean
    ) => dispatch(registerTempPlan(propertyDescription, familyMembers, isSinglePersonPlan)),
    mapTempPlanToUnit: (tempCO2PlanId: string, unitId: string) => dispatch(mapTempPlanToUnit(tempCO2PlanId, unitId)),
    getTotalCO2PlansCount: () => dispatch(getTotalCO2PlansCount()),
  };
};

export const useCO2 = (): [CO2StoreState, ReturnType<typeof useCO2PlanActions>] => {
  const state = useSelector((state: ApplicationState) => state.co2);
  const actions = useCO2PlanActions();

  return [state, actions];
};

export const loadPlan = (planId: string, unitId?: string, co2?: CO2StoreState) => async (dispatch: Dispatch<AppAction>) => {
  try {
    if (co2?.loading) return;

    dispatch({
      type: CO2PlanActionTypes.GET_CO2_PLAN,
    });

    const tempPlanId = co2?.tempCO2PlanId;
    let result: CO2Plan | null = null;
    if (tempPlanId) {
      try {
        result = await CO2PlanService.getTempPlan(tempPlanId);
      } catch {
        // not able to get co2 plan, remove it
        CO2PlanService.clearTempCO2PlanId();
        dispatch({
          type: CO2PlanActionTypes.SET_TEMP_CO2_PLANID,
          payload: null,
        });
      }
    } else if (unitId) {
      result = await CO2PlanService.getPlan(planId, unitId);
    }

    if (!result) {
      dispatch(operationFailedAction(""));
    } else {
      const fulfilledActions = result.ActionsTimeLine.map((record) => {
        record.WasFulfilledWhenLoad = record.IsFulfilled;
        return record;
      });
      result.ActionsTimeLine = fulfilledActions;

      result.TargetSurvey.Categories.forEach((category) => {
        category.EmissionSourceGroups.forEach((group) => {
          group.PersonEmissionSources.forEach((source) => {
            source.EmissionSources.forEach((emissionSource) => {
              emissionSource.Order = emissionSource.Code;
            });
          });
        });
      });
      dispatch({
        type: CO2PlanActionTypes.SET_CO2_PLAN,
        payload: result,
      });

      await loadRelatedArticles(result, dispatch);
    }
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const integrateCustomQuestionsIntoGroups = (
  question: CustomQuestions[],
  groups: CO2EmissionSourceGroup[],
  target: CO2PlanCustomQuestionsTarget
): CO2EmissionSourceGroup[] => {
  //CO2PlanCustomQuestionsTarget.InitialSurvey value is 0, but it could be simplified by server serialization
  question = question.filter(
    (q) => q.Target === target || (q.Target === undefined && target === CO2PlanCustomQuestionsTarget.InitialSurvey)
  );
  if (question.length === 0) return groups;

  let index = groups.findIndex((g) => g.Id === question[0].PlaceAfterSourceId);
  index = index === -1 ? 0 : index + 1;
  const customCategoryId = "C4F20596-724D-43F6-A709-E821DBC19999";
  let customGroup = groups.find((c) => c.Id === customCategoryId);
  if (!customGroup && question[0].Questions[0].EmissionSourceGroups.length) {
    groups = [
      ...groups.slice(0, index),
      {
        ...question[0].Questions[0].EmissionSourceGroups[0],
        Id: customCategoryId,
        CategoryId: customCategoryId,
        Code: 5,
        HouseHoldEmissionSources: [],
        PersonEmissionSources: [],
      },
      ...groups.slice(index),
    ];

    customGroup = groups.find((c) => c.Id === customCategoryId);
  }

  question.forEach((q) => {
    q.Questions.forEach((gc) => {
      customGroup?.HouseHoldEmissionSources.push(...gc.EmissionSourceGroups.flatMap((g) => g.HouseHoldEmissionSources));
      customGroup?.PersonEmissionSources.push(...gc.EmissionSourceGroups.flatMap((g) => g.PersonEmissionSources));
    });
  });

  return groups;
};

export const registerPlan =
  (
    unitId: string,
    familyMembers: CO2PlanPerson[],
    isSinglePersonPlan: boolean,
    IgnoreMembersList: string[],
    isMultiSelect: boolean
  ) =>
  async (dispatch: Dispatch<AppAction>): Promise<void> => {
    try {
      const result = await CO2PlanService.registerPlan(
        unitId,
        familyMembers,
        isSinglePersonPlan,
        IgnoreMembersList,
        isMultiSelect
      );

      result.TargetSurvey.Categories.forEach((category) => {
        category.EmissionSourceGroups.forEach((group) => {
          group.PersonEmissionSources.forEach((source) => {
            source.EmissionSources.forEach((emissionSource) => {
              emissionSource.Order = emissionSource.Code;
            });
          });
        });
      });
      dispatch({
        type: CO2PlanActionTypes.REGISTER_CO2_PLAN,
        payload: result,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const registerTempPlan =
  (propertyDescription: CO2PropertyDescription, familyMembers: CO2PlanPerson[], isSinglePersonPlan: boolean) =>
  async (dispatch: Dispatch<AppAction>): Promise<void> => {
    try {
      const result = await CO2PlanService.registerTempPlan(propertyDescription, familyMembers, isSinglePersonPlan);
      CO2PlanService.setTempCO2PlanId(result.TempCO2PlanId);
      dispatch({
        type: CO2PlanActionTypes.REGISTER_CO2_PLAN,
        payload: result.Plan,
      });
      dispatch({
        type: CO2PlanActionTypes.SET_TEMP_CO2_PLANID,
        payload: result.TempCO2PlanId,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const updatePlanPersons =
  (
    personNamesToAdd: CO2PlanPerson[],
    personIdsToDelete: string[],
    personsToRename: CO2PlanPerson[],
    isSinglePersonPlan: boolean,
    planPersonToIgnore: string[],
    personsToMerge: MergePersons[],
    isMultiSelect: boolean,
    unitId?: string,
    tempPlanId?: string | null
  ) =>
  async (dispatch: Dispatch<AppAction>): Promise<void> => {
    try {
      let result: CO2Plan | null = null;
      if (unitId) {
        const request = {
          UnitId: unitId,
          PersonNamesToAdd: personNamesToAdd,
          PersonIdsToDelete: personIdsToDelete,
          PersonsToRename: personsToRename,
          IsSinglePersonPlan: isSinglePersonPlan,
          IgnoreMembersList: planPersonToIgnore,
          PersonsToMerge: personsToMerge,
          IsMultiSelect: isMultiSelect,
        };
        result = await CO2PlanService.updatePersons(request);
      } else {
        if (tempPlanId) {
          const request = {
            TempPlanId: tempPlanId,
            PersonNamesToAdd: personNamesToAdd,
            PersonIdsToDelete: personIdsToDelete,
            PersonsToRename: personsToRename,
            IsSinglePersonPlan: isSinglePersonPlan,
          };
          result = await CO2PlanService.updateTempPersons(request);
        }
      }
      if (result) {
        dispatch({
          type: CO2PlanActionTypes.SET_CO2_PLAN,
          payload: result,
        });

        dispatch({
          type: CO2PlanActionTypes.SET_MULTISELECT,
          payload: result.IsMultiSelect,
        });

        await loadRelatedArticles(result, dispatch);
      }
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const addValueSource =
  (
    emissionSourceId: string,
    personId: string | null,
    value: number | null,
    combinedValue: number | null,
    currentSurvey: CO2EmissionSurvey,
    isTargetSurvey: boolean,
    isUpdateSelected: boolean,
    isMultiSelect?: boolean
  ) =>
  (dispatch: Dispatch<AppAction>): void => {
    const updateLogic = (source: CO2EmissionSource) => {
      source.Value = value;
      source.CombinedValue = isMultiSelect ? combinedValue : source.CombinedMaxAmount;
      if (source.CombinedMaxAmount != null && source.ChildSources?.length) {
        source.ChildSources.forEach((c) => {
          c.Value = value;
          c.CombinedValue = source.CombinedValue;
        });
      }
      if (value !== null && source.ThingEmission) {
        source.CO2Emission = (source.CO2EmissionPerThing ?? 0) * value;
        if (source.CombinedMaxAmount != null && source.CombinedValue != null) {
          source.CO2Emission = (source.CO2Emission * source.CombinedValue) / source.CombinedMaxAmount;
        }
        source.CO2Emission = Math.round(source.CO2Emission);
      }
    };
    selectSource(
      emissionSourceId,
      personId,
      currentSurvey,
      isTargetSurvey,
      isUpdateSelected,
      null,
      dispatch,
      updateLogic,
      undefined,
      isMultiSelect
    );
  };

export const addOptionSource =
  (
    emissionSourceId: string,
    personId: string | null,
    optionId: string | null,
    currentSurvey: CO2EmissionSurvey,
    isTargetSurvey: boolean
  ) =>
  (dispatch: Dispatch<AppAction>): void => {
    const updateLogic = (source: CO2EmissionSource) => {
      if (optionId) {
        const option = source.Options!.find((opt) => opt.Id === optionId);
        source.SelectedOptionId = optionId;
        source.CO2Emission = option!.CO2Emission;
      }
    };
    selectSource(emissionSourceId, personId, currentSurvey, isTargetSurvey, true, null, dispatch, updateLogic);
  };

export const selectHighLevelSource =
  (
    emissionSourceId: string,
    personId: string | null,
    deselectId: string | null,
    currentSurvey: CO2EmissionSurvey,
    isTargetSurvey: boolean,
    order?: number,
    isMultiSelect?: boolean
  ) =>
  (dispatch: Dispatch<AppAction>): void => {
    selectSource(
      emissionSourceId,
      personId,
      currentSurvey,
      isTargetSurvey,
      true,
      deselectId,
      dispatch,
      (source: CO2EmissionSource) => {
        if (source.SelectedOptionId && source.Options) {
          const option = source.Options.find((opt) => opt.Id === source.SelectedOptionId);
          if (option) {
            source.CO2Emission = option.CO2Emission;
          }
        }
      },
      order,
      isMultiSelect
    );
  };

export const setDueYear =
  (groupId: string, personId: string | null, dueYear: number, currentSurvey: CO2EmissionSurvey) =>
  (dispatch: Dispatch<AppAction>): void => {
    try {
      const updatedSurvey = { ...currentSurvey };

      const groups: CO2EmissionSourceGroup[] = updatedSurvey.Categories.map((cat) => cat.EmissionSourceGroups).flat();

      const group: CO2EmissionSourceGroup = groups.filter((gr) => gr.Id === groupId)[0];
      if (group.IsPerPerson) {
        const person: CO2PersonEmissionSourceGroup = group.PersonEmissionSources.filter(
          (per) => per.PersonId === personId
        )[0];
        person.DueYear = dueYear;
      } else {
        group.DueYear = dueYear;
      }

      //dispatch update action
      dispatch({
        type: CO2PlanActionTypes.SET_CO2_SURVEY,
        payload: { Survey: updatedSurvey, IsTargetSurvey: true },
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const setEconomyCost =
  (
    emissionSourceId: string,
    personId: string | null,
    economyCost: number | null,
    isCustom: boolean,
    currentSurvey: CO2EmissionSurvey,
    isTargetSurvey: boolean
  ) =>
  (dispatch: Dispatch<AppAction>): void => {
    try {
      const updatedSurvey = { ...currentSurvey };

      const groups: CO2EmissionSourceGroup[] = updatedSurvey.Categories.map((cat) => cat.EmissionSourceGroups).flat();

      const group: CO2EmissionSourceGroup = groups.filter((gr) => gr.Id === emissionSourceId)[0];
      if (group.IsPerPerson) {
        //  const person: CO2PersonEmissionSourceGroup =
        //   group.PersonEmissionSources.filter(
        //     (per) => per.PersonId === personId
        //   )[0];
        // person.DueYear = dueYear;
        if (personId) {
          const person = group.PersonEmissionSources.filter((p) => p.PersonId === personId)[0];
          person.CustomEconomyCost = isCustom ? economyCost : null;
          person.EconomyCost = economyCost;
        }

        group.CustomEconomyCost = isCustom ? economyCost : null;
        group.EconomyCost = economyCost;
      } else {
        group.CustomEconomyCost = isCustom ? economyCost : null;
        group.EconomyCost = economyCost;
      }

      //dispatch update action
      dispatch({
        type: CO2PlanActionTypes.SET_CO2_SURVEY,
        payload: { Survey: updatedSurvey, IsTargetSurvey: isTargetSurvey },
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const saveEmissionSurvey =
  (
    initialSurvey: CO2EmissionSurvey | null,
    targetSurvey: CO2EmissionSurvey | null,
    customQuestions: CustomQuestions[] | null,
    tempPlanId?: string | null,
    planId?: string,
    unitId?: string,
    isMultiSelect?: boolean,
    planPersons?: CO2PlanPerson[]
  ) =>
  async (dispatch: Dispatch<AppAction>): Promise<void> => {
    try {
      let initialGroups: CO2EmissionSourceGroup[] = [];
      let targetGroups: CO2EmissionSourceGroup[] = [];

      if (initialSurvey) initialGroups = initialSurvey.Categories.map((cat) => cat.EmissionSourceGroups).flat();
      if (targetSurvey) targetGroups = targetSurvey.Categories.map((cat) => cat.EmissionSourceGroups).flat();

      const initialMissingGroups =
        !isMultiSelect && initialGroups.length ? getMissingGroups(initialGroups, planPersons) : [];
      const targetMissingGroups = targetGroups.length ? getMissingGroups(targetGroups) : [];

      if (initialMissingGroups.length > 0 && !customQuestions?.length) {
        dispatch({
          type: CO2PlanActionTypes.SAVE_CO2_SURVEY_ERROR,
          payload: {
            Message: i18n.t("ClimatePlan.Errors.SomeSubjectsAreMissing"),
            IsTargetSurvey: false,
            MissingGroups: initialMissingGroups,
          },
        });
        return;
      }

      if (targetMissingGroups.length > 0 && !customQuestions?.length) {
        dispatch({
          type: CO2PlanActionTypes.SAVE_CO2_SURVEY_ERROR,
          payload: {
            Message: i18n.t("ClimatePlan.Errors.SomeSubjectsAreMissing"),
            IsTargetSurvey: true,
            MissingGroups: targetMissingGroups,
          },
        });
        return;
      }

      const initialSourcesToSave = initialGroups.length ? getSourcesToSave(initialGroups, isMultiSelect) : null;
      const targetSourcesToSave = targetGroups.length ? getSourcesToSave(targetGroups, isMultiSelect) : null;
      const customQuestionsToSave = customQuestions ? getGroupsFromQuestions(customQuestions) : null;

      let updatedPlan: CO2Plan | null = null;

      if (unitId && planId) {
        updatedPlan = await CO2PlanService.saveEmissionSurvey(
          planId,
          unitId,
          initialSourcesToSave,
          targetSourcesToSave,
          customQuestionsToSave
        );
      } else if (tempPlanId) {
        updatedPlan = await CO2PlanService.saveTempEmissionSurvey(
          tempPlanId,
          initialSourcesToSave,
          targetSourcesToSave
        );
      }

      if (initialSurvey && !initialSurvey.IsFilledIn && !customQuestions?.length) {
        dispatch({
          type: CO2PlanActionTypes.TRACK_CO2_SURVEY_FILLED_IN,
          payload: {
            isTargetSurvey: false,
          },
        });
      }

      if (targetSurvey && !targetSurvey.IsFilledIn && !customQuestions?.length) {
        dispatch({
          type: CO2PlanActionTypes.TRACK_CO2_SURVEY_FILLED_IN,
          payload: {
            isTargetSurvey: true,
          },
        });
      }

      if (updatedPlan) {
        //Get back existing survey object to avoid blinking in UI
        if (targetSurvey) {
          targetSurvey.IsFilledIn = true;
          updatedPlan.TargetSurvey = targetSurvey;
        }
        if (initialSurvey) {
          initialSurvey.IsFilledIn = getMissingGroups(initialGroups, planPersons).length === 0;
          updatedPlan.InitialSurvey = initialSurvey;
        }

        dispatch({
          type: CO2PlanActionTypes.SET_CO2_PLAN,
          payload: updatedPlan,
        });

        await loadRelatedArticles(updatedPlan, dispatch);
      }
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const uncheckZeroSurvey = (survey: CO2EmissionSurvey) => {
  const updatedSurvey = { ...survey };
  updatedSurvey.Categories.forEach((category) => {
    category.EmissionSourceGroups.forEach((group) => {
      group.PersonEmissionSources.forEach((sources) => {
        sources.EmissionSources.forEach((source) => {
          if (source.CombinedValue === 0) {
            source.IsSelected = false;
          }
        });
      });
    });
  });

  return updatedSurvey;
};

export const setActiveGroupIndex =
  (newIndex: number, currentSurvey: CO2EmissionSurvey, isTargetSurvey: boolean) =>
  (dispatch: Dispatch<AppAction>): void => {
    try {
      if (newIndex >= 0) {
        const updatedSurvey = {
          ...(isTargetSurvey ? currentSurvey : uncheckZeroSurvey(currentSurvey)),
          ActiveGroupIndex: newIndex,
        };

        dispatch({
          type: CO2PlanActionTypes.SET_CO2_SURVEY,
          payload: { Survey: updatedSurvey, IsTargetSurvey: isTargetSurvey },
        });
      }
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const markTimelineActionAsFulfilled =
  (
    emissionSourceGroupId: string,
    emissionSourceId: string,
    personId: string | null,
    isFulfilled: boolean,
    unitId?: string,
    tempCO2PlanId?: string
  ) =>
  async (dispatch: Dispatch<AppAction>): Promise<void> => {
    try {
      if (unitId) {
        const request = {
          UnitId: unitId,
          EmissionSourceGroupId: emissionSourceGroupId,
          EmissionSourceId: emissionSourceId,
          PersonId: personId,
          IsFulfilled: isFulfilled,
        };

        await CO2PlanService.markTimelineActionAsFulfilled(request);
        dispatch({
          type: CO2PlanActionTypes.MARK_CO2_TIMELINE_FULFILLED,
          payload: request,
        });
      } else {
        const tempPlanId = tempCO2PlanId;
        if (tempPlanId) {
          const request = {
            TempPlanId: tempPlanId,
            EmissionSourceGroupId: emissionSourceGroupId,
            PersonId: personId,
            IsFulfilled: isFulfilled,
          };

          await CO2PlanService.markTempTimelineActionAsFulfilled(request);
          dispatch({
            type: CO2PlanActionTypes.MARK_CO2_TIMELINE_FULFILLED,
            payload: request,
          });
        }
      }
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getTotalCO2PlansCount =
  () =>
  async (dispatch: Dispatch<AppAction>): Promise<void> => {
    try {
      dispatch({ type: CO2PlanActionTypes.GET_TOTAL_CO2_PLANS_COUNT });
      const count = await CO2PlanService.getTotalCO2PlansCount();
      dispatch({
        type: CO2PlanActionTypes.GET_TOTAL_CO2_PLANS_COUNT_SUCCEEDED,
        payload: count,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const trackSponsorSolutionsOpen =
  (emissionSourceGroupShortName: string) =>
  (dispatch: Dispatch<AppAction>): void => {
    dispatch({
      type: CO2PlanActionTypes.TRACK_SPONSOR_SOLUTIONS_OPEN,
      payload: emissionSourceGroupShortName,
    });
  };

export const selectSponsorSolution =
  (
    emissionSourceGroupId: string,
    personId: string | null,
    sponsorSolutionId: string,
    currentSurvey: CO2EmissionSurvey,
    isTargetSurvey: boolean
  ) =>
  (dispatch: Dispatch<AppAction>): void => {
    try {
      const updatedSurvey = { ...currentSurvey };

      const groups: CO2EmissionSourceGroup[] = updatedSurvey.Categories.map((cat) => cat.EmissionSourceGroups).flat();
      const group = groups.filter((gr) => gr.Id === emissionSourceGroupId)[0];
      const category = updatedSurvey.Categories.filter((cat) => cat.Id === group.CategoryId)[0];
      const sponsorSolution = group.SponsorSolutions.filter((sol) => sol.Id === sponsorSolutionId)[0];
      const existingGroupEmission = group.CO2Emission;

      if (group.IsPerPerson) {
        const person = group.PersonEmissionSources.filter((pers) => pers.PersonId === personId)[0];
        person.SelectedSourceId = null;
        person.CustomCalculationId = sponsorSolutionId;
        //update emission on parent levels
        if (group.IsPerPerson) {
          const oldPersonEmission = person.CO2Emission;
          if (isTargetSurvey) {
            person.CO2EmissionSaved = (person.CO2EmissionSaved ?? 0) - sponsorSolution.CO2Emission + oldPersonEmission;
            group.CO2EmissionSaved = (group.CO2EmissionSaved ?? 0) - sponsorSolution.CO2Emission + oldPersonEmission;
          }
          person.CO2Emission = sponsorSolution.CO2Emission;
          group.CO2Emission += sponsorSolution.CO2Emission - oldPersonEmission;
        }
      } else {
        group.SelectedSourceId = null;
        group.CustomCalculationId = sponsorSolutionId;
        if (isTargetSurvey) {
          group.CO2EmissionSaved = (group.CO2EmissionSaved ?? 0) - sponsorSolution.CO2Emission + group.CO2Emission;
        }
        group.CO2Emission = sponsorSolution.CO2Emission;
      }

      category.CO2Emission += group.CO2Emission - existingGroupEmission;

      updatedSurvey.HasInvalidInput = getMissingGroups(groups).length > 0;

      //dispatch update action
      dispatch({
        type: CO2PlanActionTypes.SET_CO2_SURVEY,
        payload: { Survey: updatedSurvey, IsTargetSurvey: isTargetSurvey },
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

// export const setUnitId = (unitId: string | null) =>  (
//   dispatch: Dispatch<AppAction>,
// ) => {
//     dispatch({
//       type: CO2PlanActionTypes.SET_UNITID,
//       payload: unitId,
//     });
// };

const getTempCO2PlanId =
  (currentStateValue?: string | null) =>
  (dispatch: Dispatch<AppAction>): void => {
    const tempPlanId = CO2PlanService.getTempCO2PlanId();

    if (currentStateValue === tempPlanId) return;

    dispatch({
      type: CO2PlanActionTypes.SET_TEMP_CO2_PLANID,
      payload: tempPlanId,
    });
  };

const mapTempPlanToUnit =
  (tempCO2PlanId: string, unitId: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<void> => {
    try {
      dispatch({ type: CO2PlanActionTypes.MAP_TEMP_CO2_PLAN_TO_UNIT });
      await CO2PlanService.mapTempPlanToUnit(tempCO2PlanId, unitId);
      dispatch({
        type: CO2PlanActionTypes.MAP_TEMP_CO2_PLAN_TO_UNIT_SUCCEEDED,
      });
      CO2PlanService.clearTempCO2PlanId();
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const registerCO2EconomyItemLinkClick =
  (emissionSourceGroupId: string, economyItemId: string, co2PlanId: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<void> => {
    try {
      await CO2PlanService.registerCO2EconomyItemLinkClick(emissionSourceGroupId, economyItemId, co2PlanId);
      dispatch({
        type: CO2PlanActionTypes.REGISTER_CO2_ECONOMY_ITEM_CLICK_SUCCEEDED,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getSponsorSolutions =
  (emissionSourceId: string, subEmissionSourceId?: string) =>
  async (dispatch: Dispatch<AppAction>): Promise<SearchResult<CO2SponsorSolution> | undefined> => {
    try {
      const result = await CO2PlanService.getSponsorSolutions(emissionSourceId, subEmissionSourceId);
      dispatch({
        type: CO2PlanActionTypes.GET_SPONSOR_SOLUTIONS,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

function selectSource(
  emissionSourceId: string,
  personId: string | null,
  currentSurvey: CO2EmissionSurvey,
  isTargetSurvey: boolean,
  isUpdateSelected: boolean,
  deselectId: string | null,
  dispatch: Dispatch<AppAction>,
  updateSource: (source: CO2EmissionSource) => void,
  order?: number,
  isMultiSelect?: boolean
) {
  try {
    const updatedSurvey = { ...currentSurvey };

    const { category, group, person, hightLevelSources, source } = getParentsBySourceId(
      emissionSourceId,
      personId,
      updatedSurvey
    );

    //if a source's sibling is selected - try to replicate its selection in current source
    tryRepicateChildrenSelection(hightLevelSources, group, person, source);

    //update selectedSourceId in hierarchy
    let selectedSourceId = emissionSourceId;
    if (hightLevelSources.length > 0) {
      hightLevelSources[hightLevelSources.length - 1].SelectedChildSourceId = selectedSourceId;
      selectedSourceId = hightLevelSources[hightLevelSources.length - 1].Id;
      for (let i = hightLevelSources.length - 2; i >= 0; i--) {
        hightLevelSources[i].SelectedChildSourceId = selectedSourceId;
        selectedSourceId = hightLevelSources[i].Id;
      }
    }
    if (group.IsPerPerson && person) {
      person.SelectedSourceId = selectedSourceId;
    } else {
      group.SelectedSourceId = selectedSourceId;
    }

    const existingEmission = group.CO2Emission;
    if (order) {
      source.Order = order;
    }
    //Aply source-specific logic
    updateSource(source);

    if (isUpdateSelected)
      updateIsSelected(group, deselectId ?? (source.IsSelected ? source.Id : null), personId, isMultiSelect);

    //update emission on parent levels
    if (group.IsPerPerson && person) {
      const oldPersonEmission = person.CO2Emission;

      if (isTargetSurvey) {
        person.CO2EmissionSaved = (person.CO2EmissionSaved ?? 0) - source.CO2Emission + oldPersonEmission;
        group.CO2EmissionSaved = (group.CO2EmissionSaved ?? 0) - source.CO2Emission + oldPersonEmission;
      }
      person.CustomEconomyCost = null;
      group.CustomEconomyCost = null;
      person.CustomCalculationId = null;
      if (source.CombinedMaxAmount != null) {
        //update common CO2Emission
        const selectedSources = person.EmissionSources.filter((s) => s.IsSelected);
        person.CO2Emission = selectedSources.reduce((a, b) => {
          return a + (b.Id == source.Id ? source.CO2Emission : b.CO2Emission);
        }, 0);
        group.CO2Emission += person.CO2Emission - oldPersonEmission;
      } else {
        person.CO2Emission = source.CO2Emission;
        group.CO2Emission += source.CO2Emission - oldPersonEmission;
      }
    } else {
      if (isTargetSurvey) {
        group.CO2EmissionSaved = (group.CO2EmissionSaved ?? 0) - source.CO2Emission + group.CO2Emission;
      }
      group.CO2Emission = source.CO2Emission;
      group.CustomCalculationId = null;
      group.CustomEconomyCost = null;
    }

    hightLevelSources.forEach((parent) => (parent.CO2Emission = source.CO2Emission));

    category.CO2Emission += group.CO2Emission - existingEmission;

    // if (isUpdateSelected)
    //   updateIsSelected(group, source.IsSelected ? source.Id : null, personId);

    const groups: CO2EmissionSourceGroup[] = updatedSurvey.Categories.map((cat) => cat.EmissionSourceGroups).flat();

    updatedSurvey.HasInvalidInput = getMissingGroups(groups).length > 0;

    //dispatch update action
    dispatch({
      type: CO2PlanActionTypes.SET_CO2_SURVEY,
      payload: { Survey: updatedSurvey, IsTargetSurvey: isTargetSurvey },
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
}

//find emission source and its high-level parents by id
function getParentsBySourceId(
  emissionSourceId: string,
  personId: string | null,
  survey: CO2EmissionSurvey
): {
  category: CO2Category;
  group: CO2EmissionSourceGroup;
  person: CO2PersonEmissionSourceGroup | null;
  hightLevelSources: CO2EmissionSource[];
  source: CO2EmissionSource;
} {
  const groups: CO2EmissionSourceGroup[] = survey.Categories.map((cat) => cat.EmissionSourceGroups).flat();
  type CO2EmissionSourceHighLevel = CO2EmissionSource & {
    parentGroup: CO2EmissionSourceGroup;
    parentPerson: CO2PersonEmissionSourceGroup | null;
  };

  const highestLevelSoures: CO2EmissionSourceHighLevel[] = groups
    .map((group) => {
      if (group.IsPerPerson) {
        if (!personId) {
          return [];
        }
        if (group.PersonEmissionSources.length > 0) {
          const person = group.PersonEmissionSources.filter((person) => person.PersonId === personId)[0];
          return person.EmissionSources.map((source) => ({
            ...source,
            parentGroup: group,
            parentPerson: person,
          }));
        } else {
          return [];
        }
      } else {
        return group.HouseHoldEmissionSources.map((source) => ({
          ...source,
          parentGroup: group,
          parentPerson: null,
        }));
      }
    })
    .flat();

  let source: CO2EmissionSource | null = null;
  let path: CO2EmissionSource[] = [];

  for (let i = 0; i < highestLevelSoures.length && source === null; ++i) {
    const searchResult = tryGetPathToSource(highestLevelSoures[i], emissionSourceId, []);
    source = searchResult.source;
    path = searchResult.hightLevelSources;
  }

  if (source === null) {
    throw Error(`Cannot find emission source ${emissionSourceId}`);
  }

  const group =
    path.length > 0
      ? (path[0] as CO2EmissionSourceHighLevel).parentGroup
      : highestLevelSoures.filter((src) => src.Id === source?.Id)[0].parentGroup;
  const person =
    path.length > 0
      ? (path[0] as CO2EmissionSourceHighLevel).parentPerson
      : highestLevelSoures.filter((src) => src.Id === source?.Id)[0].parentPerson;
  const category = survey.Categories.filter(
    (cat) => cat.EmissionSourceGroups.filter((gr) => gr.Id === group.Id).length > 0
  )[0];

  //path contains clonned object for highest level source, replace it with original
  if (path.length > 0) {
    if (group.IsPerPerson && person) {
      path[0] = person.EmissionSources.filter((src) => src.Id === path[0].Id)[0];
    } else {
      path[0] = group.HouseHoldEmissionSources.filter((src) => src.Id === path[0].Id)[0];
    }
  } else {
    if (group.IsPerPerson && person) {
      source = person.EmissionSources.filter((src) => src.Id === source?.Id)[0];
    } else {
      source = group.HouseHoldEmissionSources.filter((src) => src.Id === source?.Id)[0];
    }
  }

  return { category, group, person, hightLevelSources: path, source };
}

//get a chain of nested high-level sources and a source itself
function tryGetPathToSource(
  candidate: CO2EmissionSource,
  emissionSourceId: string,
  searchStack: CO2EmissionSource[]
): {
  hightLevelSources: CO2EmissionSource[];
  source: CO2EmissionSource | null;
} {
  if (candidate.Id === emissionSourceId) {
    return { hightLevelSources: searchStack, source: candidate };
  }
  if (candidate.HasChildSources || candidate.ChildSources?.length > 0) {
    for (let i = 0; i < candidate.ChildSources.length; ++i) {
      const { hightLevelSources, source } = tryGetPathToSource(candidate.ChildSources[i], emissionSourceId, [
        ...searchStack,
        candidate,
      ]);
      if (source) {
        return { hightLevelSources, source };
      }
    }
  }
  //nothing found
  return { hightLevelSources: [], source: null };
}

function getMissingGroups(
  groups: CO2EmissionSourceGroup[],
  planPersons?: CO2PlanPerson[]
): Array<[string, string | null]> {
  const missingGroups = Array<[string, string | null]>(); // array of group-person pair

  const validateSource = (source: CO2EmissionSource): boolean => {
    if (source.HasChildSources) {
      if (!source.SelectedChildSourceId) {
        return false;
      }
      const childSource = source.ChildSources.filter((child) => child.Id === source.SelectedChildSourceId)[0];
      return validateSource(childSource);
    }
    if (source.HasOptions) {
      return !!source.SelectedOptionId;
    }
    return true;
  };

  groups.forEach((group) => {
    if (group.IsPerPerson) {
      group.PersonEmissionSources.forEach((person) => {
        if (planPersons) {
          const currentPerson = planPersons.find((listPerson) => {
            return listPerson.Id === person.PersonId;
          });

          if (currentPerson?.BirthYear) {
            const currentYear = new Date().getFullYear();
            if (currentYear - currentPerson.BirthYear <= 6) return;
          }
        }
        if (!person.SelectedSourceId && !person.CustomCalculationId) {
          missingGroups.push([group.Id, person.PersonId]);
        } else {
          if (
            person.SelectedSourceId &&
            !validateSource(person.EmissionSources.filter((src) => src.Id === person.SelectedSourceId)[0])
          ) {
            missingGroups.push([group.Id, person.PersonId]);
          }
        }
      });
    } else {
      if (!group.SelectedSourceId && !group.CustomCalculationId) {
        missingGroups.push([group.Id, null]);
      } else {
        if (
          group.SelectedSourceId &&
          !validateSource(group.HouseHoldEmissionSources.filter((src) => src.Id === group.SelectedSourceId)[0])
        ) {
          missingGroups.push([group.Id, null]);
        }
      }
    }
  });
  return missingGroups;
}

//find value of CombinedMaxAmount for group
const getCombinedValueMaxAmount = (group: CO2EmissionSourceGroup): number | null => {
  let combinedMaxAmounts: (number | null)[] = [];
  if (group.IsPerPerson) {
    combinedMaxAmounts = group.PersonEmissionSources.flatMap((a) => a.EmissionSources).map((s) => s.CombinedMaxAmount);
  } else {
    combinedMaxAmounts = group.HouseHoldEmissionSources.map((a) => a.CombinedMaxAmount);
  }

  const combinedMaxAmount = Math.max(...combinedMaxAmounts.map((value) => value ?? 0));
  return combinedMaxAmount > 0 ? combinedMaxAmount : null;
};

function getSourcesToSave(groups: CO2EmissionSourceGroup[], isMultiSelect?: boolean) {
  const sourcesToSave: Array<SaveEmissionSource> = [];

  groups.forEach((group) => {
    if (group.IsPerPerson) {
      group.PersonEmissionSources.forEach((person) => {
        const selectedSources = person.EmissionSources.filter(
          (src) => src.IsSelected //Id === person.SelectedSourceId
        );
        for (const selectedSource of selectedSources) {
          const { lowestLevelSourceId, selectedOptionId, value } = getSourceSelection(selectedSource);
          sourcesToSave.push({
            EmissionSourceId: lowestLevelSourceId,
            SelectedOptionId: selectedOptionId,
            EmissionSourceGroupId: group.Id,
            PersonId: person.PersonId,
            Value: value,
            CombinedValue: isMultiSelect
              ? selectedSource.CombinedValue
              : selectedSource.CombinedMaxAmount || getCombinedValueMaxAmount(group),
            DueYear: person.DueYear,
            CustomCalculation: null,
            CustomEconomyCost: person.CustomEconomyCost,
            EconomyCost: person.EconomyCost,
          });
        }
        if (person.CustomCalculationId) {
          sourcesToSave.push({
            EmissionSourceId: null,
            SelectedOptionId: null,
            EmissionSourceGroupId: group.Id,
            PersonId: person.PersonId,
            Value: null,
            CombinedValue: isMultiSelect
              ? null
              : person.EmissionSources.find((x) => x.CombinedMaxAmount)?.CombinedMaxAmount || null,
            DueYear: person.DueYear,
            CustomCalculation: {
              CalculationId: person.CustomCalculationId,
              CO2Emission: person.CO2Emission,
            },
            CustomEconomyCost: person.CustomEconomyCost,
            EconomyCost: person.EconomyCost,
          });
        }
      });
    } else {
      const selectedSource = group.HouseHoldEmissionSources.filter((src) => src.Id === group.SelectedSourceId)[0];
      if (selectedSource) {
        const { lowestLevelSourceId, selectedOptionId, value } = getSourceSelection(selectedSource);
        sourcesToSave.push({
          EmissionSourceId: lowestLevelSourceId,
          SelectedOptionId: selectedOptionId,
          EmissionSourceGroupId: group.Id,
          PersonId: null,
          Value: value,
          CombinedValue: isMultiSelect
            ? selectedSource.CombinedValue
            : selectedSource.CombinedMaxAmount || getCombinedValueMaxAmount(group),
          DueYear: group.DueYear,
          CustomCalculation: null,
          CustomEconomyCost: group.CustomEconomyCost,
          EconomyCost: group.EconomyCost,
        });
      }
      if (group.CustomCalculationId) {
        sourcesToSave.push({
          EmissionSourceId: null,
          SelectedOptionId: null,
          EmissionSourceGroupId: group.Id,
          PersonId: null,
          Value: null,
          CombinedValue: null,
          DueYear: group.DueYear,
          CustomCalculation: {
            CalculationId: group.CustomCalculationId,
            CO2Emission: group.CO2Emission,
          },
          CustomEconomyCost: group.CustomEconomyCost,
          EconomyCost: group.EconomyCost,
        });
      }
    }
  });
  return sourcesToSave;
}

const getGroupsFromQuestions = (questions: CustomQuestions[]) => {
  const groups: SaveEmissionSource[] = [];

  const processSelectedSource = (
    group: CO2EmissionSourceGroup,
    emissionSource: CO2EmissionSource,
    personId: string | null
  ) => {
    if (emissionSource.IsSelected || (emissionSource.Question && emissionSource.MetaValue)) {
      groups.push({
        EmissionSourceId: emissionSource.Id,
        SelectedOptionId: emissionSource.SelectedOptionId,
        EmissionSourceGroupId: group.Id,
        PersonId: personId,
        Value: emissionSource.Value,
        CombinedValue: emissionSource.CombinedValue,
        DueYear: group.DueYear,
        CustomCalculation: null,
        CustomEconomyCost: group.CustomEconomyCost,
        EconomyCost: group.EconomyCost,
        MetaValue: emissionSource.MetaValue,
        PlanMetaId: emissionSource.PlanMetaId,
      });
    } else {
      emissionSource.ChildSources.forEach((childSource) => {
        if (childSource.IsSelected || (childSource.Question && childSource.MetaValue)) {
          groups.push({
            EmissionSourceId: childSource.Id,
            SelectedOptionId: childSource.SelectedOptionId,
            EmissionSourceGroupId: group.Id,
            PersonId: personId,
            Value: childSource.Value,
            CombinedValue: childSource.CombinedValue,
            DueYear: group.DueYear,
            CustomCalculation: null,
            CustomEconomyCost: group.CustomEconomyCost,
            EconomyCost: group.EconomyCost,
            MetaValue: childSource.MetaValue,
            PlanMetaId: childSource.PlanMetaId,
          });
        }
      });
    }
  };

  for (const question of questions) {
    for (const customQuestion of question.Questions) {
      customQuestion.EmissionSourceGroups.forEach((group) => {
        group.HouseHoldEmissionSources.forEach((houseHoldSource) =>
          processSelectedSource(group, houseHoldSource, null)
        );

        group.PersonEmissionSources.forEach((personSource) => {
          personSource.EmissionSources.forEach((emissionSource) =>
            processSelectedSource(group, emissionSource, personSource.PersonId)
          );
        });
      });
    }
  }

  return groups;
};

function getSourceSelection(source: CO2EmissionSource): {
  lowestLevelSourceId: string;
  selectedOptionId: string | null;
  value: number | null;
} {
  if (source.ChildSources?.length > 0 && source.SelectedChildSourceId != null) {
    const selectedChild = source.ChildSources.filter((src) => src.Id === source.SelectedChildSourceId)[0];
    return getSourceSelection(selectedChild);
  }
  return {
    lowestLevelSourceId: source.Id,
    selectedOptionId: source.SelectedOptionId,
    value: source.Value,
  };
}

// Recursively set IsSelected based on parent's SelectedSourceId
function updateIsSelected(
  group: CO2EmissionSourceGroup,
  deselectId: string | null = null,
  personId: string | null = null,
  isMultiSelect?: boolean
): void {
  const updateSourceBasedOnParentSelection = (
    source: CO2EmissionSource,
    parentSelectionId: string | null,
    unselectId: string | null = null
  ) => {
    //deselect, awailable for multiselect only (source.CombinedMaxAmount !== null)
    if (!!source.CombinedMaxAmount && isMultiSelect && source.Id === unselectId) {
      return {
        ...source,
        IsSelected: false,
        SelectedChildSourceId: null,
        SelectedOptionId: null,
      };
    } else if (source.Id === parentSelectionId) {
      return { ...source, IsSelected: true };
    } else if (!source.CombinedMaxAmount || !isMultiSelect) {
      return {
        ...source,
        IsSelected: false,
        SelectedChildSourceId: null,
        SelectedOptionId: null,
      };
    }

    return { ...source };
  };

  const unselectEmissionSourceAndChildren = (source: CO2EmissionSource) => {
    if (source.ChildSources?.length > 0) {
      source.ChildSources = source.ChildSources.map((src) => {
        return updateSourceBasedOnParentSelection(
          src,
          source.SelectedChildSourceId,
          src.Id == source.SelectedChildSourceId ? null : src.Id
        );
      });
      source.ChildSources.forEach((childSource) => unselectEmissionSourceAndChildren(childSource));
    }
  };

  if (group.IsPerPerson) {
    group.PersonEmissionSources.forEach((person) => {
      if (personId == null || person.PersonId === personId) {
        person.EmissionSources = person.EmissionSources.map((src) =>
          updateSourceBasedOnParentSelection(src, person.SelectedSourceId, deselectId)
        );
        person.EmissionSources.forEach((childSource) => unselectEmissionSourceAndChildren(childSource));
      }
    });
  } else {
    group.HouseHoldEmissionSources = group.HouseHoldEmissionSources.map((src) =>
      updateSourceBasedOnParentSelection(src, group.SelectedSourceId)
    );
    group.HouseHoldEmissionSources.forEach((childSource) => unselectEmissionSourceAndChildren(childSource));
  }
}

function tryRepicateChildrenSelection(
  highLevelSources: CO2EmissionSource[],
  group: CO2EmissionSourceGroup,
  person: CO2PersonEmissionSourceGroup | null,
  source: CO2EmissionSource
): void {
  //if a source's sibling is selected - get its sub-selection as an array of sorting codes
  let selectedSibling: CO2EmissionSource | null = null;
  if (highLevelSources.length > 0) {
    const parentSource = highLevelSources[highLevelSources.length - 1];
    if (parentSource.SelectedChildSourceId) {
      selectedSibling = parentSource.ChildSources.filter((src) => src.Id === parentSource.SelectedChildSourceId)[0];
    }
  } else {
    if (group.IsPerPerson && person) {
      if (person.SelectedSourceId) {
        selectedSibling = person.EmissionSources.filter((src) => src.Id === person.SelectedSourceId)[0];
      }
    } else {
      if (group.SelectedSourceId) {
        selectedSibling = group.HouseHoldEmissionSources.filter((src) => src.Id === group.SelectedSourceId)[0];
      }
    }
  }

  //get an array of sorting codes
  const siblingsSelection: number[] = [];

  if (selectedSibling) {
    let siblingBranchItem: CO2EmissionSource | null = selectedSibling;
    while (siblingBranchItem && (siblingBranchItem.SelectedChildSourceId || siblingBranchItem.SelectedOptionId)) {
      if (siblingBranchItem.SelectedChildSourceId) {
        const itemsChild: CO2EmissionSource = siblingBranchItem.ChildSources.filter(
          (src) => src.Id === siblingBranchItem!.SelectedChildSourceId
        )[0];
        siblingsSelection.push(itemsChild.Code);
        siblingBranchItem = itemsChild;
      }

      if (siblingBranchItem.SelectedOptionId) {
        const siblingsSelectedOption = siblingBranchItem.Options.find(
          (opt) => opt.Id === siblingBranchItem!.SelectedOptionId
        );
        if (siblingsSelectedOption) {
          siblingsSelection.push(siblingsSelectedOption.Code);
        }
        siblingBranchItem = null;
      }
    }
  }

  //try to select child items based on an array of sorting codes
  if (siblingsSelection.length > 0) {
    const updateSourcesSelection = (currentSource: CO2EmissionSource, selectionCodes: number[]) => {
      if (currentSource.HasChildSources && selectionCodes.length > 0) {
        const childToSelect = currentSource.ChildSources.find((src) => src.Code === selectionCodes[0]);
        if (childToSelect) {
          currentSource.SelectedChildSourceId = childToSelect.Id;
          const childsSelection = selectionCodes.slice(1); // remove first element
          updateSourcesSelection(childToSelect, childsSelection);
          currentSource.CO2Emission = childToSelect.CO2Emission;
        }
      }

      if (currentSource.HasOptions && selectionCodes.length > 0) {
        const optionToSelect = currentSource.Options.find((opt) => opt.Code === selectionCodes[0]);
        if (optionToSelect) {
          currentSource.SelectedOptionId = optionToSelect.Id;
        }
      }
    };
    updateSourcesSelection(source, siblingsSelection);
  }
}

const selectCustomAnswer =
  (sourceId: string, groupId: string, questions: CustomQuestions[], personId?: string, metaValue?: string) =>
  (dispatch: Dispatch<AppAction>): void => {
    const _getUpdatedSource = (source: CO2EmissionSource, isMultiselect: boolean) => {
      const updatedChildSource: CO2EmissionSource = {
        ...source,
        ChildSources: source.ChildSources.map((childSource) => {
          if (childSource.Id === sourceId) {
            if (isMultiselect && childSource.IsSelected) {
              return {
                ...childSource,
                IsSelected: false,
              };
            }

            if (metaValue !== undefined) {
              return {
                ...childSource,
                MetaValue: metaValue,
              };
            }

            return {
              ...childSource,
              IsSelected: true,
            };
          }

          if (!isMultiselect) {
            return {
              ...childSource,
              IsSelected: false,
            };
          }

          return childSource;
        }),
      };
      return updatedChildSource;
    };

    const updatedQuestions = [...questions].map((questionBlock) => {
      const updatedQuestionBlock: CustomQuestions = {
        ...questionBlock,
        Questions: questionBlock.Questions.map((question) => {
          const updatedQuestions: CO2Category = {
            ...question,
            EmissionSourceGroups: question.EmissionSourceGroups.map((group) => {
              const updatedGroup: CO2EmissionSourceGroup = {
                ...group,
                HouseHoldEmissionSources: group.HouseHoldEmissionSources.map((source) => {
                  if (groupId !== group.Id) return source;

                  return _getUpdatedSource(source, group.IsMultiselect);
                }),
                PersonEmissionSources: group.PersonEmissionSources.map((personSource) => {
                  if (groupId !== group.Id || personSource.PersonId !== personId) return personSource;

                  const updatedSource: CO2PersonEmissionSourceGroup = {
                    ...personSource,
                    EmissionSources: personSource.EmissionSources.map((source) =>
                      _getUpdatedSource(source, group.IsMultiselect)
                    ),
                  };

                  return updatedSource;
                }),
              };
              return updatedGroup;
            }),
          };

          return updatedQuestions;
        }),
      };

      return updatedQuestionBlock;
    });

    dispatch({
      type: CO2PlanActionTypes.SET_UPDATED_QUESTIONS,
      payload: updatedQuestions,
    });
  };
