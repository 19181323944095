import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, Button, FormControlLabel, LinearProgress } from "@mui/material";
import { useHistory } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { connect, ConnectedProps } from "react-redux";
import AutoComplete from "../../../../components/Autocomplete/AutoComplete";
import BaseDialog from "../../../../components/Dialogs/BaseDialog";
import MCheckbox from "../../../../components/Base/MyhouseCheckbox";
import { moveInToProperty } from "../../../../actions/user.actions";
import { ApplicationState } from "../../../../reducers/store";
import AddressHelper from "../../../../helpers/address-helper";
import Unit from "../../../../definitions/model/unit/Unit";
import { AppThunkDispatch } from "../../../../definitions/Action";
import { UserConstants } from "../../../../constants";
import { useTranslation } from "react-i18next";
import { ControllerConnector } from "../../../../components/Base/FormConnector";
import { useAppDispatch } from "../../../../actions";

type MoveInDialogProps = {
  presetUnit: Unit;
  showJoinFamilyDialog: () => void;
};

type MoveInFormData = {
  PropertyId: string;
  IsResident: boolean;
};

const MoveInDialog = ({
  moveInToProperty,
  showJoinFamilyDialog,
  showMoveInDialog,
  presetUnit,
  loading,
}: MoveInDialogProps & MoveInDialogConnectedProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation");
  const [valueCallback, setValueCallback] = useState("");
  const [autocompletePreset, setAutocompletePreset] = useState("");
  const methods = useForm<MoveInFormData>({
    defaultValues: {
      PropertyId: "",
      IsResident: false,
    },
  });
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
  } = methods;
  const closeDialog = () => dispatch({ type: UserConstants.HIDE_MOVE_IN_DIALOG });

  useEffect(() => {
    setValue("PropertyId", valueCallback);
  }, [valueCallback, setValue]);

  useEffect(() => {
    register("PropertyId", { required: true });
    return () => unregister("PropertyId"); // unregister input after component unmount
  }, [register, unregister]);

  useEffect(() => {
    if (presetUnit) {
      const autocompleteValue = AddressHelper.GetAutocompleteInput(presetUnit.Address);
      setAutocompletePreset(autocompleteValue);
      setValue("PropertyId", presetUnit.Id);
    }
  }, [presetUnit, setValue]);

  const submit = (data: MoveInFormData) => {
    //For now we allow all sers to move-in directly, whithou checking if property alredy occupied
    // if (presetUnit.FamilyGroupId !== NIL_UUID) {
    //   showJoinFamilyDialog();
    //   closeDialog();
    // } else {
    moveInToProperty(data.PropertyId).then((unit: Unit | null | undefined) => {
      if (unit) {
        history.push(`${AddressHelper.GetUnitAddressUrl(unit)}/profile`);
      }
      closeDialog();
    });
    // }
  };

  return (
    <BaseDialog
      titleComponent={t("Pages.PropertyFacts.MoveIntoHomeTitle") ?? ""}
      closeDialog={closeDialog}
      open={showMoveInDialog}
    >
      <Grid container justifyContent="center">
        <Grid
          item
          sx={{
            width: "511px",
          }}
        >
          {loading && <LinearProgress color="primary" />}
          <Box mb={4}>
            <Typography>{t("Pages.PropertyFacts.RequireVirtuallyMoveIntoOwnHome")}</Typography>
          </Box>
          <AutoComplete
            placeholder={t("Autocomplete.Placeholder")}
            setValueCallback={setValueCallback}
            className={`${errors.PropertyId ? "Mui-error Mui-error" : ""}`}
            inputValue={autocompletePreset}
          />
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(submit)}>
              <FormControlLabel
                control={
                  <ControllerConnector name="IsResident" type="checkbox">
                    <MCheckbox name="IsResident" error={!!errors.IsResident} />
                  </ControllerConnector>
                }
                label={<Typography variant="caption">{t("Pages.PropertyFacts.ConfirmWhereILive")}</Typography>}
                sx={{
                  "& .MuiFormControlLabel-root": {
                    margin: 0,
                  },
                }}
              />
              <Grid container justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  sx={{
                    width: "170px",
                    "& span": {
                      width: "auto",
                    },
                  }}
                >
                  {t("Pages.PropertyFacts.MoveIn")}
                </Button>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </BaseDialog>
  );
};

const mapStateToProps = (state: ApplicationState) => {
  return {
    loading: state.user.loading,
    showMoveInDialog: state.user.showMoveInDialog,
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  moveInToProperty: (unitId: string) => dispatch(moveInToProperty(unitId, false)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type MoveInDialogConnectedProps = ConnectedProps<typeof connector>;

export default connector(MoveInDialog);
