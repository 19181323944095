import React, { useEffect, useState } from "react";
import * as H from "history";
import { Typography, List, ListItem, ListItemText, Paper, Grid, styled } from "@mui/material";
import { NavigationItem } from "../../../definitions/Menu";
import { useUnit } from "../../../actions/unit.actions";
import Unit from "../../../definitions/model/unit/Unit";
import { useHistory } from "react-router-dom";
import { UserRating } from "../../../definitions/model/User";
import { repeatSkeleton } from "../../../components/Base/DetailsSkeleton";
import { useUser } from "../../../actions/user.actions";
import { GetTranslatedText } from "../../../helpers/translation-helper";
import NavigationHelper from "../../../helpers/navigation-helper";
import { Building } from "../../../definitions/model/Building";
import { useBuilding } from "../../../actions/building.actions";

type MenuListItemProps = {
  title: string;
  onClick?: () => void;
  userSensitive?: boolean;
  background: string;
  isPublished: boolean;
  targetUrl?: string;
  totalCO2Plans?: number;
  fullWidth?: boolean;
};

const MenuList = styled(List)(({ theme }) => ({
  width: "auto",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  [theme.breakpoints.up("sm")]: {
    justifyContent: "unset",
  },
}));

const StyledListItem = styled(ListItem, { shouldForwardProp: (propName: string) => propName !== "fullWidth" })<{
  fullWidth?: boolean;
}>(({ theme, fullWidth }) => ({
  width: "100%",
  maxWidth: fullWidth ? "100%" : 320,
  height: 215,
  minWidth: 265,
  [theme.breakpoints.up("sm")]: {
    width: fullWidth ? "100%" : 265,
    maxWidth: fullWidth ? "100%" : 320,
  },
  "&:hover": {
    textDecoration: "none",
    cursor: "pointer",
  },
}));

const StyledPaper = styled(Paper)(() => ({
  textAlign: "center",
  alignItems: "center",
  margin: 8,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "flex-end",
  boxSizing: "border-box",
  width: "100%",
  position: "relative",
  overflow: "hidden",
  "& img": {
    objectFit: "cover",
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  "& div": {
    zIndex: 1,
  },
}));

const BadgeGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: "50%",
  textAlign: "center",
  width: 72,
  height: 72,
  position: "absolute",
  padding: 3,
  top: -10,
  left: -10,
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box",
  zIndex: 1,
}));

type MenuBlockListProps = {
  navigationItems: NavigationItem[] | null;
  totalCO2Plans: number;
  unitLoading: boolean;
  getNavigationItems: (userRating?: UserRating) => Promise<NavigationItem[] | undefined>;
};

const MenuBlockList = ({
  navigationItems,
  totalCO2Plans,
  getNavigationItems,
  unitLoading,
}: MenuBlockListProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [{ unit }] = useUnit();
  const [{ user }] = useUser();
  const [{ building }] = useBuilding();
  const history = useHistory();

  useEffect(() => {
    if (!navigationItems) {
      setLoading(true);
      getNavigationItems(user?.Rating).finally(() => setLoading(false));
    }
  }, [navigationItems, user]);

  return (
    <MenuList disablePadding>
      {!loading &&
        navigationItems &&
        !unitLoading &&
        navigationItems
          .filter((item) => !item.UserSensitive || (item.UserSensitive && user?.Rating && item.Rating <= user.Rating))
          .map((item) => (
            <MenuListItem
              key={item.OrderNumber}
              onClick={() => menuClick(item.TargetUrl, item.UnitSensitive, unit, building, history)}
              title={GetTranslatedText(item.Titles)}
              background={item.BackgroundImageUrl}
              isPublished={item.IsPublished}
              targetUrl={item.TargetUrl}
              totalCO2Plans={totalCO2Plans}
            />
          ))}
      {(loading || unitLoading) &&
        repeatSkeleton(
          { width: "100%", height: "100%", sx: { transform: "unset" } },
          5,
          <StyledListItem style={{ padding: 8 }} />
        )}
    </MenuList>
  );
};

export default MenuBlockList;

export const MenuListItem = (props: MenuListItemProps): JSX.Element => {
  return (
    <StyledListItem fullWidth={props.fullWidth} disableGutters onClick={props.onClick}>
      {props.targetUrl === "/co2calculator" && props.totalCO2Plans ? (
        <BadgeGrid>
          <Typography variant="body2" style={{ color: "white", lineHeight: "1.2" }}>
            {(props.totalCO2Plans || 0).toLocaleString("da-dk", { maximumFractionDigits: 0 })} planer oprettet
          </Typography>
        </BadgeGrid>
      ) : null}
      <StyledPaper elevation={3} style={{ opacity: props.isPublished ? 1 : 0.5 }}>
        <img src={props.background} title={props.title} />
        <StyledListItemText>
          <Typography variant="h6" style={{ color: "#fff" }}>
            {props.title}
          </Typography>
        </StyledListItemText>
      </StyledPaper>
    </StyledListItem>
  );
};

export const menuClick = (
  targetUrl: string,
  unitSensitive: boolean,
  unit: Unit | null,
  building: Building | null,
  history: H.History
): void => {
  if (unitSensitive && !unit) {
    history.push("/search/move-in");
    return;
  }

  NavigationHelper.GoByRoute(history, unit, building, targetUrl);
};

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  background: "rgba(0, 0, 0, 0.3)",
  margin: 0,
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  flex: "none",
  height: 70,
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
}));
