import ApiService from "./api.service";
import { headers } from "./http.service";
import {
  FolderType,
  DocumentType,
  FileType,
  CreateFolderRequest,
  RemoveFolderRequest,
  CreateDocumentRequest,
  RemoveDocumentRequest,
  UpdateDocumentRequest,
  UpdateDocumentFolderRequest,
  UnitFoldersType,
  ReorderDocumentsRequest,
  ReorderFoldersRequest,
} from "../definitions/Document";
import { Dictionary } from "lodash";

const DocumentService = {
  getFolders: async (sourceItemId: string, childSourceItemId?: string): Promise<FolderType[]> => {
    let url = `/api/Document/getFolders/${sourceItemId}`;
    if (childSourceItemId) url += `?childSourceItemId=${childSourceItemId}`;
    
    const result = await ApiService.get<FolderType[]>(url);
    return result;
  },
  createFolder: async (data: CreateFolderRequest): Promise<FolderType> => {
    const url = `/api/Document/createfolder`;
    const result = await ApiService.post<FolderType>(url, data);
    return result;
  },
  updateDocumentFolder: async (data: UpdateDocumentFolderRequest): Promise<FolderType> => {
    const url = `/api/Document/updatefolder`;
    const result = await ApiService.post<FolderType>(url, data);
    return result;
  },
  deleteFolder: async (data: RemoveFolderRequest): Promise<void> => {
    const url = `/api/Document/removefolder/${data.SourceItemId}/${data.FolderId}`;
    return await ApiService.delete<void>(url);
  },
  reorderFolders: async (data: ReorderFoldersRequest): Promise<FolderType[]> => {
    const url = `/api/Document/reorderFolders`;
    const result = await ApiService.put<FolderType[]>(url, data);
    return result;
  },
  createDocument: async (data: CreateDocumentRequest): Promise<DocumentType> => {
    const url = `/api/Document/createDocument`;
    const result = await ApiService.post<DocumentType>(url, data);
    return result;
  },
  deleteDocument: (data: RemoveDocumentRequest): Promise<void> => {
    const url = `/api/Document/removeDocument/${data.SourceItemId}/${data.FolderId}/${data.DocumentId}`;
    return ApiService.delete(url);
  },
  updateDocument: async (data: UpdateDocumentRequest): Promise<DocumentType> => {
    const url = `/api/Document/updateDocument`;
    const result = await ApiService.put<DocumentType>(url, data);
    return result;
  },
  reorderDocuments: async (data: ReorderDocumentsRequest): Promise<FolderType> => {
    const url = `/api/Document/reorderDocuments`;
    const result = await ApiService.put<FolderType>(url, data);
    return result;
  },
  uploadFile: async (file: File, metadata?: Dictionary<string>): Promise<FileType> => {
    const formData = new FormData();
    const blob = new Blob([file], { type: file.type });
    formData.append("File", blob);
    formData.append("Name", file.name);
    if (metadata) {
      formData.append("Metadata", JSON.stringify(metadata));
    }
    const headersNoContentType = await headers();
    delete headersNoContentType["Content-Type"];

    const response = await fetch(`/api/Document/uploadFile`, {
      method: "POST",
      body: formData,
      headers: headersNoContentType,
    });
    const result = await response.json();
    return result;
  },
  getUserFolders: async (userId: string): Promise<UnitFoldersType[]> => {
    const url = `/api/Document/allfolders/${userId}`;
    const result = await ApiService.get<UnitFoldersType[]>(url);
    return result;
  },
  deleteDocuments: async (sourceItemId: string, documentIds: string[], removeAll = false): Promise<void> => {
    const url = `/api/Document/removeDocuments`;
    await ApiService.post(url, {
      SourceItemId: sourceItemId,
      DocumentIds: documentIds,
      RemoveAll: removeAll,
    });
  },
  deleteDocumentsFromUnits: async (unitIds: string[], documentIds: string[], removeAll = false): Promise<void> => {
    const url = `/api/document/removedocuments/all`;
    const request = {
      UnitIds: unitIds,
      DocumentIds: documentIds,
      RemoveAll: removeAll,
    };
    await ApiService.post(url, request);
  },
  deleteFile: async (fileId: string): Promise<void> => {
    const url = `/api/Document/file/${fileId}`;
    await ApiService.delete<void>(url);
  },
};

export default DocumentService;
