import { DocumentActions } from "../constants/document.actiontypes";
import { AppThunkDispatch } from "../definitions/Action";
import {
  CreateFolderRequest,
  RemoveFolderRequest,
  CreateDocumentRequest,
  RemoveDocumentRequest,
  UpdateDocumentRequest,
  UpdateDocumentFolderRequest,
  DocumentSourceType,
  ReorderDocumentsRequest,
  ReorderFoldersRequest,
} from "../definitions/Document";
import DocumentService from "../services/document.service";
import { operationFailedActionGeneral, useAppDispatch } from ".";
import { useSelector } from "react-redux";
import { ApplicationState } from "../reducers/store";
import { v4 as uuid } from "uuid";
import { Dictionary } from "lodash";

const operationFailedAction = (payload: unknown) =>
  operationFailedActionGeneral(payload, DocumentActions.DOCUMENT_OPERATION_FAILED);

const getFolders =
  (sourceItemId: string, sourceType: DocumentSourceType, childSourceItemId?: string) =>
  async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({
        type: DocumentActions.GET_FOLDERS,
      });

      const result = await DocumentService.getFolders(sourceItemId, childSourceItemId);
      dispatch({
        type: DocumentActions.GET_FOLDERS_SUCCEDED,
        payload: { result, SourceType: sourceType },
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const createFolder = (data: CreateFolderRequest) => async (dispatch: AppThunkDispatch) => {
  try {
    const tempId = uuid();
    dispatch({
      type: DocumentActions.CREATE_FOLDER,
      payload: {
        ...data,
        Id: tempId,
        isDirty: true,
        Documents: [],
        Submitted: new Date(),
      },
    });
    const result = await DocumentService.createFolder(data);

    dispatch({
      type: DocumentActions.CREATE_FOLDER_SUCCEDED,
      payload: { ...result, tempId: tempId, SourceType: data.SourceType },
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const deleteFolder =
  (data: RemoveFolderRequest, sourceType: DocumentSourceType) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({
        type: DocumentActions.DELETE_FOLDER,
        payload: { FolderId: data.FolderId, SourceType: sourceType },
      });
      await DocumentService.deleteFolder(data);

      dispatch({
        type: DocumentActions.DELETE_FOLDER_SUCCEDED,
        payload: { FolderId: data.FolderId, SourceType: sourceType },
      });
    } catch (error) {
      dispatch({
        type: DocumentActions.DOCUMENT_OPERATION_FAILED,
        payload: { document: { folderId: data.FolderId }, error, SourceType: sourceType },
      });
      throw error;
    }
  };

const reorderFolders = (data: ReorderFoldersRequest) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: DocumentActions.REORDER_FOLDERS,
      payload: data,
    });
    const result = await DocumentService.reorderFolders(data);

    dispatch({
      type: DocumentActions.REORDER_FOLDERS_SUCCEDED,
      payload: { result, SourceType: data.SourceType },
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
    // dispatch({
    //   type: DocumentActions.DOCUMENT_OPERATION_FAILED,
    //   payload: { document: { id: data.DocumentId, folderId: data.FolderId }, error, SourceType: data.SourceType },
    // });
    throw error;
  }
};

const createDocument = (data: CreateDocumentRequest) => async (dispatch: AppThunkDispatch) => {
  const tempId = uuid();
  try {
    dispatch({
      type: DocumentActions.CREATE_DOCUMENT,
      payload: {
        ...data,
        Id: tempId,
        isDirty: true,
        Submitted: new Date(),
        RelatedSourceIds: data.ChildSourceItemId ? [data.ChildSourceItemId] : [],
      },
    });
    const result = await DocumentService.createDocument(data);

    dispatch({
      type: DocumentActions.CREATE_DOCUMENT_SUCCEDED,
      payload: { folderId: data.FolderId, document: result, tempId: tempId, SourceType: data.SourceType },
    });
  } catch (error) {
    dispatch({
      type: DocumentActions.CREATE_DOCUMENT_ERROR,
      payload: { FolderId: data.FolderId, DocumentId: tempId, SourceType: data.SourceType },
    });
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const deleteDocument =
  (data: RemoveDocumentRequest, sourceType: DocumentSourceType) => async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({ type: DocumentActions.DELETE_DOCUMENT });
      await DocumentService.deleteDocument(data);

      dispatch({
        type: DocumentActions.DELETE_DOCUMENT_SUCCEDED,
        payload: { folderId: data.FolderId, documentId: data.DocumentId, SourceType: sourceType },
      });
    } catch (error) {
      dispatch({
        type: DocumentActions.DOCUMENT_OPERATION_FAILED,
        payload: {
          document: { id: data.DocumentId, folderId: data.FolderId, SourceType: sourceType },
          error,
        },
      });
      throw error;
    }
  };

const updateDocument =
  (data: UpdateDocumentRequest) => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
    const folders = getState().document.documentFolders[data.SourceType];
    const doc = folders.flatMap((x) => x.Documents)?.find((x) => x.Id === data.DocumentId);
    const documentData = {
      SourceItemId: data.SourceItemId,
      FolderId: data.FolderId,
      DocumentId: data.DocumentId,
    };
    if (!doc) return;

    const folderId = folders.find((x) => x.Documents.some((d) => d.Id === data.DocumentId))?.Id;
    try {
      dispatch({
        type: DocumentActions.UPDATE_DOCUMENT,
        payload: { ...doc, ...documentData, isDirty: true, SourceType: data.SourceType },
      });
      const result = await DocumentService.updateDocument(data);

      dispatch({
        type: DocumentActions.UPDATE_DOCUMENT_SUCCEDED,
        payload: { folderId: data.FolderId, document: result, SourceType: data.SourceType },
      });
    } catch (error) {
      dispatch({
        type: DocumentActions.DOCUMENT_OPERATION_FAILED,
        payload: { document: { id: doc.Id, folderId }, error, SourceType: data.SourceType },
      });
      throw error;
    }
  };

const reorderDocuments = (data: ReorderDocumentsRequest) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({
      type: DocumentActions.REORDER_DOCUMENTS,
      payload: data,
    });
    const result = await DocumentService.reorderDocuments(data);

    dispatch({
      type: DocumentActions.REORDER_DOCUMENTS_SUCCEDED,
      payload: { ...result, SourceType: data.SourceType },
    });
  } catch (error) {
    dispatch({
      type: DocumentActions.DOCUMENT_OPERATION_FAILED,
      payload: { document: { id: data.DocumentId, folderId: data.FolderId }, error, SourceType: data.SourceType },
    });
    throw error;
  }
};

const uploadFile = (file: File, metadata?: Dictionary<string>) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: DocumentActions.UPLOAD_FILE });
    const result = await DocumentService.uploadFile(file, metadata);

    dispatch({
      type: DocumentActions.UPLOAD_FILE_SUCCEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const updateFile = (file: File, data: UpdateDocumentRequest) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: DocumentActions.UPDATE_FILE, payload: data.DocumentId });
    const uploadedFile = await DocumentService.uploadFile(file);
    data.FileIds.push(uploadedFile.Id);

    const document = await DocumentService.updateDocument(data);

    dispatch({
      type: DocumentActions.UPDATE_FILE_SUCCEDED,
      payload: data.DocumentId,
    });
    dispatch({
      type: DocumentActions.UPDATE_DOCUMENT_SUCCEDED,
      payload: { folderId: data.FolderId, document: document, SourceType: data.SourceType },
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const updateFolder =
  (data: UpdateDocumentFolderRequest) => async (dispatch: AppThunkDispatch, getState: () => ApplicationState) => {
    try {
      const folder = getState().document.documentFolders[data.SourceType].find((x) => x.Id === data.FolderId);

      dispatch({
        type: DocumentActions.UPDATE_DOCUMENT_FOLDER,
        payload: { ...folder, ...data, isDirty: true },
      });
      const updatedDocumentFolder = await DocumentService.updateDocumentFolder(data);
      dispatch({
        type: DocumentActions.UPDATE_DOCUMENT_FOLDER_SUCCEEDED,
        payload: { updatedDocumentFolder, SourceType: data.SourceType },
      });
    } catch (error) {
      dispatch({
        type: DocumentActions.DOCUMENT_OPERATION_FAILED,
        payload: { document: { folderId: data.FolderId }, error, SourceType: data.SourceType },
      });
      throw error;
    }
  };

const deleteDocuments =
  (sourceItemId: string, documentIds: string[], removeAll = false) =>
  async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({ type: DocumentActions.DELETE_DOCUMENTS });
      await DocumentService.deleteDocuments(sourceItemId, documentIds, removeAll);
      dispatch({ type: DocumentActions.DELETE_DOCUMENTS_SUCCEEDED });
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const deleteDocumentsFromUnits =
  (unitIds: string[], documentIds: string[], removeAll = false) =>
  async (dispatch: AppThunkDispatch) => {
    try {
      dispatch({ type: DocumentActions.DELETE_DOCUMENTS });
      await DocumentService.deleteDocumentsFromUnits(unitIds, documentIds, removeAll);

      dispatch({ type: DocumentActions.DELETE_DOCUMENTS_SUCCEEDED });
    } catch (error) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const getUserFolders = (userId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: DocumentActions.GET_USER_FOLDERS });
    const result = await DocumentService.getUserFolders(userId);
    dispatch({
      type: DocumentActions.GET_USER_FOLDERS_SUCCEEDED,
      payload: result,
    });
    return result;
  } catch (error) {
    dispatch(operationFailedAction(error));
    throw error;
  }
};

const deleteFile = (fileId: string) => async (dispatch: AppThunkDispatch) => {
  try {
    dispatch({ type: DocumentActions.DELETE_FILE, payload: fileId });
    await DocumentService.deleteFile(fileId);

    dispatch({
      type: DocumentActions.DELETE_FILE_SUCCEDED,
      payload: fileId,
    });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const useDocumentState = () => useSelector((state: ApplicationState) => state.document);

const useDocumentActions = () => {
  const dispatch = useAppDispatch();

  return {
    getUserFolders: (userId: string) => dispatch(getUserFolders(userId)),
    createFolder: (data: CreateFolderRequest) => dispatch(createFolder(data)),
    updateFolder: (data: UpdateDocumentFolderRequest) => dispatch(updateFolder(data)),
    deleteFolder: (data: RemoveFolderRequest, sourceType: DocumentSourceType) =>
      dispatch(deleteFolder(data, sourceType)),
    reorderFolders: (data: ReorderFoldersRequest) => dispatch(reorderFolders(data)),
    createDocument: (data: CreateDocumentRequest) => dispatch(createDocument(data)),
    deleteDocument: (data: RemoveDocumentRequest, sourceType: DocumentSourceType) =>
      dispatch(deleteDocument(data, sourceType)),
    deleteDocuments: (sourceItemId: string, documentIds: string[], removeAll = false) =>
      dispatch(deleteDocuments(sourceItemId, documentIds, removeAll)),
    deleteDocumentsFromUnits: (unitIds: string[], documentIds: string[], removeAll = false) =>
      dispatch(deleteDocumentsFromUnits(unitIds, documentIds, removeAll)),
    updateDocument: (data: UpdateDocumentRequest) => dispatch(updateDocument(data)),
    reorderDocuments: (data: ReorderDocumentsRequest) => dispatch(reorderDocuments(data)),
    uploadFile: (file: File, metadata?: Dictionary<string>) => dispatch(uploadFile(file, metadata)),
    updateFile: (file: File, data: UpdateDocumentRequest) => dispatch(updateFile(file, data)),
    deleteFile: (fileId: string) => dispatch(deleteFile(fileId)),
    getFolders: (sourceItemId: string, sourceType: DocumentSourceType, childSourceItemId?: string) =>
      dispatch(getFolders(sourceItemId, sourceType, childSourceItemId)),
  };
};

export const useDocument = (): [ReturnType<typeof useDocumentState>, ReturnType<typeof useDocumentActions>] => {
  const state = useDocumentState();
  const actions = useDocumentActions();
  return [state, actions];
};
