import * as H from "history";
import Unit from "../definitions/model/unit/Unit";
import AddressHelper from "./address-helper";
import { Building } from "../definitions/model/Building";

const NavigationHelper = {
  GoByRoute: (
    history: H.History,
    unit: Unit | null,
    building: Building | null,
    destinationUrl: string,
    options: any = {}
  ): void => {
    //Production could have host myhouse.dk or www.myhouse.dk
    destinationUrl = destinationUrl.replace("www.", "");
    const currentHost = window.location.host.replace("www.", "");

    if (destinationUrl.indexOf("unit-route") !== -1 && unit) {
      const target = destinationUrl.replace("unit-route", AddressHelper.GetUnitAddressUrl(unit));
      history.push(target, options);
      return;
    }

    if (destinationUrl.indexOf("building-route") !== -1) {
      if (!building) {
        history.push("/building-search", options);
        return;
      }

      const target = destinationUrl.replace("building-route", AddressHelper.GetBuildingAddressUrl(building));
      history.push(target, options);
      return;
    }

    if (destinationUrl.indexOf(currentHost) !== -1) {
      const _url = new URL(destinationUrl);
      history.push(_url.pathname, options);
      return;
    }

    try {
      //try if external url
      const _url = new URL(destinationUrl);
      history.push(_url.pathname, options);
    } catch {
      history.push(destinationUrl, options);
    }
  },
  IsClimateSite: (): boolean => {
    return (
      window.location.pathname.indexOf("klimaplan") === -1 &&
      (window.location.host.indexOf("mitklimaaftryk.dk") !== -1 ||
        window.location.host.indexOf("klimaplan.nu") !== -1 ||
        window.location.host.indexOf("minklimaplan.nu") !== -1 ||
        window.location.host.indexOf("minklimaplan.com") !== -1 ||
        window.location.host.indexOf("myclimateplan.dk") !== -1 ||
        window.location.host.indexOf("myclimateplan.nu") !== -1 ||
        window.location.host.indexOf("voresklimaplan.dk") !== -1)
    );
  },
};

export default NavigationHelper;
